import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setCirclePlotsRenderData, setIsHistogramPlotsLoading, setThetaProfilingPlotsRadius, setSignalPlotsRenderData, setIsSignalPlotsLoading, setHistogramPlotsRenderData, setStatsTable1Data, setRunSummaryDensityPlotRenderData, } from "../actions/index";
import { ListGroup, Row, Col, Table } from "react-bootstrap";
import * as Utils from '../../utils/utils';
import FlowSpinner from '../flowspinner/flowspinner';
import RenderPlot from '../plots/renderplot';
import ShowAlert from '../showalert/showalert';
import TextBox from '../textbox/textbox';
import * as RenderThetaProfilingPlots from '../../plots/thetaprofiling/thetaprofiling';
import AlertPopUp from '../alertpopup/alertpopup';
import * as RenderCirclePlots from '../../plots/circleplots/circleplots';
import * as RenderHistogramPlots from '../../plots/histogramplots/histogramplots';
import RenderSignalCharts from '../../plots/signalplots/signalplots';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import SkeletonLoader from '../skeletonloader/skeletonloader';
import RangeSliderCommon from '../slider/slidercommon';
import CSVDownloadLink from '../downloadlink/csvdownloadlink';

const Signals = (props) => {

    const { signalPlotsData, setSignalPlotsData } = props;

    const dispatch = useDispatch();
    const state = useSelector(state => state);

    const [histogramPlotsData, setHistogramPlotsData] = useState([]);
    const [histogramPlotsDataCSV, setHistogramPlotsDataCSV] = useState([]);
    const [histogramPlotsDataCSVTileDescr, setHistogramPlotsDataCSVTileDescr] = useState([]);
    const [circlePlotsData, setCirclePlotsData] = useState([]);
    const [thetaProfilingPlotsData, setThetaProfilingPlotsData] = useState([]);
    const [thetaProfilingPlotsRadius, setThetaProfilingPlotsRadiusState] = useState([]);
    const [statsTable1Data, setStatsTable1DataState] = useState([]);

    const [minDensitySpinnerValue, setMinDensitySpinnerValue] = React.useState(state.spinnerDensity.min);
    const [maxDensitySpinnerValue, setMaxDensitySpinnerValue] = React.useState(state.spinnerDensity.max);
    const [minSignalSpinnerValue, setMinSignalSpinnerValue] = React.useState(state.spinnerSignal.min);
    const [maxSignalSpinnerValue, setMaxSignalSpinnerValue] = React.useState(state.spinnerSignal.max);
    const [minSNRSpinnerValue, setMinSNRSpinnerValue] = React.useState(state.spinnerSNR.min);
    const [maxSNRSpinnerValue, setMaxSNRSpinnerValue] = React.useState(state.spinnerSNR.max);
    const [thetaProfilingRadiusPopOver, showThetaProfilingRadiusPopOver] = React.useState(false);
    const [thetaProfilingRadiusPopOverText, setThetaProfilingRadiusPopOverText] = React.useState('');
    const [expandedIds, setExpandedIds] = React.useState([1, 2, 3, 4]);
    const [th1Header, setTh1Header] = useState('Lowest 10% SNR per tile');
    const [th2Header, setTh2Header] = useState('Highest 10% SNR per tile');
    const [th3Header, setTh3Header] = useState('Average SNR per tile');
    const [isDensitySliderChanged, setDensitySliderChanged] = React.useState(false);
    const [isSignalSliderChanged, setSignalSliderChanged] = React.useState(false);
    const [isSNRSliderChanged, setSNRSliderChanged] = React.useState(false);

    useEffect(() => {
        setHistogramPlotsData([]);
        setHistogramPlotsDataCSV([]);
        setHistogramPlotsDataCSVTileDescr([]);
        setCirclePlotsData(undefined);
        setThetaProfilingPlotsData(undefined);
        setThetaProfilingPlotsRadiusState([]);
        setStatsTable1DataState([]);
        Utils.purgePlot('circlePlot1');
        Utils.purgePlot('circlePlot2');
        Utils.purgePlot('circlePlot3'); 
        Utils.purgePlot('signalPlot1');
        Utils.purgePlot('signalPlot2'); 
        setDensitySliderChanged(false);    
        setSignalSliderChanged(false);  
        setSNRSliderChanged(false);
    }, [state.runId]);    

    useEffect(() => {
        try {
            let exists = state.run_summary_density_plots_data.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId) && f.flow === state.flow);
            if (exists.length > 0 && exists[0].data !== undefined) {
                if (state.run_summary_density_plots_by_camera_value === 'unified') {
                    reRenderCirclePlotsRunSummaryDensity(exists[0].data, minDensitySpinnerValue, maxDensitySpinnerValue, minSignalSpinnerValue, maxSignalSpinnerValue, minSNRSpinnerValue, maxSNRSpinnerValue);
                }
                else {
                    let cam = state.run_summary_density_plots_by_camera_value.match(/(?<=_)\d+/g)[0];
                    let byCamera = exists[0].data.filter(f => f.Camera !== undefined && f.Camera !== null && !isNaN(f.Camera)).filter(f => parseInt(f.Camera) === parseInt(cam));
                    reRenderCirclePlotsRunSummaryDensity(byCamera, minDensitySpinnerValue, maxDensitySpinnerValue, minSignalSpinnerValue, maxSignalSpinnerValue, minSNRSpinnerValue, maxSNRSpinnerValue);
                }

            }
        }
        catch { }
    }, [state.run_summary_density_plots_by_camera_value]);

    useEffect(() => {
        try {
            let exists = state.theta_profiling_plots_radius.filter(f => f.runid === state.runId);
            if (exists.length > 0) {
                let radius = state.theta_profiling_plots_radius.filter(f => f.runid === state.runId);
                if (radius.length > 0) {
                    reRenderThetaProfilingPlots();
                }
            }
        }
        catch { }
    }, [state.theta_profiling_plots_radius]);   

    useEffect(() => {
        try {
            let exists = state.signal_plots_data.filter(f => f.runid === state.runId);
            if (exists.length > 0) {
                let data = exists[0].data;
                if (data.data) {                
                    let byCameraTemp = data.data[state.by_camera_value_global];
                    if (byCameraTemp && byCameraTemp.length > 0) {
                        let jsData = Utils.transformData(byCameraTemp);         
                        //data.data = jsData;
                        let signal = RenderSignalCharts({ data: jsData }, data.flows !== undefined && data.flows !== null && data.flows.length > 0 ? data.flows : null, data.noGap !== undefined && data.noGap !== null && data.noGap.length > 0 ? data.noGap : null, data.defaultFlowOrder);                   
                        if (signal !== undefined)
                            dispatch(setSignalPlotsRenderData(signal, state.runId));
                        else setSignalPlotsData(undefined);
                        dispatch(setIsSignalPlotsLoading(false));
                    }
                }
                else {
                    setSignalPlotsData(undefined);
                    dispatch(setIsSignalPlotsLoading(false));
                    dispatch(setSignalPlotsRenderData(undefined, state.runId));
                }
            }
        }
        catch {
            setSignalPlotsData(undefined);
            dispatch(setIsSignalPlotsLoading(false));
        }
    }, [state.signal_plots_data, state.by_camera_value_global]);    

    useEffect(() => {
        try {
            let exists = state.signal_plots_render_data.filter(f => f.runid === state.runId);
            if (exists !== undefined && exists.length > 0) {
                //if (exists.length > 0 && exists[0].data !== undefined && exists[0].data.data !== undefined && exists[0].data.data.length > 0) {
                if (exists !== undefined && exists.length > 0 && exists[0].data !== undefined) {
                    let updatedData;
                    if(!exists[0].data.hasOwnProperty('data1')){
                        let byCameraTemp;
                        if (state.runId.includes('_')) byCameraTemp = exists[0].data.data[`camera_${state.runId.split('_')[1]}`];
                        else byCameraTemp = exists[0].data.data[`unified`];
                        let json = Utils.transformData(byCameraTemp);   
                        updatedData = RenderSignalCharts({ data: json }, exists[0].data.flows !== undefined && exists[0].data.flows !== null && exists[0].data.flows.length > 0 ? exists[0].data.flows : null, exists[0].data.noGap !== undefined && exists[0].data.noGap !== null && exists[0].data.noGap.length > 0 ? exists[0].data.noGap : null, exists[0].data.defaultFlowOrder);                   
                    } else updatedData = exists[0].data;
                    
                    setSignalPlotsData(updatedData);
                    dispatch(setIsSignalPlotsLoading(false));
                }
                else {
                    setSignalPlotsData(undefined);
                    dispatch(setIsSignalPlotsLoading(false));
                }

            }
        }
        catch {
            setSignalPlotsData(undefined);
            dispatch(setIsSignalPlotsLoading(false));
        }
    }, [state.signal_plots_render_data]);

    useEffect(() => {
        try {
            let exists = state.histogram_plots_data.filter(f => f.runid === state.runId && f.flow === state.flow);
            let existsTileArea = state.tileArea.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId) && f.instrId === state.instrumentId);
            if (exists.length > 0) {
                if (exists[0].data && exists[0].data.length > 0 && existsTileArea.length > 0 && existsTileArea[0].data.TileArea) {
                    let data = exists[0].data;
                    let tileArea = existsTileArea[0].data;
                    let res = data.map(m => {
                        return {
                            //'beadsPerTile': m.BeadsPerTile !== undefined && m.BeadsPerTile !== null && m.BeadsPerTile !== '' ? parseInt(parseFloat(m.BeadsPerTile) / tileArea.TileArea) : m.BeadsPerTile,
                            'beadsPerTile': m.BeadsPerTile,
                            'beadsPerTile_All': m.BeadsPerTile,
                            'beadsPerTile_ForSignals_250GL': parseFloat(m.BeadsPerTile_ForSignals_250GL) / tileArea.TileArea,
                            'beadsPerTile_ForSignals_250GL_All': m.BeadsPerTile_ForSignals_250GL,
                            'flow': m.Flow,
                            'tileID': m.TileID,
                            'tileOffset': m.TileOffset,
                            'ring': m.Ring,
                            'radius': m.Radius,
                            'theta': m.Theta,
                            'mean_Sig': m.Mean_Sig,
                            'stdDev_Sig': m.StdDev_Sig,
                            'mean_SigStd': m.Mean_SigStd,
                            //'count_beads_sig_gt_threshold': parseFloat(m.Count_beads_sig_gt_threshold) / tileArea.TileArea,
                            'count_beads_sig_gt_threshold_All': m.Count_beads_sig_gt_threshold,
                            'count_beads_sig_gt_threshold': m.Count_beads_sig_gt_threshold,
                            'mean_beads_sig_gt_threshold': isNaN(m.Mean_beads_sig_gt_threshold) ? '0' : m.Mean_beads_sig_gt_threshold
                        }
                    });
                    data.data = res;
                    let histogram1 = RenderHistogramPlots.RenderBinHistogramChartsDensity(res, state.flow, tileArea.TileArea, false, tileArea.Crop, tileArea.PixSize, tileArea.TileSizeX, tileArea.TileSizeY);
                    let histogramContainerName = 'BeadsHistogramChartSignal';
                    let labelName = 'Flow ' + state.flow + ' Signal per tile=';
                    let histogram2 = RenderHistogramPlots.RenderBinHistogramChartsSignal(data.data, false, histogramContainerName, labelName);
                    let histogram3 = RenderHistogramPlots.RenderBinHistogramChartsSNR(data.data, false);
                    dispatch(setHistogramPlotsRenderData({ histogram1, histogram2, histogram3 }, state.runId, state.flow));
                    dispatch(setIsHistogramPlotsLoading(false));
                    setHistogramPlotsData({ histogram1, histogram2, histogram3 });

                    let tileDescrCsvDataTileDescr = exists[0].data.map(m => {
                        return {
                            TileID: m.TileID,
                            TileOffset: m.TileOffset,
                            BeadsPerTile: m.BeadsPerTile,
                            Ring: m.Ring,
                            Radius: m.Radius,
                            Theta: m.Theta
                        };
                    });
                    setHistogramPlotsDataCSVTileDescr(tileDescrCsvDataTileDescr);

                    let tileDescrCsvData = exists[0].data.map(m => {
                        return {
                            Flow: m.Flow,
                            Ring: m.Ring,
                            Tile: m.TileID,
                            Mean_Sig: m.Mean_Sig,
                            StdDev_Sig: m.StdDev_Sig,
                            Mean_SigStd: m.Mean_SigStd,
                            Count_beads_sig_gt_threshold: m.Count_beads_sig_gt_threshold,
                            Mean_beads_sig_gt_threshold: m.Mean_beads_sig_gt_threshold,
                        };
                    });
                    setHistogramPlotsDataCSV(tileDescrCsvData);

                    let arraySumDensityRaw = Utils.unpackIntByTileArea(data.data, 'count_beads_sig_gt_threshold_All', tileArea.TileArea);
                    let arraySumDensity = arraySumDensityRaw.reduce(Utils.getSum);
                    let arrayCountDensity = data.data.length;
                    let meanDensity = arraySumDensity / arrayCountDensity;
                    let sigmaDensity = arraySumDensityRaw.stanDeviate();
                    let sigmaValDensity = 3 * parseInt(sigmaDensity);
                    let minDensityValCalc = parseInt(meanDensity) - sigmaValDensity;
                    if (minDensityValCalc < 0) {
                        minDensityValCalc = 0;
                    }
                    let maxDensityValCalc = parseInt(meanDensity) + sigmaValDensity;
                    //dispatch(setSpinnerDensity({ min: minDensityValCalc, max: maxDensityValCalc }));
                    let minDens = minDensityValCalc !== undefined && !isNaN(minDensityValCalc) && minDensityValCalc > 0 ? minDensityValCalc : 0;
                    let maxDens = maxDensityValCalc !== undefined && !isNaN(maxDensityValCalc) && maxDensityValCalc > 0 ? maxDensityValCalc : 0;
                    setMinDensitySpinnerValue(minDens);
                    setMaxDensitySpinnerValue(maxDens);

                    let arraySumSignalRaw = Utils.unpackInt(data.data, 'mean_Sig');
                    let arraySumSignal = arraySumSignalRaw.reduce(Utils.getSum);
                    let arrayCountSignal = data.data.length;
                    let meanSignal = arraySumSignal / arrayCountSignal;
                    let sigmaSignal = arraySumSignalRaw.stanDeviate();
                    let sigmaValSignal = 3 * parseInt(sigmaSignal);
                    let minSignalValCalc = parseInt(meanSignal) - sigmaValSignal;
                    let maxSignalValCalc = parseInt(meanSignal) + sigmaValSignal;
                    //dispatch(setSpinnerSignal({ min: minSignalValCalc, max: maxSignalValCalc }));
                    let minSign = minSignalValCalc !== undefined && !isNaN(minSignalValCalc) && minSignalValCalc > 0 ? minSignalValCalc : 0;
                    setMinSignalSpinnerValue(minSignalValCalc !== undefined && !isNaN(minSignalValCalc) && minSignalValCalc > 0 ? minSignalValCalc : 0);
                    setMaxSignalSpinnerValue(maxSignalValCalc !== undefined && !isNaN(maxSignalValCalc) && maxSignalValCalc > 0 ? maxSignalValCalc : 0);

                    let SNR = Utils.getSnr(data.data);
                    let arraySumSnr = SNR.reduce(Utils.getSum);
                    let arrayCountSnr = data.data.length;
                    let meanSnr = arraySumSnr / arrayCountSnr;
                    let sigmaSnr = SNR.stanDeviate();
                    let sigmaValSnr = 3 * parseInt(sigmaSnr);
                    let minSnrValCalc = parseInt(meanSnr) - sigmaValSnr;
                    let maxSnrValCalc = parseInt(meanSnr) + sigmaValSnr;
                    //dispatch(setSpinnerSNR({ min: minSnrValCalc, max: maxSnrValCalc }));
                    setMinSNRSpinnerValue(minSnrValCalc !== undefined && !isNaN(minSnrValCalc) && minSnrValCalc > 0 ? minSnrValCalc : 0);
                    setMaxSNRSpinnerValue(maxSnrValCalc !== undefined && !isNaN(maxSnrValCalc) && maxSnrValCalc > 0 ? maxSnrValCalc : 0);

                    let minBrightBeadValCalc;
                    let maxBrightBeadValCalc;

                    let isBrightBeads = data.data.some(f => f.count_beads_sig_gt_threshold !== undefined && f.count_beads_sig_gt_threshold !== null && f.count_beads_sig_gt_threshold !== '');

                    if (!state.isRunBaseLine && isBrightBeads) {
                        function getBrightBeads(rows) {
                            return rows.map(function (row) {
                                return isNaN(parseFloat(row['mean_beads_sig_gt_threshold'])) ? 0 : parseFloat(row['mean_beads_sig_gt_threshold']);
                            });
                        }
                        setTh1Header('Lowest 10% Bright Beads per tile');
                        setTh2Header('Highest 10% Bright Beads per tile');
                        setTh3Header('Average Bright Beads per tile'); 
                        let brightBead = getBrightBeads(data.data);
                        let arraySumBrightBead = brightBead.reduce(Utils.getSum);
                        let arrayCountBrightBead = data.data.length;
                        let meanBrightBead = arraySumBrightBead / arrayCountBrightBead;
                        let sigmaBrightBead = brightBead.stanDeviate();
                        let sigmaValBrightBead = 3 * parseInt(sigmaBrightBead);
                        minBrightBeadValCalc = parseInt(meanBrightBead) - sigmaValBrightBead;
                        maxBrightBeadValCalc = parseInt(meanBrightBead) + sigmaValBrightBead;

                        setMinSNRSpinnerValue(minBrightBeadValCalc !== undefined && !isNaN(minBrightBeadValCalc) && minBrightBeadValCalc > 0 ? minBrightBeadValCalc : 0);
                        setMaxSNRSpinnerValue(maxBrightBeadValCalc !== undefined && !isNaN(maxBrightBeadValCalc) && maxBrightBeadValCalc > 0 ? maxBrightBeadValCalc : 0);

                        reRenderCirclePlots(minDensityValCalc, maxDensityValCalc, minSignalValCalc, maxSignalValCalc, minBrightBeadValCalc, maxBrightBeadValCalc);
                        //reRenderCirclePlotsRunSummaryDensity(minDensityValCalc, maxDensityValCalc, minSignalValCalc, maxSignalValCalc, minBrightBeadValCalc, maxBrightBeadValCalc);
                    }
                    else {
                        reRenderCirclePlots(minDensityValCalc, maxDensityValCalc, minSignalValCalc, maxSignalValCalc, minSnrValCalc, maxSnrValCalc);
                        //reRenderCirclePlotsRunSummaryDensity(minDensityValCalc, maxDensityValCalc, minSignalValCalc, maxSignalValCalc, minSnrValCalc, maxSnrValCalc);
                    }


                    //reRenderCirclePlots(minDensityValCalc, maxDensityValCalc, minSignalValCalc, maxSignalValCalc, minSnrValCalc, maxSnrValCalc);                    

                    let statsTable1 = getStatValues(data.data, state.flow);
                    dispatch(setStatsTable1Data(statsTable1, state.runId, state.flow));
                    //setStatsTable1DataState(statsTable1);                  

                    let radius = state.theta_profiling_plots_radius.filter(f => f.runid === state.runId);
                    if (radius.length === 0) {
                        let r = getThetaProfilingPlotRadiusInitial();
                        dispatch(setThetaProfilingPlotsRadius(r, state.runId));
                        setThetaProfilingPlotsRadiusState(r);
                    }
                }
                else {
                    //dispatch(setHistogramPlotsRenderData({}, state.runId));
                    setHistogramPlotsData(undefined);
                    //dispatch(setCirclePlotsRenderData([], state.runId));
                    setCirclePlotsData(undefined);
                    //dispatch(setStatsTable1Data({}, state.runId));
                    setStatsTable1DataState(undefined);
                    setThetaProfilingPlotsData(undefined);
                }
            }
        }
        catch (e) {
            dispatch(setIsHistogramPlotsLoading(false));
        }
    }, [state.histogram_plots_data, state.tileArea]);

    useEffect(() => {
        try {
            let exists = state.histogram_plots_render_data.filter(f => f.runid === state.runId && f.flow === state.flow);
            if (exists !== undefined && exists.length > 0 && exists[0].data === undefined) {
                setHistogramPlotsData(undefined);
                setStatsTable1DataState(undefined);
                dispatch(setIsHistogramPlotsLoading(false));
            }
            else if (exists.length > 0 && typeof exists[0].data === 'object' && exists[0].data.length === undefined) {
                setHistogramPlotsData(exists[0].data);

                let existsCirclePlots = state.circle_plots_render_data.filter(f => f.runid === state.runId && f.flow === state.flow);
                if (existsCirclePlots !== undefined && existsCirclePlots.length > 0) {
                    dispatch(setCirclePlotsRenderData(existsCirclePlots[0].data, state.runId, state.flow));
                }

                let existsStatsTableData = state.statsTable1Data.filter(f => f.runid === state.runId && f.flow === state.flow);
                if (existsStatsTableData.length > 0) {
                    //var statsTable1 = getStatValues(existsStatsTableData[0].data, state.flow);
                    setStatsTable1DataState(existsStatsTableData[0].data);
                }
                else {
                    setStatsTable1DataState([]);
                }

                let radius = state.theta_profiling_plots_radius.filter(f => f.runid === state.runId);
                if (radius.length === 0) {
                    let r = getThetaProfilingPlotRadiusInitial();
                    dispatch(setThetaProfilingPlotsRadius(r, state.runId));
                    setThetaProfilingPlotsRadiusState(r);
                }
                else {
                    dispatch(setThetaProfilingPlotsRadius(radius[0].data, state.runId));
                    setThetaProfilingPlotsRadiusState(radius[0].data);
                }

                dispatch(setIsHistogramPlotsLoading(false));
            }
            else {
                //setHistogramPlotsData({histogram1: undefined});
                /*setHistogramPlotsData([]);
                setStatsTable1DataState([]);
                setCirclePlotsData([]);
                setThetaProfilingPlotsData([]);

                if (exists.length > 0 && exists[0].data.length === 0) {
                    dispatch(setIsHistogramPlotsLoading(false));
                    dispatch(setIsSignalPlotsLoading(false));
                }*/
            }
        }
        catch (e) {
            setHistogramPlotsData(undefined);
            setStatsTable1DataState(undefined);
            dispatch(setIsHistogramPlotsLoading(false));
        }
    }, [state.histogram_plots_render_data]);

    useEffect(() => {
        try {
            let exists = state.circle_plots_render_data.filter(f => f.runid === state.runId && f.flow === state.flow);
            if (exists.length > 0 && exists[0].data != {}) {
                setCirclePlotsData(exists[0].data);
                dispatch(setIsHistogramPlotsLoading(false));
            }
        }
        catch {
            setCirclePlotsData(undefined);
            dispatch(setIsHistogramPlotsLoading(false));
        }
    }, [state.circle_plots_render_data]);

    const reRenderCirclePlots = (minDensityVal, maxDensityVal, minSignalVal, maxSignalVal, minSnrVal, maxSnrVal, notToSaveToState) => {
        let exists = state.histogram_plots_data.filter(f => f.runid === state.runId && f.flow === state.flow);
        if (exists.length > 0) {
            let res = exists[0].data.map(m => {
                return {
                    'beadsPerTile': m.BeadsPerTile,
                    'beadsPerTile_ForSignals_250GL': m.BeadsPerTile_ForSignals_250GL,
                    'flow': m.Flow,
                    'tileID': m.TileID,
                    'tileOffset': m.TileOffset,
                    'ring': m.Ring,
                    'radius': m.Radius,
                    'theta': m.Theta,
                    'mean_Sig': m.Mean_Sig,
                    'stdDev_Sig': m.StdDev_Sig,
                    'mean_SigStd': m.Mean_SigStd,
                    'count_beads_sig_gt_threshold': m.Count_beads_sig_gt_threshold,
                    'mean_beads_sig_gt_threshold': m.Mean_beads_sig_gt_threshold
                }
            });
            let tl = state.tileArea.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId) && f.instrId === state.instrumentId);
            let circle = RenderCirclePlots.RenderCircleCharts(res, 'radius', 'theta', 'beadsPerTile', state.flow, minDensityVal, maxDensityVal, minSignalVal, maxSignalVal, minSnrVal, maxSnrVal, tl !== undefined && tl.length > 0 ? tl[0].data.TileArea : undefined, state.isRunBaseLine, true, state.runId, tl !== undefined && tl.length > 0 ? tl[0].data.Crop : undefined,
                tl !== undefined && tl.length > 0 ? tl[0].data.PixSize : undefined, tl !== undefined && tl.length > 0 ? tl[0].data.TileSizeX : undefined, tl !== undefined && tl.length > 0 ? tl[0].data.TileSizeY : undefined, minSnrVal, maxSnrVal);
            if (notToSaveToState === undefined || notToSaveToState === false) {
                dispatch(setCirclePlotsRenderData(circle, state.runId, state.flow));
            }
            setCirclePlotsData(circle);
        }
    };

    const reRenderCirclePlotsRunSummaryDensity = (data, minDensityVal, maxDensityVal, minSignalVal, maxSignalVal, minSnrVal, maxSnrVal, notToSaveToState) => {
        try {
            if (data !== undefined && data.length > 0) {
                let res = data.map(m => {
                    return {
                        'beadsPerTile': m.BeadsPerTile,
                        'beadsPerTile_ForSignals_250GL': m.BeadsPerTile_ForSignals_250GL,
                        'flow': m.Flow,
                        'tileID': m.TileID,
                        'tileOffset': m.TileOffset,
                        'ring': m.Ring,
                        'radius': m.Radius,
                        'theta': m.Theta,
                        'mean_Sig': m.Mean_Sig,
                        'stdDev_Sig': m.StdDev_Sig,
                        'mean_SigStd': m.Mean_SigStd,
                        'count_beads_sig_gt_threshold': m.Count_beads_sig_gt_threshold,
                        'mean_beads_sig_gt_threshold': m.Mean_beads_sig_gt_threshold
                    }
                });
                let tl = state.tileArea.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId) && f.instrId === state.instrumentId);
                let circle = RenderCirclePlots.RenderCircleCharts(res, 'radius', 'theta', 'beadsPerTile', state.flow, minDensityVal, maxDensityVal, minSignalVal, maxSignalVal, minSnrVal, maxSnrVal, tl !== undefined && tl.length > 0 ? tl[0].data.TileArea : undefined, state.isRunBaseLine, true, state.runId, tl !== undefined && tl.length > 0 ? tl[0].data.Crop : undefined,
                    tl !== undefined && tl.length > 0 ? tl[0].data.PixSize : undefined, tl !== undefined && tl.length > 0 ? tl[0].data.TileSizeX : undefined, tl !== undefined && tl.length > 0 ? tl[0].data.TileSizeY : undefined, minSnrVal, maxSnrVal, undefined, undefined, state.run_summary_density_plots_by_camera_value !== 'unified' ? `${state.run_summary_density_plots_by_camera_value}` : state.run_summary_density_plots_by_camera_value);
                if (notToSaveToState === undefined || notToSaveToState === false) {
                    dispatch(setRunSummaryDensityPlotRenderData(state.runId, state.run_summary_density_plots_by_camera_value, state.flow, { data_density: circle.data_density, layout_density: circle.layout_density, config: circle.config }));
                }
            }
            else {
                //dispatch(setRunSummaryDensityPlotRenderData(state.runId, state.run_summary_density_plots_by_camera_value, state.flow, undefined));
            }
        }
        catch { }
    };

    const onMinDensitySpinnerChange = e => {
        setMinDensitySpinnerValue(e.target.value);
    };

    const onMaxDensitySpinnerChange = e => {
        setMaxDensitySpinnerValue(e.target.value);
    };

    const onMinDensitySpinnerChangeSlider = val => {
        setMinDensitySpinnerValue(val);
    };

    const onMaxDensitySpinnerChangeSlider = val => {
        setMaxDensitySpinnerValue(val);
    };

    const onApplyDensitySpinnerClick = e => {
        //dispatch(setSpinnerDensity({ min: minDensitySpinnerValue, max: maxDensitySpinnerValue }));
        reRenderCirclePlots(minDensitySpinnerValue, maxDensitySpinnerValue, minSignalSpinnerValue, maxSignalSpinnerValue, minSNRSpinnerValue, maxSNRSpinnerValue, true);
    };

    const onApplySignalSpinnerClick = e => {
        //dispatch(setSpinnerSignal({ min: minSignalSpinnerValue, max: maxSignalSpinnerValue }));
        reRenderCirclePlots(minDensitySpinnerValue, maxDensitySpinnerValue, minSignalSpinnerValue, maxSignalSpinnerValue, minSNRSpinnerValue, maxSNRSpinnerValue, true);
    };

    const onMinSignalSpinnerChange = e => {
        setMinSignalSpinnerValue(e.target.value);
    };

    const onMaxSignalSpinnerChange = e => {
        setMaxSignalSpinnerValue(e.target.value);
    };

    const onMinSignalSpinnerChangeSlider = val => {
        setMinSignalSpinnerValue(val);
    };

    const onMaxSignalSpinnerChangeSlider = val => {
        setMaxSignalSpinnerValue(val);
    };

    const onApplySNRSpinnerClick = e => {
        //dispatch(setSpinnerSNR({ min: minSNRSpinnerValue, max: maxSNRSpinnerValue }));
        reRenderCirclePlots(minDensitySpinnerValue, maxDensitySpinnerValue, minSignalSpinnerValue, maxSignalSpinnerValue, minSNRSpinnerValue, maxSNRSpinnerValue);
        //reRenderCirclePlotsRunSummaryDensity(minDensitySpinnerValue, maxDensitySpinnerValue, minSignalSpinnerValue, maxSignalSpinnerValue, minSNRSpinnerValue, maxSNRSpinnerValue);
    };

    const onMinSNRSpinnerChange = e => {
        setMinSNRSpinnerValue(e.target.value);
    };

    const onMaxSNRSpinnerChange = e => {
        setMaxSNRSpinnerValue(e.target.value);
    };

    const onMinSNRSpinnerChangeSlider = val => {
        setMinSNRSpinnerValue(val);
    };

    const onMaxSNRSpinnerChangeSlider = val => {
        setMaxSNRSpinnerValue(val);
    };

    const onThetaProfilingRadiusChange = e => {
        //dispatch(setThetaProfilingPlotsRadius(e.target.value, state.runId));
        setThetaProfilingPlotsRadiusState(e.target.value);
    };

    const handleThetaProfilingRadiusKeyPress = e => {
        if (e.key === 'Enter') {
            onShowThetaProfilingRadiusClick();
        } else {
            let allowedChars = '0123456789';
            const contains = (stringValue, charValue) => {
                return stringValue.indexOf(charValue) > -1;
            }
            let invalidKey = e.key.length === 1 && !contains(allowedChars, e.key)
                || e.key === '.' && contains(e.target.value, '.');
            invalidKey && e.preventDefault();
        }
    };

    const onShowThetaProfilingRadiusClick = e => {
        let exists = state.histogram_plots_data.filter(f => f.runid === state.runId);
        if (exists.length > 0) {
            let arr = exists[0].data.data.map(elem => {
                return Math.round(elem.radius);
            }).filter(onlyUnique);

            let closest = getClosestVal(arr, thetaProfilingPlotsRadius);
            //dispatch(setThetaProfilingPlotsRadius(closest));
            setThetaProfilingPlotsRadiusState(closest);
            reRenderThetaProfilingPlots();
        }
    };

    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    };

    const getClosestVal = (arr, goal) => {
        return arr.reduce((prev, curr) => {
            return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
        });
    };

    const reRenderThetaProfilingPlots = () => {
        try {
            let exists = state.histogram_plots_data.filter(f => f.runid === state.runId);
            if (exists.length > 0) {
                let data = exists[0].data;
                if (data.data !== undefined) {
                    let radiusExists = data.data.some(p => Math.round(p.radius) === parseInt(thetaProfilingPlotsRadius));
                    if (radiusExists) {
                        setThetaProfilingRadiusPopOverText('');
                        showThetaProfilingRadiusPopOver(false);
                        const tl = state.tileArea.find(f => f.runid === Utils.getRunIdWithoutCamera(state.runId));
                        let tileArea = undefined;
                        if (tl !== undefined)
                            tileArea = tl.data.TileArea;
                        let thetaProfiling = RenderThetaProfilingPlots.RenderThetaProfilingCharts(data.data, tileArea, state.flow, thetaProfilingPlotsRadius);
                        setThetaProfilingPlotsData(thetaProfiling);
                        dispatch(setIsHistogramPlotsLoading(false));
                    }
                    else {
                        setThetaProfilingRadiusPopOverText(`Sorry, such Radius does not exists. Available values: ${data.data.map(m => Math.round(m.radius)).filter(onlyUnique).join(", ")}`);
                        showThetaProfilingRadiusPopOver(true);
                        dispatch(setIsHistogramPlotsLoading(false));
                    }
                }
            }
        }
        catch { }
    };

    const getStatValues = (data, flow) => {
        let result = {};

        try {
            if (data !== undefined && data.length) {
                const average = (array) => array.reduce((a, b) => a + b) / array.length;

                let radiusColumn = data.map(r => r.radius);
                let minRadius = radiusColumn.filter(w => w !== null && w !== undefined && w !== '').map(r => parseInt(Math.floor(parseFloat(r))));
                let minRadiusVal = minRadius.min();
                let maxRadius = radiusColumn.filter(w => w !== null && w !== undefined && w !== '').map(r => parseInt(Math.floor(parseFloat(r))));
                let maxRadiusVal = maxRadius.max();

                let ringColumn = data.map(r => r.ring);
                const distinct = (value, index, self) => {
                    return self.indexOf(value) === index;
                };
                let ringsNumber = ringColumn.filter(distinct).map(r => parseInt(r)).length;

                let tilesColumn = data.map(r => r.tileID).filter(w => w !== "").filter(w => w !== null && w !== undefined && w !== '' && w !== ' ');
                let tilesNumber = tilesColumn.length;

                result.Flow = parseInt(flow);
                result.MinRadius = minRadiusVal;
                result.MaxRadius = maxRadiusVal;
                result.TotalRingsNumber = ringsNumber;
                result.TotalTilesNumber = tilesNumber;

                if (flow === "1") {
                    let beadsColumn = data.map(r => r.beadsPerTile).filter(w => w !== "").filter(w => w !== null && w !== undefined && w !== ' ');
                    let beadsColumn_250GL = data.map(r => r.beadsPerTile_ForSignals_250GL).filter(w => w !== "").filter(w => w !== null && w !== undefined && w !== ' ');
                    let beadsColumnAll = data.map(r => r.beadsPerTile_All).filter(w => w !== "").filter(w => w !== null && w !== undefined && w !== null);
                    let beadsColumnAll_250GL = data.map(r => r.beadsPerTile_ForSignals_250GL_All).filter(w => w !== "").filter(w => w !== null && w !== undefined && w !== ' ');                   
                    let beadsNumberall = beadsColumnAll.length !== 0 ? beadsColumnAll.filter(w => w !== null && w !== undefined && w !== null && w !== 'NaN').map(r => parseInt(r)).reduce((a, b) => a + b, 0) : 0;
                    let beadsNumberall_250GL = beadsColumnAll_250GL.length !== 0 ? beadsColumnAll_250GL.filter(w => w !== null && w !== undefined && w !== null && w !== 'NaN').map(r => parseFloat(r)).reduce((a, b) => a + b, 0) : 0;
                   
                    let sortedBeads = data.filter(w => w.beadsPerTile !== null && w.beadsPerTile !== undefined && w.beadsPerTile !== null && w.beadsPerTile !== '').sort((a, b) => {
                        return parseInt(a.beadsPerTile) - parseInt(b.beadsPerTile);
                    });
                    let sortedBeadsCount = sortedBeads.length;
                    let min10 = parseInt((sortedBeadsCount * 10) / 100);
                    let max10 = parseInt((sortedBeadsCount * 90) / 100);

                    let minBeadsNumber10 = sortedBeads[min10] === undefined ? "0" : sortedBeads[min10];
                    let maxBeadsNumber10 = sortedBeads[max10] === undefined ? "0" : sortedBeads[max10];
                    let minBeadsNumber = parseInt(minBeadsNumber10.beadsPerTile);
                    let maxBeadsNumber = parseInt(maxBeadsNumber10.beadsPerTile);

                    let avgBeadsPerTileNumberArr = beadsColumn.map(r => parseFloat(r));
                    let avgBeadsPerTileNumber = average(avgBeadsPerTileNumberArr);

                    ////For Beads with signals > 250GL
                    let sortedBeads_250GL = data.filter(w => w.beadsPerTile_ForSignals_250GL !== null && w.beadsPerTile_ForSignals_250GL !== '' && w.beadsPerTile_ForSignals_250GL !== undefined).sort((a, b) => parseFloat(a.beadsPerTile_ForSignals_250GL) - parseFloat(b.beadsPerTile_ForSignals_250GL));
                    let sortedBeadsCount_250GL = sortedBeads_250GL.length;
                    let min10_250GL = parseInt((sortedBeadsCount_250GL * 10) / 100);
                    let max10_250GL = parseInt((sortedBeadsCount_250GL * 90) / 100);

                    let minBeadsNumber10_250GL = sortedBeads_250GL.length > 0 ? sortedBeads_250GL[min10_250GL].beadsPerTile_ForSignals_250GL === "" ? "0" : sortedBeads_250GL[min10_250GL].beadsPerTile_ForSignals_250GL : "0";
                    //var temp = sortedBeads_250GL[max10_250GL];
                    let maxBeadsNumber10_250GL = sortedBeads_250GL.length > 0 ? sortedBeads_250GL[max10_250GL].beadsPerTile_ForSignals_250GL : "0";
                    let minBeadsNumber_250GL = parseInt(minBeadsNumber10_250GL);
                    let maxBeadsNumber_250GL = parseInt(maxBeadsNumber10_250GL);
                    let avgBeadsPerTileNumber_250GL = beadsColumn_250GL.length > 0 ? average(beadsColumn_250GL.map(r => parseFloat(r))) : 0;
                    ///

                    let signalsColumn = data.map(r => r.mean_Sig).filter(w => w !== "");
                    //var signalsNumber = signalsColumn.map(r => parseFloat(r)).reduce((a, b) => a + b, 0);
                    let sortedSignals = data.filter(m => m.mean_Sig !== '').sort((a, b) => parseFloat(a.mean_Sig) - parseFloat(b.mean_Sig));
                    let sortedSignalsCount = sortedSignals.length;
                    let min10_signals = parseInt((sortedSignalsCount * 10) / 100);
                    let max10_signals = parseInt((sortedSignalsCount * 90) / 100);
                    let minSignalsNumber10 = parseFloat(sortedSignals[min10].mean_Sig);
                    let maxSignalsNumber10 = parseFloat(sortedSignals[max10].mean_Sig);
                    let minSignalsNumber = minSignalsNumber10;
                    let maxSignalsNumber = maxSignalsNumber10;
                    let avgSignalsPerTileNumber = average(signalsColumn.map(r => parseFloat(r)));

                    let snrColumn = data.map(r => parseFloat(r.mean_Sig !== "" ? r.mean_Sig : "0") / parseFloat(r.mean_SigStd !== "" ? r.mean_SigStd : "0"));
                    let sortedSnr = data.filter(f => f.mean_Sig !== '' && f.mean_SigStd !== "").sort((a, b) => (parseFloat(a.mean_Sig) / parseFloat(a.mean_SigStd) > parseFloat(b.mean_Sig) / parseFloat(b.mean_SigStd)) ? 1 : ((parseFloat(b.mean_Sig) / parseFloat(b.mean_SigStd) > parseFloat(a.mean_Sig) / parseFloat(a.mean_SigStd)) ? -1 : 0));
                    let sortedSnrCount = sortedSnr.length;
                    let min10_snr = parseInt((sortedSnrCount * 10) / 100);
                    //var max10_min10_snr = parseInt((sortedSnrCount * 90) / 100);
                    let minSnrNumber10 = parseFloat(sortedSnr[min10].mean_Sig) / parseFloat(sortedSnr[min10].mean_SigStd);
                    let maxSnrNumber10 = parseFloat(sortedSnr[max10].mean_Sig) / parseFloat(sortedSnr[max10].mean_SigStd);
                    let minSnrNumber = minSnrNumber10;
                    let maxSnrNumber = maxSnrNumber10;
                    let avgSnrPerTileNumber = average(snrColumn.map(r => parseFloat(r)));

                    result.TotalBeadsNumber = beadsNumberall;
                    result.TotalBeadsNumber_ForSignals_250GL = beadsNumberall_250GL;
                    result.MinBeadsNumber = parseInt(minBeadsNumber);
                    result.MaxBeadsNumber = parseInt(maxBeadsNumber);
                    result.AvgBeadsPerTileNumber = parseInt(avgBeadsPerTileNumber);
                    result.MinBeadsNumber_250GL = parseInt(minBeadsNumber_250GL);
                    result.MaxBeadsNumber_250GL = parseInt(maxBeadsNumber_250GL);
                    result.AvgBeadsPerTileNumber_250GL = parseInt(avgBeadsPerTileNumber_250GL);
                    result.MinSignalsNumber = parseInt(minSignalsNumber);
                    result.MaxSignalsNumber = parseInt(maxSignalsNumber);
                    result.AvgSignalsPerTileNumber = parseInt(avgSignalsPerTileNumber);
                    result.MinSnrNumber = parseInt(minSnrNumber);
                    result.MaxSnrNumber = parseInt(maxSnrNumber);
                    result.AvgSnrPerTileNumber = parseInt(avgSnrPerTileNumber);



                    let brightBeadsColumn = data.map(r => r.mean_beads_sig_gt_threshold).filter(w => w !== ""/* && w !== null*/);
                    let sortedBrightBeads = data.filter(f => f.mean_beads_sig_gt_threshold !== undefined && !isNaN(f.mean_beads_sig_gt_threshold)).sort((a, b) => parseFloat(a.mean_beads_sig_gt_threshold) - parseFloat(b.mean_beads_sig_gt_threshold));
                    if (sortedBrightBeads.filter(x => (x.mean_beads_sig_gt_threshold) === "-nan").length > 0) {
                        sortedBrightBeads.forEach(x => {
                            if (x.mean_beads_sig_gt_threshold === "-nan")
                                x.mean_beads_sig_gt_threshold = "0";
                        });
                    }                  
                    let minBrightBeadsNumber10 = sortedBrightBeads[min10].mean_beads_sig_gt_threshold !== undefined && sortedBrightBeads[min10].mean_beads_sig_gt_threshold !== null ? parseFloat(sortedBrightBeads[min10].mean_beads_sig_gt_threshold) : 0;
                    let maxBrightBeadsNumber10 = sortedBrightBeads[max10].mean_beads_sig_gt_threshold !== undefined && sortedBrightBeads[max10].mean_beads_sig_gt_threshold !== null ? parseFloat(sortedBrightBeads[max10].mean_beads_sig_gt_threshold) : 0;;
                    let minBrightBeadsNumber = minBrightBeadsNumber10;
                    let maxBrightBeadsNumber = maxBrightBeadsNumber10;
                    let avgBrightBeadsPerTileNumber;
                    if (brightBeadsColumn !== null && brightBeadsColumn.length > 0) {
                        let filteredValues = [];
                        brightBeadsColumn.forEach(value => {
                            if (value !== undefined && !isNaN(value))
                                filteredValues.push(parseFloat(value));
                            else
                                filteredValues.push('0d');
                        });
                        const average = array => array.reduce((a, b) => a + b) / array.length;
                        avgBrightBeadsPerTileNumber = average(filteredValues);
                    }
                    else {
                        avgBrightBeadsPerTileNumber = 0;
                    }
                    result.MinBrightBeadsNumber = minBrightBeadsNumber;
                    result.MaxBrightBeadsNumber = maxBrightBeadsNumber;
                    result.AvgBrightBeadsPerTileNumber = Math.round(avgBrightBeadsPerTileNumber);
                }
                else {
                    let beadsColumn = data.filter(w => w.count_beads_sig_gt_threshold !== "").map(r => r.count_beads_sig_gt_threshold);
                    let beadsColumnAll = data.filter(w => w.count_beads_sig_gt_threshold_All !== "" && w.count_beads_sig_gt_threshold_All !== null).map(r => r.count_beads_sig_gt_threshold_All);
                    //var beadsNumber = parseInt(beadsColumn.map(r => parseFloat(r)).reduce((a, b) => a + b, 0));
                    let beadsNumberAll = beadsColumnAll.length !== 0 ? beadsColumnAll.filter(w => w !== '' && w !== null && w !== ' ' && w !== undefined).map(r => parseInt(r)).reduce((a, b) => a + b, 0) : 0;

                    let sortedBeads = data.sort((a, b) => parseFloat(a.count_beads_sig_gt_threshold) - parseFloat(b.count_beads_sig_gt_threshold));
                    let sortedBeadsCount = sortedBeads.length;
                    let min10 = parseInt((sortedBeadsCount * 10) / 100);
                    let max10 = parseInt((sortedBeadsCount * 90) / 100);
                    let minBeadsNumber10 = sortedBeads[min10].count_beads_sig_gt_threshold;
                    let maxBeadsNumber10 = sortedBeads[max10].count_beads_sig_gt_threshold;
                    let minBeadsNumber = minBeadsNumber10;
                    let maxBeadsNumber = maxBeadsNumber10;
                    let avgBeadsPerTileNumber = average(beadsColumn.map(r => parseFloat(r)));

                    let signalsColumn = data.map(r => r.mean_Sig).filter(w => w !== "");
                    //var signalsNumber = signalsColumn.filter(w => w !== null).map(r => parseFloat(r)).reduce((a, b) => a + b, 0);
                    let sortedSignals = data.filter(f => f.mean_Sig !== '').sort((a, b) => parseFloat(a.mean_Sig) - parseFloat(b.mean_Sig));
                    let sortedSignalsCount = sortedSignals.length;
                    let min10_signals = parseInt((sortedSignalsCount * 10) / 100);
                    let max10_signals = parseInt((sortedSignalsCount * 90) / 100);
                    let minSignalsNumber10 = parseFloat(sortedSignals[min10_signals].mean_Sig);
                    let maxSignalsNumber10 = parseFloat(sortedSignals[max10_signals].mean_Sig);
                    let minSignalsNumber = minSignalsNumber10;
                    let maxSignalsNumber = maxSignalsNumber10;
                    let avgSignalsPerTileNumber = average(signalsColumn.map(r => parseFloat(r)));

                    let snrColumn = data.map(r => parseFloat(r.mean_Sig !== "" ? r.mean_Sig : "0") / parseFloat(r.mean_SigStd !== "" ? r.mean_SigStd : "0"));
                    //var snrNumber = snrColumn.map(r => parseFloat(r)).reduce((a, b) => a + b);
                    let sortedSnr = data.filter(f => f.mean_Sig !== '').sort((a, b) => parseFloat(a.mean_Sig) - parseFloat(b.mean_Sig));
                    let sortedSnrCount = sortedSnr.length;
                    let min10_snr = parseInt((sortedSnrCount * 10) / 100);
                    let max10_snr = parseInt((sortedSnrCount * 90) / 100);
                    let minSnrNumber10 = parseFloat(sortedSnr[min10_snr].mean_Sig) / parseFloat(sortedSnr[min10].mean_SigStd);
                    let maxSnrNumber10 = parseFloat(sortedSnr[max10_snr].mean_Sig) / parseFloat(sortedSnr[max10].mean_SigStd);
                    let minSnrNumber = minSnrNumber10;
                    let maxSnrNumber = maxSnrNumber10;
                    let avgSnrPerTileNumber = average(snrColumn.map(r => parseFloat(r)));

                    result.TotalBeadsNumber = beadsNumberAll;
                    result.MinBeadsNumber = minBeadsNumber;
                    result.MaxBeadsNumber = parseInt(maxBeadsNumber);
                    result.AvgBeadsPerTileNumber = parseInt(avgBeadsPerTileNumber);
                    result.MinSignalsNumber = parseInt(minSignalsNumber);
                    result.MaxSignalsNumber = parseInt(maxSignalsNumber);
                    result.AvgSignalsPerTileNumber = parseInt(avgSignalsPerTileNumber);
                    result.MinSnrNumber = parseInt(minSnrNumber);
                    result.MaxSnrNumber = parseInt(maxSnrNumber);
                    result.AvgSnrPerTileNumber = parseInt(avgSnrPerTileNumber);

                    let brightBeadsColumn = data.map(r => r.mean_beads_sig_gt_threshold).filter(w => w !== ""/* && w !== null*/);
                    let sortedBrightBeads = data.filter(f => f.mean_beads_sig_gt_threshold !== undefined && !isNaN(f.mean_beads_sig_gt_threshold)).sort((a, b) => parseFloat(a.mean_beads_sig_gt_threshold) - parseFloat(b.mean_beads_sig_gt_threshold));
                    if (sortedBrightBeads.filter(x => (x.mean_beads_sig_gt_threshold) === "-nan").length > 0) {
                        sortedBrightBeads.forEach(x => {
                            if (x.mean_beads_sig_gt_threshold === "-nan")
                                x.mean_beads_sig_gt_threshold = "0";
                        });
                    }
                    //var sortedBrightBeadsCount = sortedBrightBeads.length;
                    //var min10_BrightBeads = (sortedBrightBeadsCount * 10) / 100;
                    //var max10_BrightBeads = (sortedBrightBeadsCount * 90) / 100;
                    let minBrightBeadsNumber10 = sortedBrightBeads[min10].mean_beads_sig_gt_threshold !== undefined && sortedBrightBeads[min10].mean_beads_sig_gt_threshold !== null ? parseFloat(sortedBrightBeads[min10].mean_beads_sig_gt_threshold) : 0;
                    let maxBrightBeadsNumber10 = sortedBrightBeads[min10].mean_beads_sig_gt_threshold !== undefined && sortedBrightBeads[max10].mean_beads_sig_gt_threshold !== null ? parseFloat(sortedBrightBeads[max10].mean_beads_sig_gt_threshold) : 0;;
                    let minBrightBeadsNumber = minBrightBeadsNumber10;
                    let maxBrightBeadsNumber = maxBrightBeadsNumber10;
                    let avgBrightBeadsPerTileNumber;
                    if (brightBeadsColumn !== null && brightBeadsColumn.length > 0) {
                        let filteredValues = [];
                        brightBeadsColumn.forEach(value => {
                            if (value !== undefined && !isNaN(value))
                                filteredValues.push(parseFloat(value));
                            else
                                filteredValues.push('0d');
                        });
                        const average = array => array.reduce((a, b) => a + b) / array.length;
                        avgBrightBeadsPerTileNumber = average(filteredValues);
                    }
                    else {
                        avgBrightBeadsPerTileNumber = 0;
                    }
                    result.MinBrightBeadsNumber = parseInt(minBrightBeadsNumber);
                    result.MaxBrightBeadsNumber = parseInt(maxBrightBeadsNumber);
                    result.AvgBrightBeadsPerTileNumber = Math.round(avgBrightBeadsPerTileNumber);
                }
            }
        }
        catch (error) {
            //console.log(error);
        }

        return result;
    }

    const getThetaProfilingPlotRadiusInitial = () => {
        let exists = state.histogram_plots_data.filter(f => f.runid === state.runId);
        if (exists.length > 0) {
            return exists[0].data.data.map(function (elem) {
                return Math.round(elem.radius);
            }).filter(Utils.onlyUnique)[Math.round((exists[0].data.data.map(function (elem) {
                return Math.round(elem.radius);
            }).filter(Utils.onlyUnique).length - 1) / 2)];
        }
        else return 0;
    };

    const handleExpandClick = id => {
        setExpandedIds(expandedIds.includes(id) ? [...expandedIds.filter(item => item !== id)] : [...expandedIds, id]);
    }

    const handleDensitySliderChange = value => {
        if (value !== undefined && value.length === 2) {
            setDensitySliderChanged(true);
            onMinDensitySpinnerChangeSlider(value[0]);
            onMaxDensitySpinnerChangeSlider(value[1]);           
        }
    }

    useEffect(() => {
        try {
            if (minDensitySpinnerValue !== undefined && maxDensitySpinnerValue !== undefined && isDensitySliderChanged === true) {
                onApplyDensitySpinnerClick();
            }
        }
        catch { }
    }, [minDensitySpinnerValue, maxDensitySpinnerValue]);

    useEffect(() => {
        try {
            if (minSignalSpinnerValue !== undefined && maxSignalSpinnerValue !== undefined && isSignalSliderChanged === true) {
                onApplySignalSpinnerClick();
            }
        }
        catch { }
    }, [minSignalSpinnerValue, maxSignalSpinnerValue]);

    useEffect(() => {
        try {
            if (minSNRSpinnerValue !== undefined && maxSNRSpinnerValue !== undefined && isSNRSliderChanged === true) {
                onApplySNRSpinnerClick();
            }
        }
        catch { }
    }, [minSNRSpinnerValue, maxSNRSpinnerValue]);

    const handleSignalSliderChange = value => {
        if (value !== undefined && value.length === 2) {
            setSignalSliderChanged(true);
            onMinSignalSpinnerChangeSlider(value[0]);
            onMaxSignalSpinnerChangeSlider(value[1]);
        }
    }

    const handleSNRSliderChange = value => {
        if (value !== undefined && value.length === 2) {
            setSNRSliderChanged(true);
            onMinSNRSpinnerChangeSlider(value[0]);
            onMaxSNRSpinnerChangeSlider(value[1]);
        }
    }

    return (
        <div className="tableContainer">
            <Row>
                <Col lg={12} style={{ marginTop: 15 }}>
                    <Card sx={{ minWidth: 275, zIndex: 1, }}>
                        <CardHeader
                            action={
                                <IconButton
                                    onClick={() => handleExpandClick(1)}
                                    aria-expanded={expandedIds.includes(1)}
                                    aria-label="Expand/Collapse"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            }
                            title="Average Signal Per Flow"
                            subheader={state.runId}
                        />
                        <Collapse in={expandedIds.includes(1)} timeout="auto">
                            <CardContent>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        {signalPlotsData !== undefined &&
                                            <>
                                                <Row>
                                                    <Col lg={6}>
                                                        {<RenderPlot id="signalPlot1" data={signalPlotsData && signalPlotsData.data1} layout={signalPlotsData && signalPlotsData.layout1} config={signalPlotsData && signalPlotsData.config} isLoading={state.isSignalPloatsLoading}
                                                            type={'rounded'} width={'100%'} height={300} />}
                                                    </Col>
                                                    <Col lg={6}>
                                                        {<RenderPlot id="signalPlot2" data={signalPlotsData && signalPlotsData.data2} layout={signalPlotsData && signalPlotsData.layout2} config={signalPlotsData && signalPlotsData.config} isLoading={state.isSignalPloatsLoading}
                                                            type={'rounded'} width={'100%'} height={300} />}
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        {(!state.isSignalPloatsLoading && signalPlotsData !== undefined) &&
                                            <Row className="text-center" /* style={{ marginTop: 15, display: 'table', margin: '0 auto', verticalAlign: 'middle' }} className="form-group row" */>
                                                <FlowSpinner flow={state.flow} maxFlows={state.current_run_data.length > 0 ? state.current_run_data[0] : 999} />
                                            </Row>
                                        }
                                        {(signalPlotsData === undefined && !state.isSignalPloatsLoading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'warning'} text={`Failed to display Signal data for ${state.runId}. No data available, or not uploaded`} />
                                            </Col>
                                        }
                                    </ListGroup.Item>
                                </ListGroup>
                            </CardContent>
                        </Collapse>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12} style={{ marginTop: 15 }}>
                    <Card sx={{ minWidth: 275, }}>
                        <CardHeader
                            action={
                                <IconButton
                                    onClick={() => handleExpandClick(2)}
                                    aria-expanded={expandedIds.includes(2)}
                                    aria-label="Expand/Collapse"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            }
                            title="Histogram Charts"
                            subheader={state.runId}
                        />
                        <Collapse in={expandedIds.includes(2)} timeout="auto">
                            <CardContent>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            {(histogramPlotsData !== undefined) &&
                                                <React.Fragment>
                                                    <Col lg={4}>
                                                        {<RenderPlot id="histogramPlot1" data={histogramPlotsData.histogram1 && histogramPlotsData.histogram1.data} layout={histogramPlotsData.histogram1 && histogramPlotsData.histogram1.layout} config={histogramPlotsData.histogram1 && histogramPlotsData.histogram1.config} isLoading={state.isHistogramPloatsLoading}
                                                            type={'rounded'} width={'100%'} height={300} />}
                                                    </Col>
                                                    <Col lg={4}>
                                                        {<RenderPlot id="histogramPlot2" data={histogramPlotsData.histogram2 && histogramPlotsData.histogram2.data} layout={histogramPlotsData.histogram2 && histogramPlotsData.histogram2.layout} config={histogramPlotsData.histogram2 && histogramPlotsData.histogram2.config} isLoading={state.isHistogramPloatsLoading}
                                                            type={'rounded'} width={'100%'} height={300} />}
                                                    </Col>
                                                    <Col lg={4}>
                                                        {<RenderPlot id="histogramPlot3" data={histogramPlotsData.histogram3 && histogramPlotsData.histogram3.data} layout={histogramPlotsData.histogram3 && histogramPlotsData.histogram3.layout} config={histogramPlotsData.histogram3 && histogramPlotsData.histogram3.config} isLoading={state.isHistogramPloatsLoading}
                                                            type={'rounded'} width={'100%'} height={300} />}
                                                    </Col>
                                                </React.Fragment>
                                            }
                                            <Row>
                                                <Col lg={4}>
                                                    {histogramPlotsData !== undefined && histogramPlotsData.histogram1 &&
                                                        <CSVDownloadLink data={histogramPlotsDataCSVTileDescr ? histogramPlotsDataCSVTileDescr : [[]]} filename={`FolderInfoZP_tilesDescription_${state.runId}.csv`} />
                                                    }
                                                </Col>
                                                <Col lg={4}>
                                                    {histogramPlotsData !== undefined && histogramPlotsData.histogram1 &&
                                                        <CSVDownloadLink data={histogramPlotsDataCSV ? histogramPlotsDataCSV : [[]]} filename={`${state.runId}_flow${state.flow}.csv`} />
                                                    }
                                                </Col>
                                                <Col lg={4}>
                                                    {histogramPlotsData !== undefined && histogramPlotsData.histogram1 &&
                                                        <CSVDownloadLink data={histogramPlotsDataCSV ? histogramPlotsDataCSV : [[]]} filename={`${state.runId}_flow${state.flow}.csv`} />
                                                    }
                                                </Col>
                                            </Row>
                                            {((histogramPlotsData === undefined && !state.isHistogramPloatsLoading)) &&
                                                <Col lg={12}>
                                                    <ShowAlert type={'warning'} text={`Missing wafer maps data for run ${state.runId}. No data available, or not uploaded`} />
                                                </Col>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </CardContent>
                        </Collapse>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12} style={{ marginTop: 15 }}>
                    <Card sx={{ minWidth: 275, }}>
                        <CardHeader
                            action={
                                <IconButton
                                    onClick={() => handleExpandClick(3)}
                                    aria-expanded={expandedIds.includes(3)}
                                    aria-label="Expand/Collapse"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            }
                            title="Circle Charts"
                            subheader={state.runId}
                        />
                        <Collapse in={expandedIds.includes(3)} timeout="auto">
                            <CardContent>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <React.Fragment>
                                            {circlePlotsData !== undefined &&
                                                <Row>
                                                    <Col lg={{ span: 3, offset: 1 }}>
                                                        {<RenderPlot id="circlePlot1" data={circlePlotsData.data_density && circlePlotsData.data_density} layout={circlePlotsData.layout_density && circlePlotsData.layout_density} config={circlePlotsData.config && circlePlotsData.config} isLoading={state.isHistogramPloatsLoading}
                                                            type={'circular'} width={'70%'} height={450} />}
                                                    </Col>
                                                    <Col lg={{ span: 3, offset: 1 }}>
                                                        {<RenderPlot id="circlePlot2" data={circlePlotsData.data_signal && circlePlotsData.data_signal} layout={circlePlotsData.layout_signal && circlePlotsData.layout_signal} config={circlePlotsData.config && circlePlotsData.config} isLoading={state.isHistogramPloatsLoading}
                                                            type={'circular'} width={'70%'} height={450} />}
                                                    </Col>
                                                    <Col lg={{ span: 3, offset: 1 }}>
                                                        {<RenderPlot id="circlePlot3" data={circlePlotsData.data_snr && circlePlotsData.data_snr} layout={circlePlotsData.layout_snr && circlePlotsData.layout_snr} config={circlePlotsData.config && circlePlotsData.config} isLoading={state.isHistogramPloatsLoading}
                                                            type={'circular'} width={'70%'} height={450} />}
                                                    </Col>
                                                </Row>
                                            }
                                            {(circlePlotsData === undefined && !state.isHistogramPloatsLoading) &&
                                                <Col lg={12}>
                                                    <ShowAlert type={'warning'} text={`Missing wafer maps data for run ${state.runId}. No data available, or not uploaded`} />
                                                </Col>
                                            }
                                            {statsTable1Data === undefined || (statsTable1Data !== undefined && statsTable1Data.length === 0) ?
                                                <SkeletonLoader noData={statsTable1Data === undefined} isLoading={state.isHistogramPloatsLoading} type={'text'} />
                                                :
                                                <Row>
                                                    <Col lg={4}>
                                                        <React.Fragment>
                                                            <Row style={{ marginTop: 35 }}>
                                                                <Col lg={{ span: 11, offset: 1 }}>
                                                                    <Box >
                                                                        <RangeSliderCommon
                                                                            getAriaLabel={() => 'Range'}
                                                                            defaultValue={[minDensitySpinnerValue, maxDensitySpinnerValue]}
                                                                            onChange={handleDensitySliderChange}
                                                                            valueLabelDisplay={"on"}
                                                                            disabled={false}
                                                                        />
                                                                    </Box>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={{ span: 11, offset: 1 }}>
                                                                    {statsTable1Data.Flow !== 1 ?
                                                                        <Table responsive>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Min radius</th>
                                                                                    <th>Max radius</th>
                                                                                    <th>Total number of rings</th>
                                                                                    <th>Total number of tiles</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{statsTable1Data.MinRadius}</td>
                                                                                    <td>{statsTable1Data.MaxRadius}</td>
                                                                                    <td>{statsTable1Data.TotalRingsNumber}</td>
                                                                                    <td>{Utils.numberWithCommas(statsTable1Data.TotalTilesNumber)}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Total number of beads</th>
                                                                                    <th>Lowest 10% bead density per mm²</th>
                                                                                    <th>Highest 10% bead density per mm²</th>
                                                                                    <th>Average bead density per mm²</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{Utils.numberWithCommas(statsTable1Data.TotalBeadsNumber)}</td>
                                                                                    <td>{Utils.numberWithCommas(statsTable1Data.MinBeadsNumber)}</td>
                                                                                    <td>{Utils.numberWithCommas(statsTable1Data.MaxBeadsNumber)}</td>
                                                                                    <td>{Utils.numberWithCommas(statsTable1Data.AvgBeadsPerTileNumber)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan="8" style={{ color: 'orange' }}>{'*Beads with Signal > 250GL'}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table> :
                                                                        statsTable1Data.Flow === 1 && <Table responsive>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Min radius</th>
                                                                                    <th>Max radius</th>
                                                                                    <th>Total number of rings</th>
                                                                                    <th>Total number of tiles</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{statsTable1Data.MinRadius}</td>
                                                                                    <td>{statsTable1Data.MaxRadius}</td>
                                                                                    <td>{statsTable1Data.TotalRingsNumber}</td>
                                                                                    <td>{Utils.numberWithCommas(statsTable1Data.TotalTilesNumber)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ color: 'orange' }}>{statsTable1Data.MinRadius}</td>
                                                                                    <td style={{ color: 'orange' }}>{statsTable1Data.MaxRadius}</td>
                                                                                    <td style={{ color: 'orange' }}>{statsTable1Data.TotalRingsNumber}</td>
                                                                                    <td style={{ color: 'orange' }}>{Utils.numberWithCommas(statsTable1Data.TotalTilesNumber)}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Total number of beads</th>
                                                                                    <th>Lowest 10% bead density per mm²</th>
                                                                                    <th>Highest 10% bead density per mm²</th>
                                                                                    <th>Average bead density per mm²</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{Utils.numberWithCommas(statsTable1Data.TotalBeadsNumber)}</td>
                                                                                    <td>{Utils.numberWithCommas(statsTable1Data.MinBeadsNumber)}</td>
                                                                                    <td>{Utils.numberWithCommas(statsTable1Data.MaxBeadsNumber)}</td>
                                                                                    <td>{Utils.numberWithCommas(statsTable1Data.AvgBeadsPerTileNumber)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ color: 'orange' }}>{Utils.numberWithCommas(statsTable1Data.TotalBeadsNumber_ForSignals_250GL)}</td>
                                                                                    <td style={{ color: 'orange' }}>{Utils.numberWithCommas(statsTable1Data.MinBeadsNumber_250GL)}</td>
                                                                                    <td style={{ color: 'orange' }}>{Utils.numberWithCommas(statsTable1Data.MaxBeadsNumber_250GL)}</td>
                                                                                    <td style={{ color: 'orange' }}>{Utils.numberWithCommas(statsTable1Data.AvgBeadsPerTileNumber_250GL)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan="8" style={{ color: 'orange' }}>{'*Beads with Signal > 250GL'}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <React.Fragment>
                                                            <Row style={{ marginTop: 35 }}>
                                                                <Col lg={{ span: 11, offset: 1 }}>
                                                                    <Box>
                                                                        <RangeSliderCommon
                                                                            getAriaLabel={() => 'Range'}
                                                                            defaultValue={[minSignalSpinnerValue, maxSignalSpinnerValue]}
                                                                            onChange={handleSignalSliderChange}
                                                                            valueLabelDisplay={"on"}
                                                                            disabled={false}
                                                                        />
                                                                    </Box>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={{ span: 11, offset: 1 }}>
                                                                    <Table responsive >
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Min radius</th>
                                                                                <th>Max radius</th>
                                                                                <th>Total number of rings</th>
                                                                                <th>Total number of tiles</th>
                                                                                <th>Lowest 10% signals per tile</th>
                                                                                <th>Highest 10% signals per tile</th>
                                                                                <th>Average Signal per tile</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{statsTable1Data.MinRadius}</td>
                                                                                <td>{statsTable1Data.MaxRadius}</td>
                                                                                <td>{statsTable1Data.TotalRingsNumber}</td>
                                                                                <td>{Utils.numberWithCommas(statsTable1Data.TotalTilesNumber)}</td>
                                                                                <td>{Utils.numberWithCommas(statsTable1Data.MinSignalsNumber)}</td>
                                                                                <td>{Utils.numberWithCommas(statsTable1Data.MaxSignalsNumber)}</td>
                                                                                <td>{Utils.numberWithCommas(statsTable1Data.AvgSignalsPerTileNumber)}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <React.Fragment>
                                                            <Row style={{ marginTop: 35 }}>
                                                                <Col lg={{ span: 11, offset: 1 }}>
                                                                    <Box>
                                                                        <RangeSliderCommon
                                                                            getAriaLabel={() => 'Range'}
                                                                            defaultValue={[minSNRSpinnerValue, maxSNRSpinnerValue]}
                                                                            onChange={handleSNRSliderChange}
                                                                            valueLabelDisplay={"on"}
                                                                            disabled={false}
                                                                        />
                                                                    </Box>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={{ span: 11, offset: 1 }}>
                                                                    <Table responsive>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Min radius</th>
                                                                                <th>Max radius</th>
                                                                                <th>Total number of rings</th>
                                                                                <th>Total number of tiles</th>
                                                                                <th>{th1Header}</th>
                                                                                <th>{th2Header}</th>
                                                                                <th>{th3Header}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{statsTable1Data.MinRadius}</td>
                                                                                <td>{statsTable1Data.MaxRadius}</td>
                                                                                <td>{statsTable1Data.TotalRingsNumber}</td>
                                                                                <td>{Utils.numberWithCommas(statsTable1Data.TotalTilesNumber)}</td>
                                                                                <td>{Utils.numberWithCommas(!isNaN(statsTable1Data.MinSnrNumber) && statsTable1Data.MinSnrNumber !== undefined ? statsTable1Data.MinSnrNumber : statsTable1Data.MinBrightBeadsNumber )}</td>
                                                                                <td>{Utils.numberWithCommas(!isNaN(statsTable1Data.MaxSnrNumber) && statsTable1Data.MaxSnrNumber !== undefined ? statsTable1Data.MaxSnrNumber : statsTable1Data.MaxBrightBeadsNumber)}</td>
                                                                                <td>{Utils.numberWithCommas(!isNaN(statsTable1Data.AvgSnrPerTileNumber) && statsTable1Data.AvgSnrPerTileNumber !== undefined ? statsTable1Data.AvgSnrPerTileNumber : statsTable1Data.AvgBrightBeadsPerTileNumber)}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    </Col>
                                                </Row>
                                            }
                                        </React.Fragment>
                                    </ListGroup.Item>
                                </ListGroup>
                            </CardContent>
                        </Collapse>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12} style={{ marginTop: 15 }}>
                    <Card sx={{ minWidth: 275, }}>
                        <CardHeader
                            action={
                                <IconButton
                                    onClick={() => handleExpandClick(4)}
                                    aria-expanded={expandedIds.includes(4)}
                                    aria-label="Expand/Collapse"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            }
                            title="Theta Profiling"
                            subheader={state.runId}
                        />
                        <Collapse in={expandedIds.includes(4)} timeout="auto">
                            <CardContent>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <React.Fragment>
                                            {(thetaProfilingPlotsData !== undefined && !state.isHistogramPloatsLoading) &&
                                                <Row className="align-items-center">
                                                    <Row>
                                                        <Col lg={1} style={{ display: 'table', margin: '0 auto' }}>
                                                            <TextBox style={{ width: 90, marginLeft: '30%', }} label={'Radius (mm):'} value={thetaProfilingPlotsRadius} onChange={onThetaProfilingRadiusChange} onKeyPress={handleThetaProfilingRadiusKeyPress} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ height: 100 }}>
                                                        <Col lg={12} style={{ marginTop: 10 }}>
                                                            <AlertPopUp show={thetaProfilingRadiusPopOver} text={thetaProfilingRadiusPopOverText} />
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            }
                                            {thetaProfilingPlotsData !== undefined &&
                                                <Row>
                                                    <Col lg={6}>
                                                        {<RenderPlot data={thetaProfilingPlotsData.data_theta1 && thetaProfilingPlotsData.data_theta1} layout={thetaProfilingPlotsData.layout_theta1 && thetaProfilingPlotsData.layout_theta1} config={thetaProfilingPlotsData.config && thetaProfilingPlotsData.config} isLoading={state.isHistogramPloatsLoading}
                                                            type={'rounded'} width={'100%'} height={300} />}
                                                    </Col>
                                                    <Col lg={6}>
                                                        {<RenderPlot data={thetaProfilingPlotsData.data_theta2 && thetaProfilingPlotsData.data_theta2} layout={thetaProfilingPlotsData.layout_theta2 && thetaProfilingPlotsData.layout_theta2} config={thetaProfilingPlotsData.config && thetaProfilingPlotsData.config} isLoading={state.isHistogramPloatsLoading}
                                                            type={'rounded'} width={'100%'} height={300} />}
                                                    </Col>
                                                </Row>
                                            }
                                            {(thetaProfilingPlotsData === undefined && !state.isHistogramPloatsLoading) &&
                                                <Col lg={12}>
                                                    <ShowAlert type={'warning'} text={`Missing theta profiling data for run ${state.runId}. No data available, or not uploaded`} />
                                                </Col>
                                            }
                                        </React.Fragment>
                                    </ListGroup.Item>
                                </ListGroup>
                            </CardContent>
                        </Collapse>
                    </Card>
                </Col>
            </Row>
        </div>
    )
};

export default Signals;