const RenderSignalCharts = (csv, flowNames, flowsNoGap, defaultFlowOrder) => {
    try {
        var rows = csv;
        function unpack(rows, key) {
            return rows.map(function (row) { return key === 'flowNumber' ? parseInt(row[key]) : row[key]; });
        }      

        function unpackByCaption(rows, key, caption, defaultFlowOrder) {
            var data = [];
            rows.filter(function (row, index) {
                if (defaultFlowOrder !== undefined) {
                    if (caption === 'T' && index % 4 === defaultFlowOrder.indexOf('T')) {
                        data.push(row[key]);
                    }
                    else if (caption === 'A' && index % 4 === defaultFlowOrder.indexOf('A')) {
                        data.push(row[key]);
                    }
                    else if (caption === 'C' && index % 4 === defaultFlowOrder.indexOf('C')) {
                        data.push(row[key]);
                    }
                    else if (caption === 'G' && index % 4 === defaultFlowOrder.indexOf('G')) {
                        data.push(row[key]);
                    }
                }
                else {
                    if (caption === 'T' && index % 4 === 0) {
                        data.push(row[key]);
                    }
                    else if (caption === 'A' && index % 4 === 1) {
                        data.push(row[key]);
                    }
                    else if (caption === 'C' && index % 4 === 2) {
                        data.push(row[key]);
                    }
                    else if (caption === 'G' && index % 4 === 3) {
                        data.push(row[key]);
                    }
                }
            });

            return data;
        }

        function unpackByCaptionOrdered(rows, key, caption, ordered) {
            var data = [];
            rows.filter(function (row, index) {
                if ((parseInt(row.flow) - 1) % 4 === ordered.indexOf(caption)) {
                    data.push(row[key]);
                }
            });

            return data;
        } 

        function parseDate(timestamp) {
            var year = timestamp.substring(0, 2);
            var month = parseInt(timestamp.substring(2, 4)) - 1;
            var day = timestamp.substring(4, 6);
            var hours = timestamp.substring(7, 9);
            var minutes = timestamp.substring(9, 11);
            var seconds = timestamp.substring(11, 13);
            return new Date('20' + year, month, day, hours, minutes, seconds);
        }

        var runTimeValues = [];

        function diff(timestamp1, timestamp2, index, array) {
            let date2;
            let date1;
    
            if (timestamp1.timeStamp !== undefined && timestamp2.timeStamp !== undefined && timestamp1.timeStamp.includes("_")) {
                date2 = parseDate(timestamp2.timeStamp);
                date1 = parseDate(timestamp1.timeStamp);
            } else {
                date2 = new Date(timestamp2.timeStamp);
                date1 = new Date(timestamp1.timeStamp);
            }   
           
            runTimeValues.push((date2 - date1) / 1000 / 60);
            return timestamp2;
        }

        function getRuntimeValues(rows) {
            try {
                if (rows.data !== undefined && rows.data.length) return rows.data.reduce(diff);                
                else return rows.reduce(diff);
            }
            catch { }
        }

        getRuntimeValues(rows);

        function makeflow(rows) {
            return Array.from({ length: rows.length }, (_, index) => index + 1);
        } 

        function makeflowbyIndex(rows, idx) {
            return rows.map((currElement, index) => {
                return index + idx;
            })
        }

        function makeflow_snr(rows) {
            return rows.map((currElement, index) => {
                return index + 1;
            })
        }

        var data_chart1;

        var isBrightBeads = false;
        if (rows.data[0]?.mean_beads_sig_gt_threshold !== undefined) {
            isBrightBeads = true;
        }

        if (isBrightBeads) {
            var Mean_Sig = {
                type: "scatter",
                mode: "lines",
                name: 'Mean Signal Level per flow',
                x: flowNames !== null ? (flowsNoGap !== null ? flowsNoGap : makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 1)) : makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 1),
                y: unpack(rows.data !== undefined ? rows.data : rows, 'mean_Sig'),
                line: { color: '#1f77b4' }
            }
    
            var Mean_Beads = {
                type: "scatter",
                mode: "lines",
                name: 'Mean Signal of Bright Beads',
                x: flowNames !== null ? (flowsNoGap !== null ? flowsNoGap : makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 1)) : makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 1),
                y: unpack(rows.data !== undefined ? rows.data : rows, 'mean_beads_sig_gt_threshold'),
                line: { color: '#FFFF00' }
            }
    
            var StdDev_Sig = {
                type: "scatter",
                mode: "lines",
                name: 'STD Signal Level per flow',              
                x: flowNames !== null ? (flowsNoGap !== null ? flowsNoGap : makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 1)) : makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 1),
                y: unpack(rows.data !== undefined ? rows.data : rows, 'stdDev_Sig'),
                line: { color: '#ff7f0e' }
            }
    
            data_chart1 = [Mean_Sig, Mean_Beads, StdDev_Sig];
        } else {
                var Mean_Sig = {
                    type: "scatter",
                    mode: "lines",
                    name: 'Mean Signal Level per flow',                 
                    x: flowNames !== null ? (flowsNoGap !== null ? flowsNoGap : makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 1)) : makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 1),
                    y: unpack(rows.data !== undefined ? rows.data : rows, 'mean_Sig'),
                    line: { color: '#1f77b4' }
                }
    
                var StdDev_Sig = {
                    type: "scatter",
                    mode: "lines",
                    name: 'STD Signal Level per flow',                   
                    x: flowNames !== null ? (flowsNoGap !== null ? flowsNoGap : makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 1)) : makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 1),
                    y: unpack(rows.data !== undefined ? rows.data : rows, 'stdDev_Sig'),
                    line: { color: '#ff7f0e' }
                }
    
                data_chart1 = [Mean_Sig, StdDev_Sig];
        }
        
        var layout_chart1 = {           
            margin: {
                t: 4,
                b: 40,
                l: 40,
                r: 30
            },            
            height: 390,
            xaxis: {
                ticks: 'outside',
                //tickmode: 'array',   
                tickmode: "linear",           
                tickvals: flowNames !== null ? (flowsNoGap !== null ? flowsNoGap : makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 1)) : makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 1),
                ticktext: flowNames !== null ? unpack(flowNames.slice(0, rows.data !== undefined ? rows.data.length : rows.length), 'flowName') : makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 1),               
                text: flowNames !== null ? unpack(flowNames.slice(0, rows.data !== undefined ? rows.data.length : rows.length), 'flowName') : makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 1),
                tick0: 1,  
                dtick: 5,              
            },
            legend: {
                x: 0.2,
                y: 1.5
            },          
            shapes: flowNames !== null ? DrawShapeLine(unpack(flowNames, 'flowNumber')) : [],
        };

        function DrawShapeLine(data) {
            var result = [];
            data.map((curr, i, array) => {
                var prev = array[i - 1] ? parseInt(array[i - 1]) : 0;
                if (parseInt(curr) - parseInt(prev) > 1) {
                    result.push(
                        {
                            type: 'line',
                            x0: parseInt(curr),
                            y0: 0,
                            x1: parseInt(curr),
                            yref: 'paper',
                            y1: 1,
                            line: {
                                color: 'grey',
                                width: 1.5,
                                dash: 'dot'
                            }
                        }
                    );
                }
            });

            return result;
        }       

        function GenerateChartData() {
            var data = [];
            var Mean_Sig_T = {
                type: "scatter",
                mode: "lines",
                name: 'T Mean Signal per cycle',
                x: makeflow_snr(rows.data !== undefined ? rows.data : rows),
                y: unpackByCaption(rows.data !== undefined ? rows.data : rows, 'mean_Sig', 'T', defaultFlowOrder),              
                line: { color: 'red' }
            }
           
            var Mean_Sig_A = {
                type: "scatter",
                mode: "lines",
                name: 'A Mean Signal per cycle',
                x: makeflow_snr(rows.data !== undefined ? rows.data : rows),
                y: unpackByCaption(rows.data !== undefined ? rows.data : rows, 'mean_Sig', 'A', defaultFlowOrder),
                line: { color: 'green' }
            }
           
            var Mean_Sig_C = {
                type: "scatter",
                mode: "lines",
                name: 'C Mean Signal per cycle',
                x: makeflow_snr(rows.data !== undefined ? rows.data : rows),
                y: unpackByCaption(rows.data !== undefined ? rows.data : rows, 'mean_Sig', 'C', defaultFlowOrder),                
                line: { color: '#1f77b4' }
            }
            
            var Mean_Sig_G = {
                type: "scatter",
                mode: "lines",
                name: 'G Mean Signal per cycle',
                x: makeflow_snr(rows.data !== undefined ? rows.data : rows),
                y: unpackByCaption(rows.data !== undefined ? rows.data : rows, 'mean_Sig', 'G', defaultFlowOrder),
                line: { color: 'black' }
            }           

            if (defaultFlowOrder !== undefined) {
                defaultFlowOrder.map(m => {
                    switch (m) {
                        case 'T':
                            data.push(Mean_Sig_T);
                            break;
                        case 'G':
                            data.push(Mean_Sig_G);
                            break;
                        case 'C':
                            data.push(Mean_Sig_C);
                            break;
                        case 'A':
                            data.push(Mean_Sig_A);
                            break;
                        default:
                            break;
                    }
                });
            }
            else {
                data.push(Mean_Sig_T);
                data.push(Mean_Sig_G);
                data.push(Mean_Sig_C);
                data.push(Mean_Sig_A);
            }

            return data;
        }

        function GetSignal2LineColor(name) {
            switch (name) {
                case 'T':
                    return 'red';                   
                case 'A':
                    return 'green';                   
                case 'C':
                    return '#1f77b4';                   
                case 'G':
                    return 'black';                 
                default:
                    break;
            }
        }

        function GenerateSignal2DataByFlowOrder(flows) {
            var ordered = [];
            for (var i = 0; i <= 3; i++) {
                ordered.push(flows[i].flowName);
            }
            var data = [];
            ordered.forEach(val => {
                var Mean_Sig = {
                    type: "scatter",
                    mode: "lines",
                    connectgaps: false,
                    name: `${val} Mean Signal per cycle`,                    
                    x: makeflow_snr(rows.data !== undefined ? rows.data : rows),
                    y: unpackByCaptionOrdered(rows.data !== undefined ? rows.data : rows, 'mean_Sig', val, ordered),
                    line: { color: GetSignal2LineColor(val) },
                }
                data.push(Mean_Sig);
            });
            return data;
        } 

        var data_chart2 = flowNames !== null ? GenerateSignal2DataByFlowOrder(flowNames) : GenerateChartData();

        var layout_chart2 = {           
            margin: {
                t: 4,
                b: 40,
                l: 40
            },           
            height: 390,
            xaxis: {
                connectgaps: false,
                ticks: 'outside',
                tickmode: 'array',
                tickvals: makeflow_snr(rows.data !== undefined ? rows.data : rows),
                ticktext: makeflow_snr(rows.data !== undefined ? rows.data : rows),
                text: makeflow_snr(rows.data !== undefined ? rows.data : rows),
                dtick: 1,            
                tickcolor: 'white',
                automargin: true,              
            },
            showlegend: true,
            legend: {
                orientation: 'h',               
                y: 1.5
            },           
        }; 
        
        var RunTime = {
            type: "scatter",
            mode: "lines",
            name: 'Runtime per flow',
            x: makeflowbyIndex(rows.data !== undefined ? rows.data : rows, 2),
            y: runTimeValues,
            line: { color: '#1f77b4' }
        }

        var data_chart3 = [RunTime];

        var layout_chart3 = {           
            margin: {
                l: 35,
                t: 4,
                b: 50
            },           
            height: 390,
            xaxis: {
                autotick: false,
                ticks: 'outside',
                tick0: 1,
                dtick: 4,
                ticklen: 8,
                tickwidth: 4,                
            },
            yaxis: {
                type: 'time'
            },
            showlegend: true,
            legend: {
                x: 0.2,
                y: 1.5
            },           
        };


        var Align_X = {
            type: "scatter",
            mode: "lines",
            name: 'Stage Alignment X (pix)',
            x: makeflow(rows.data !== undefined ? rows.data : rows),
            y: unpack(rows.data !== undefined ? rows.data : rows, 'avg_Align_X'),
            line: { color: '#1f77b4' }
        }

        var Align_Y = {
            type: "scatter",
            mode: "lines",
            name: 'Stage Alignment Y (pix)',
            x: makeflow(rows.data !== undefined ? rows.data : rows),
            y: unpack(rows.data !== undefined ? rows.data : rows, 'avg_Align_Y'),
            line: { color: '#ff7f0e' }
        }

        var data_chart4 = [Align_X, Align_Y];

        var layout_chart4 = {           
            margin: {
                l: 35,
                t: 4,
                b: 50
            },           
            height: 390,
            xaxis: {
                autotick: false,
                ticks: 'outside',
                tick0: 1,
                dtick: 4,
                ticklen: 8,
                tickwidth: 4,                
            },
            yaxis: {
                type: 'time'
            },
            showlegend: true,
            legend: {
                x: 0.2,
                y: 1.8
            },           
        };

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data1: data_chart1, layout1: layout_chart1, data2: data_chart2, layout2: layout_chart2, data3: data_chart3, layout3: layout_chart3, data4: data_chart4, layout4: layout_chart4, config: config };
    }
    catch {        
        return undefined;
    }
}

export default RenderSignalCharts;