import Cookies from 'universal-cookie';

const API = {};

const getToken = () => {
    const cookies = new Cookies(null, { path: '/' });
    return cookies.get('token');
}

const sendRequest = async (url, method) => {   
    const options = {
        method,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const token = getToken();
    if (token) options.headers.Authorization = token;      

    return await fetch(`${process.env.REACT_APP_API_URL}${url}`, options);
}


API.logOut = () => {
    const instance = sendRequest('/nexus/proxy/logout', 'GET');
    return instance;
} 

API.isAuthenticated = () => {
    const instance = sendRequest('/nexus/proxy/auth', 'GET');
    return instance;
}

export default API;