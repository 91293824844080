import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    getVCReportPlots, getFMetricsTableData, setErrorPlotLoading, setReadLengthPlotLoading, setBarcodeCoveragePlotIsLoading, setPolyPlotLoading, setFMetricsTableRenderData, setBERTableRenderData, setBERHMERTableRenderData, setPhasingTableRenderData, setQTableRenderData,
    setBarcodeDetailsButton, getPpmSeqReportList, setBarcodeCoverageBarcode, geBarcodeCoveragePlots
} from "../actions/index";
import * as Utils from '../../utils/utils';
import { Tab, ListGroup, Row, Col, Nav, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import RunConfiguration from '../runconfiguration/runconfiguration';
import ReadFiltering from '../readfiltering/readfiltering';
import Image from '../image/image';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import IconButton from '@mui/material/IconButton';
import InsightsIcon from '@mui/icons-material/Insights';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import * as RenderErrorPlots from '../../plots/errorplots/errorplots';
import * as RenderReadLengthPlots from '../../plots/readlengthplots/readlengthplots';
import RenderPlot from '../plots/renderplot';
import * as RenderBCPlots from '../../plots/barcodecoverageplots/barcodecoverageplots';
import * as mpld3 from 'mpld3';
import * as RenderCoveragePlots from '../../plots/coverageplots/coverageplots';
import useDeviceQuery from '../../utils/hooks/useDeviceMedia';
import * as RenderPolyPlots from '../../plots/poly/poly';
import SummaryHeader from '../summaryheader/summaryheader';
import html2canvas from "html2canvas";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CSVDownloadLink from '../downloadlink/csvdownloadlink';
import Button from '@mui/material/Button';
import jsPDFGenerator from '../../utils/jsPDFGenerator';
import useBarcodeStickyHeader from '../../utils/hooks/useBarcodeStickyHeader';
import HtmlPopup from '../htmlPopup/htmlPopup';

const RunSummary = () => {
    const state = useSelector(state => state);
    const barcode_coverage_selected_barcode = useSelector(state => state.barcode_coverage_selected_barcode);
    const dispatch = useDispatch();

    const [expandedIds, setExpandedIds] = React.useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]);
    const [error1PlotDataPNG, setError1PlotDataPNG] = useState(undefined);
    const [error2PlotDataPNG, setError2PlotDataPNG] = useState(undefined);
    const [error1PlotData, setError1PlotData] = useState(undefined);
    const [error2PlotData, setError2PlotData] = useState(undefined);
    const [rl1PlotData, setRl1PlotData] = useState([]);
    const [rl2PlotData, setRl2PlotData] = useState([]);
    const [rl3PlotData, setRl3PlotData] = useState([]);
    const [polyPlot1, setPolyPlot1] = useState([]);
    const [polyPlot2, setPolyPlot2] = useState([]);
    const [barcodeCoveragePlotDataPNG, setBarcodeCoveragePlotDataPNG] = useState(undefined);
    const [barcodeCoveragePlotData, setBarcodeCoveragePlotData] = useState(undefined);
    const [barcodeQualityPlotDataPNG, setBarcodeQualityPlotDataPNG] = useState(undefined);
    const [barcodeQualityPlotData, setBarcodeQualityPlotData] = useState(undefined);
    const [barcodeQualityPlotBerPerFlowData, setBarcodeQualityPlotBerPerFlowData] = useState(undefined)
    const [fwhmPlotData, setFwhmPlotData] = useState(undefined);
    const [covPlot1, setCovPlot1] = useState(undefined);
    const [covPlot2, setCovPlot2] = useState(undefined);
    const [signalPlotsData, setSignalsPlotData] = useState([]);
    const [signalPlotsPNGData, setSignalsPlotPNGData] = useState([]);
    const [uniformityPlotsData, setUniformityPlotData] = useState([]);
    const [uniformityPlotsXData, setUniformityPlotXData] = useState([]);
    const [uniformitySignalPlotsData, setUniformitySignalPlotData] = useState([]);
    const [phasingPlotsData, setPhasingPlotData] = useState([]);
    const [signalLinearityPlotsData, setSignalLinearityPlotsData] = useState([]);
    const [averageReadLength, setAverageReadLength] = useState(undefined);
    const [homoIndelError, setHomoIndelError] = useState(undefined);
    const [totalBasesValue, setTotalBasesValue] = useState(undefined);
    const [selectedBarcode, setSelectedBarcode] = useState('TT');
    const [selectedBarcodeBySample, setSelectedBarcodeBySample] = useState({ barcode: 'TT', sample: 'TT' });
    const [barcodeDetailsTableData, setBarcodeDetailsTableData] = useState(undefined);
    const [meanCoverageValue, setMeanCoverageValue] = useState(undefined);
    const [barcodeDetailsReadLengthPlot, setBarcodeDetailsReadLengthPlot] = useState(undefined);
    const [barcodeDetailsReadLengthHeader, setBarcodeDetailsReadLengthHeader] = useState(undefined);
    const [gcBeashHeader, setGcBeashHeader] = useState(undefined);
    const [gcBeasTableData, setGcBeasTableData] = useState(undefined);
    const [renderMenuItemsDataPoly, setRenderMenuItemsDataPoly] = useState([]);
    const [polyH5Plot1, setPolyH5Plot1] = useState([]);
    const [polyH5Plot2, setPolyH5Plot2] = useState([]);
    const [polyH5Plot3, setPolyH5Plot3] = useState([]);
    const [hoverOn, setHoverOn] = useState(undefined);
    const [isPDFRendering, setPDFRendering] = useState(false);
    const [barcodeTableDownloadData, setBarcodeTableDownloadData] = useState(undefined);
    const [qTableBarcodeMetricData, setQTableBarcodeMetricData] = useState([]);
    const [bsqBarcodeDetailPlot, setBsqBarcodeDetailPlot] = useState(undefined);
    const [bsqBarcodeDetailValue, setBsqBarcodeDetailValue] = useState(undefined);
    const [selectedQTableDataType, setSelectedQTableDataType] = useState(localStorage.getItem('active_barcode_table_button') || '1k');
    const [activeKey, setActiveKey] = useState("link-1");
    const [plotURIs, setPlotURIs] = useState([]);
    const [runDetaildPDFTable, setRunDetaildPDFTable] = useState([]);
    const [pdfLoading, setPdfLoading] = useState(false);    
    const [ppmSeqHTMLList, setPpmSeqHTMLList] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [hoverIndex, setHoverIndex] = useState(-1);
    const [basePlotCalc, setbasePlotCalc] = useState(undefined);
    const [averageRLPlotCalc, setAverageRLPlotCalc] = useState(undefined);
    
    const tableRef = useRef(null);
    const { isSticky } = useBarcodeStickyHeader(tableRef);
    

    const handleTabSelect = (key) => {
      setActiveKey(key);
    };

    const { isMedium1Screen, isMedium2Screen, isMedium3Screen, isSmallScreen, isBigScreen, isBigScreen1, isBigScreen2, isBigScreen3, isBigScreen4, isBigScreen5 } = useDeviceQuery();

    useEffect(() => {
        dispatch(setErrorPlotLoading(true));
        setError1PlotData(undefined);
        setError2PlotData(undefined);
        setError1PlotDataPNG(undefined);
        setError2PlotDataPNG(undefined);
        dispatch(setPolyPlotLoading(true));
        setPolyPlot1([]);
        setPolyPlot2([]);
        setBarcodeCoveragePlotData(undefined);
        setBarcodeCoveragePlotDataPNG(undefined);
        setBarcodeQualityPlotData(undefined);
        setBarcodeQualityPlotDataPNG(undefined);
        setFwhmPlotData(undefined);
        setCovPlot1(undefined);
        setCovPlot2(undefined);
        setRl1PlotData(undefined);
        setRl2PlotData(undefined);
        setRl3PlotData(undefined);
        setSignalsPlotData([]);
        setSignalsPlotPNGData([]);
        setUniformityPlotData([]);
        setUniformityPlotXData([]);
        setPhasingPlotData([]);
        setUniformitySignalPlotData([]);
        setBarcodeQualityPlotBerPerFlowData(undefined);
        setSignalLinearityPlotsData([]);
        setTotalBasesValue(undefined);
        setSelectedBarcode('TT');
        dispatch(setBarcodeCoverageBarcode('TT'));
        setSelectedBarcodeBySample({ barcode: 'TT', sample: 'TT' });
        setBarcodeDetailsTableData(undefined);
        setMeanCoverageValue(undefined);
        setBarcodeDetailsReadLengthPlot(undefined);
        setBarcodeDetailsReadLengthHeader(undefined);
        setGcBeashHeader(undefined);
        setGcBeasTableData(undefined);
        setPolyH5Plot1(undefined);
        setPolyH5Plot2(undefined);
        setPolyH5Plot3(undefined);
        setHoverOn(undefined);
        setAverageReadLength(undefined);
        setHomoIndelError(undefined);
        setBarcodeTableDownloadData(undefined);
        setQTableBarcodeMetricData([]);
        setBsqBarcodeDetailPlot(undefined);
        setBsqBarcodeDetailValue(undefined);
        //setSelectedQTableDataType(undefined);
        setRunDetaildPDFTable([]);
        setPpmSeqHTMLList(undefined);
        setbasePlotCalc(undefined);
        setAverageRLPlotCalc(undefined);
        Utils.purgePlot('readLengthPlot');
        Utils.purgePlot('bsqBarcodeDetailPlot');
        Utils.purgePlot('errorPlot1');
        Utils.purgePlot('errorPlot2');
        Utils.purgePlot('polyPlot1');
        Utils.purgePlot('polyPlot2');
        Utils.purgePlot('polyPlot3');
    }, [state.runId]);

    useEffect(() => {
        try {
            Utils.purgePlot('polyPlot1');
            Utils.purgePlot('polyPlot2');
            Utils.purgePlot('polyPlot3');
            dispatch(setPolyPlotLoading(true));
            let exists = state.poly_plots_data.filter(f => f.runid === state.runId);
            if (exists.length > 0 && exists[0].data !== undefined && exists[0].data.type !== undefined && exists[0].data.type === 'h5') {
                let data1 = exists[0].data.data.plot1[state.by_camera_value_global];
                if (data1) {
                    let poly1 = RenderPolyPlots.RenderPolyPlot1(data1, state.runId, state.by_camera_value_global);
                    setPolyH5Plot1(poly1);
                }

                let data2 = exists[0].data.data.plot2[state.by_camera_value_global];

                if (data2) {
                    let poly2 = RenderPolyPlots.RenderPolyPlot2(data2, state.runId, state.by_camera_value_global);
                    setPolyH5Plot2(poly2);
                }

                let data3 = exists[0].data.data.plot3[state.by_camera_value_global];

                if (data3) {
                    let poly3 = RenderPolyPlots.RenderPolyPlot3(data3, state.runId, state.by_camera_value_global);
                    setPolyH5Plot3(poly3);
                }
                dispatch(setPolyPlotLoading(false));
            }
            else if (exists.length > 0 && exists[0].data !== undefined && exists[0].data.type !== undefined && exists[0].data.type === 'png') {
                setPolyPlot1(exists[0].data.data.plot1);
                setPolyPlot2(exists[0].data.data.plot2);
                dispatch(setPolyPlotLoading(false));
            }
            else {
                setPolyPlot1(undefined);
                setPolyPlot2(undefined);
                dispatch(setPolyPlotLoading(false));
            }
        }
        catch {
            setPolyPlot1(undefined);
            setPolyPlot2(undefined);
            setPolyH5Plot1(undefined);
            setPolyH5Plot2(undefined);
            setPolyH5Plot3(undefined);
            dispatch(setPolyPlotLoading(false));
        }
    }, [state.poly_plots_data, state.by_camera_value_global]);

    const handleSubTabsRunSummarySelect = key => {
        if (key === 'link-3') {
            if (state.vcreport_exists) {
                dispatch(getVCReportPlots(Utils.getRunIdWithoutCamera(state.runId), 'VCREPORTALL', false, state.jwt));
            }
        }
    }

    const handleExpandClick = id => {
        setExpandedIds(expandedIds.includes(id) ? [...expandedIds.filter(item => item !== id)] : [...expandedIds, id]);
    }

    useEffect(() => {
        try {
            dispatch(setErrorPlotLoading(true));
            setError1PlotData(undefined);
            setError2PlotData(undefined);
            setError1PlotDataPNG(undefined);
            setError2PlotDataPNG(undefined);
            let exists = state.error_plots.filter(f => f.runid === state.runId);
            if (exists.length > 0 && exists[0].data !== undefined) {
                if (exists[0].data.plot1 !== undefined && typeof exists[0].data.plot1 === 'object') {
                    let data = exists[0].data.plot1[state.by_camera_value_global];
                    let error1 = RenderErrorPlots.RenderErrorPerHistPlot(data, state.runId, state.by_camera_value_global);
                    setError1PlotData(error1);
                }
                else setError1PlotDataPNG(exists[0].data.plot1);

                if (exists[0].data.plot2 !== undefined && typeof exists[0].data.plot2 === 'object') {
                    let data = exists[0].data.plot2[state.by_camera_value_global];
                    let error2 = RenderErrorPlots.RenderErrorNerPerFlowPlot(data, state.runId, state.by_camera_value_global);
                    setError2PlotData(error2);
                }
                else setError2PlotDataPNG(exists[0].data.plot2);
                dispatch(setErrorPlotLoading(false));
            }
            else {
                setError1PlotData(undefined);
                setError2PlotData(undefined);
                setError1PlotDataPNG(undefined);
                setError2PlotDataPNG(undefined);
                dispatch(setErrorPlotLoading(false));
            }
        }
        catch {
            setError1PlotData(undefined);
            setError2PlotData(undefined);
            setError1PlotDataPNG(undefined);
            setError2PlotDataPNG(undefined);
            dispatch(setErrorPlotLoading(false));
        }
    }, [state.error_plots, state.by_camera_value_global]);

    const renderCalcData = data => {
        try {
            Utils.purgePlot('BCJSON0')
            if (data) {
                setBarcodeCoveragePlotData(undefined);
                let exists = state.calculate_coverage_data?.filter(x => x.barcode === barcode_coverage_selected_barcode);
                if (exists[0].calculatedData !== undefined && exists.length > 0) {
                    let gcData = [];
                    var bc = RenderBCPlots.PlotBarcodeCoveragePlot(state.runId, exists[0].calculatedData, exists[0].barcode, false);
                    setBarcodeCoveragePlotData([bc]);
                    exists[0].calculatedData.forEach(element => {
                        let key = element.genome;
                        let sum = element.data;
                        if (key === 'GC 10-20' || key === 'GC 20-30' || key === 'GC 30-40' || key === 'GC 40-50'
                            || key === 'GC 50-60' || key === 'GC 60-70' || key === 'GC 70-80' || key === 'GC 80-90') {
                            gcData.push({ genome: key, median: sum.median, mean: sum.mean, p25: sum.q1, p75: sum.q3 });
                        }
                    });
                    setGcBeasTableData(gcData);
                } else {
                    setGcBeasTableData(undefined);
                    setBarcodeCoveragePlotData(undefined);
                }
            } else {
                setGcBeasTableData(undefined);
                setBarcodeCoveragePlotData(undefined);
            }
        } catch {
            setBarcodeCoveragePlotData(undefined);
            setGcBeasTableData(undefined);
        }
    };

    useEffect(() => {
        try {
            Utils.purgePlot('bsqBarcodeDetailPlot');
            Utils.purgePlot('BCJSON0')
            dispatch(setBarcodeCoveragePlotIsLoading(true));
            setBarcodeCoveragePlotDataPNG(undefined);

            let exists = state.barcode_coverage_plots.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId));
            if (exists.length > 0 && exists[0].data !== undefined) {
                if (exists[0].data !== undefined) {
                    if (exists[0].data.type === 'from_file') {
                        setBarcodeCoveragePlotDataPNG(exists[0].data.data);
                    }
                    else if (exists[0].data.type === 'from_db') {
                        renderBaseQualityPlot(exists[0].data.data, barcode_coverage_selected_barcode);
                        let tt = exists[0].data.data.filter(f => f.barcode === "TT");
                        if (tt && tt.length > 0) {
                            if (tt[0].data) {
                                let data = tt[0].data.data[0].data.bqx;
                                if (data && data.length > 0) {
                                    try {
                                        let q30 = data.map(m => m.slice(30, 60).reduce((a, b) => a + b)).reduce((a, b) => a + b);
                                        let q60 = data.map(m => m.slice(0, 60).reduce((a, b) => a + b)).reduce((a, b) => a + b);
                                        let res = q30 / q60;
                                        setTotalBasesValue(parseInt(res * 100));
                                    }
                                    catch {
                                        setTotalBasesValue(undefined);
                                    }
                                    let rl3 = RenderReadLengthPlots.RenderReadLengthBaseQualityPlot(data, state.runId, state.by_camera_value_global, "TT", averageRLPlotCalc ? Math.floor(averageRLPlotCalc * 1.5) : null);
                                    setRl3PlotData(rl3);
                                    dispatch(setBarcodeCoveragePlotIsLoading(false));
                                } else setRl3PlotData(undefined);
                            } else setRl3PlotData(undefined);
                        } else setRl3PlotData(undefined);
                        dispatch(setBarcodeCoveragePlotIsLoading(false));
                        let dataByBarcode = exists[0].data.data.filter(f => f.barcode === barcode_coverage_selected_barcode);
                        if (dataByBarcode && dataByBarcode.length > 0) {
                            renderCalcData(dataByBarcode)
                            let rl = RenderReadLengthPlots.RenderReadLengthBarcodeDetailsPlot(dataByBarcode[0].data.data[0].data, state.runId, barcode_coverage_selected_barcode, averageRLPlotCalc ? Math.floor(averageRLPlotCalc * 1.5) : null);
                            setBarcodeDetailsReadLengthPlot(rl);
                        } else {
                            renderCalcData(undefined);
                            setBarcodeDetailsReadLengthPlot(undefined);
                        }
                    }
                    else {
                        setBarcodeCoveragePlotDataPNG(undefined);
                        setRl3PlotData(undefined);
                        dispatch(setBarcodeCoveragePlotIsLoading(false));
                    }
                }
            }
            else {
                setBarcodeCoveragePlotDataPNG(undefined);
                setRl3PlotData(undefined);
            }
        }
        catch {
            setBarcodeCoveragePlotDataPNG(undefined);
            setRl3PlotData(undefined);
        }
    }, [state.barcode_coverage_plots, state.calculate_coverage_data, barcode_coverage_selected_barcode, state.runId]);

    const addDataQTable = (data) => {
        try {
            if (data) {
                let calcData = state.calculate_coverage_data;
                if (calcData) {
                    let qTableCopy = [...data];
                    let calculateCopy = [...calcData];
                    let mergeData = Utils.mergeData(calculateCopy, qTableCopy);
                    //let newArray = mergeData.slice(17);
                    setBarcodeTableDownloadData(mergeData);
                    //setBarcodeGCBiasDownloadData(newArray);
                }
            }

        } catch (error) {
            setBarcodeTableDownloadData(undefined);
        }
    };

    useEffect(() => {
        try {
            if (barcode_coverage_selected_barcode) {
                let barcode = barcode_coverage_selected_barcode;
                let sample = barcode_coverage_selected_barcode;
                let barcodeArr = barcode_coverage_selected_barcode.split('-');
                if (barcodeArr && barcodeArr.length > 2){
                    sample = barcodeArr[0];
                    barcode = barcodeArr.splice(1).join('-');
                } else if (barcodeArr && barcodeArr.length > 0){
                    barcode = barcode_coverage_selected_barcode.replace(`${barcode}-`, '');
                    sample = barcodeArr[0];
                }
                setSelectedBarcodeBySample({ barcode, sample });
            }
            else setSelectedBarcodeBySample({ barcode: 'TT', sample: 'TT' });
        }
        catch {
            setSelectedBarcodeBySample({ barcode: 'TT', sample: 'TT' });
        }
    }, [barcode_coverage_selected_barcode]);

    useEffect(() => {
        try {
            setBarcodeQualityPlotData(undefined);
            setBarcodeQualityPlotDataPNG(undefined);

            let exists = state.base_quality_plots.filter(f => f.runid === state.runId);
            if (exists.length > 0 && exists[0].data !== undefined) {
                if (exists[0].data.type === 'from_db') {
                    let bcAll = [];
                    //creating containers for the plot before plotting
                    exists[0].data.data.filter(f => f !== undefined && f !== null).forEach((plot, i) => {
                        bcAll.push(plot);
                    });
                    Utils.purgeD3Plots('BQ_JSON');
                    setBarcodeQualityPlotData(bcAll);
                }
                else if (exists[0].data.type === 'from_file') {
                    setBarcodeQualityPlotDataPNG(exists[0].data.data);
                }
            }
            else {
                setBarcodeQualityPlotData(undefined);
                setBarcodeQualityPlotDataPNG(undefined);
            }
        }
        catch (e) {
            setBarcodeQualityPlotData(undefined);
            setBarcodeQualityPlotDataPNG(undefined);
        }
    }, [state.base_quality_plots]);

    useEffect(() => {
        try {
            if (barcodeQualityPlotData !== undefined && barcodeQualityPlotData.length > 0) {
                barcodeQualityPlotData.forEach((plot, i) => {
                    const element = document.getElementById(`BQ_JSON${i + 1}`);
                    if (element) element.innerHTML = '';
                    let old = JSON.stringify(plot).replace(/\bNaN\b/g, "0").replace(/\Infinity\b/g, "0");
                    const data = JSON.parse(old);
                    if (isSmallScreen) {
                        data.width = 300
                        data.height = 500
                    }
                    if (isMedium1Screen) {
                        data.width = 400
                        data.height = 500
                    }
                    if (isMedium2Screen) {
                        data.width = 500
                        data.height = 500
                    }
                    if (isBigScreen) {
                        data.width = 700
                        data.height = 600
                    }
                    mpld3.draw_figure(`BQ_JSON${i + 1}`, data);
                });
            }
        }
        catch { }
    }, [barcodeQualityPlotData, isSmallScreen, isMedium1Screen, isMedium2Screen, isBigScreen]);

    useEffect(() => {
        try {
            if (state.base_quality_ber_per_flow_plot_data !== undefined) {
                setBarcodeQualityPlotBerPerFlowData(state.base_quality_ber_per_flow_plot_data);
            }
            else setBarcodeQualityPlotBerPerFlowData(undefined);
        }
        catch { }
    }, [state.base_quality_ber_per_flow_plot_data]);


    useEffect(() => {
        try {
            let exists = state.fwhm_plots_data.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId));
            if (exists !== undefined && exists[0].data !== undefined) {
                setFwhmPlotData(exists[0].data);
            }
            else setFwhmPlotData(undefined);
        }
        catch { }
    }, [state.fwhm_plots_data]);

    useEffect(() => {
        try {
            dispatch(setReadLengthPlotLoading(true));
            let exists = state.read_length_plots.filter(f => f.runid === state.runId);
            if (exists.length > 0 && exists[0].data !== undefined) {
                try {
                    if (exists[0].data.plot1 !== undefined) {
                        let data = exists[0].data.plot1[state.by_camera_value_global];
                        let readLengthMedian = Math.floor(parseInt(Utils.getArrayAverage(data['All reads'], data['Length'])));
                        let readLengthMedianCalc = Math.floor(parseInt(Utils.getArrayAverage(data['All reads'], data['Length'])));
                        if (readLengthMedian) setAverageReadLength(readLengthMedian);
                        if (readLengthMedianCalc) setAverageRLPlotCalc(readLengthMedian);
                        let rl1 = RenderReadLengthPlots.RenderReadLengthPlot(data, state.runId, state.by_camera_value_global, readLengthMedian ? Math.floor(readLengthMedian * 1.5) : null);
                        setRl1PlotData(rl1);
                        dispatch(setReadLengthPlotLoading(false));
                    }
                }
                catch {
                    setRl1PlotData(undefined);
                }

                try {
                    if (exists[0].data.plot2 !== undefined) {
                        let data2 = exists[0].data.plot2[state.by_camera_value_global];
                        let rl2 = RenderReadLengthPlots.RenderHomoIndelErrorPlot(data2['All hmers'], state.runId, state.by_camera_value_global);
                        setRl2PlotData(rl2);
                        dispatch(setReadLengthPlotLoading(false));
                    }
                }
                catch {
                    setRl2PlotData(undefined);
                }
            }
            else {
                setRl1PlotData(undefined);
                setRl2PlotData(undefined);
            }
        }
        catch {
            setRl1PlotData(undefined);
            setRl2PlotData(undefined);
        }
        finally {
            // dispatch(setReadLengthPlotLoading(false));
        }
    
    }, [state.read_length_plots]);

    useEffect(() => {
        try {
            dispatch(setReadLengthPlotLoading(true));
            let exists = state.read_length_plots.filter(f => f.runid === state.runId);
            if (exists.length > 0 && exists[0].data !== undefined && exists[0].data.plot1 !== undefined) {
                let data = exists[0].data.plot1[state.by_camera_value_global];
                let rl1 = RenderReadLengthPlots.RenderReadLengthPlot(data, state.runId, state.by_camera_value_global);
                setRl1PlotData(rl1);
                let readLengthMedian = parseInt(Utils.getArrayAverage(data['All reads'], data['Length']));
                if (readLengthMedian) setAverageReadLength(readLengthMedian);

                let data2 = exists[0].data.plot2[state.by_camera_value_global];
                let rl2 = RenderReadLengthPlots.RenderHomoIndelErrorPlot(data2['All hmers'], state.runId, state.by_camera_value_global);
                setRl2PlotData(rl2);
                dispatch(setReadLengthPlotLoading(false));
            }
            else {
                setRl1PlotData(undefined);
                setRl2PlotData(undefined);
                setAverageReadLength(undefined);
            }
        }
        catch {
            setRl1PlotData(undefined);
            setRl2PlotData(undefined);
            setAverageReadLength(undefined);
            dispatch(setReadLengthPlotLoading(false));
        }
        finally {
            //dispatch(setReadLengthPlotLoading(false));
        }
    }, [state.by_camera_value_global]);

    useEffect(() => {
        try {
            if (state.homo_indel_error !== undefined) {
                let data = state.homo_indel_error[state.by_camera_value_global];
                setHomoIndelError(parseFloat(data).toFixed(2));
            }
        }
        catch {
            setHomoIndelError(undefined);
        }
    }, [state.homo_indel_error, state.by_camera_value_global]);

    const get_mean_coverage = data => {
        let refLength = 0;
        let totalCoverage = 0;
        let meanCvg;

        for (let x = 0; x < data.length; x++) {
            totalCoverage += data[x] * x;
            refLength += data[x];
        }

        meanCvg = totalCoverage / refLength;

        return meanCvg.toFixed(2);
    }

    const get_pct_duplicates = (nr, pf) => {
        let sum = 100 * (nr / pf);
        return sum.toFixed(2);
    }

    const get_gc30 = (gc1020, gc2030) => {
        let gc_10_30 = [],
            diff,
            max,
            g1,
            g2;


        if (gc1020.length > 0 && gc2030.length > 0) {
            g1 = gc1020.length;
            g2 = gc2030.length;

            if (g1 > g2) {
                diff = g1 - g2;
                max = g1;
                for (let i = 0; i < diff; i++) {
                    gc2030.push(0);
                }
            } else if (g2 > g1) {
                diff = g2 - g1;
                max = g2;
                for (let i = 0; i < diff; i++) {
                    gc1020.push(0);
                }
            } else {
                max = g1;
            }


            for (let i = 0; i < max; i++) {
                gc_10_30.push(gc1020[i] + gc2030[i]);
            }

        }
        return gc_10_30;
    }

    const get_gc60 = baseCoverage => {
        const max1 = Math.max(baseCoverage["GC 60-70"].length, baseCoverage["GC 70-80"].length);
        const max2 = Math.max(baseCoverage["GC 80-90"].length, baseCoverage["GC 90-100"].length);
        const max = Math.max(max1, max2);

        const gc1 = baseCoverage["GC 60-70"];
        const gc2 = baseCoverage["GC 70-80"];
        const gc3 = baseCoverage["GC 80-90"];
        const gc4 = baseCoverage["GC 90-100"];


        let gc60_100 = [];

        for (let i = 0; i < max; i++) {
            let v1 = gc1[i] || 0;
            let v2 = gc2[i] || 0;
            let v3 = gc3[i] || 0;
            let v4 = gc4[i] || 0;

            gc60_100.push(v1 + v2 + v3 + v4);
        }
        return gc60_100;
    }

    const get_f = (fValue, array) => {

        // compute genomic target size

        let ref_length = 0,
            total_coverage = 0,
            mean_cvg,
            p,
            lowCov = array[0],
            fValue_cov,
            fValue_value,
            fValue_index = 0;



        for (let x = 0; x < array.length; x++) {
            total_coverage += array[x] * x;
            ref_length += array[x];
        }

        mean_cvg = total_coverage / ref_length;


        p = (1.0 - fValue) * ref_length;

        do {
            fValue_index = fValue_index + 1;
            lowCov += array[fValue_index];
        } while (lowCov < p);

        fValue_index -= (lowCov - p) / (array[fValue_index]);

        fValue_value = (mean_cvg / fValue_index).toFixed(2);

        if (mean_cvg < 30) {
            fValue_value = "(" + fValue_value.toString() + ")";
        }

        return fValue_value;

    }

    useEffect(() => {
        try {
            if (state.fmetrics_table_data !== undefined && state.fmetrics_table_data !== null) {
                let dataBybarcode = state.fmetrics_table_data.filter(f => Object.keys(f)[0] === barcode_coverage_selected_barcode);
                if (dataBybarcode !== undefined && dataBybarcode.length > 0) {
                    let data = Object.values(dataBybarcode[0])[0];
                    RenderFMetricsTable(data);
                    let plot1 = RenderCoveragePlots.PlotBarcodeCoverageCOVHGPlot(state.runId, data, barcode_coverage_selected_barcode);
                    setCovPlot1(plot1);
                    //var plot2 = RenderCoveragePlots.PlotBarcodeCoveragePlot(state.runId, data, barcode_coverage_selected_barcode);
                    //setCovPlot2(plot2);
                }
                else {
                    setCovPlot1(undefined);
                    setCovPlot2(undefined);
                }
            }
        }
        catch (e) {
            setCovPlot1(undefined);
            setCovPlot2(undefined);
            dispatch(setFMetricsTableRenderData(undefined));
        }
    }, [state.fmetrics_table_data, barcode_coverage_selected_barcode]);

    const RenderFMetricsTable = (data) => {
        try {
            let obj = data;
            let renderData = [['', 'Genome', 'Exome']];
            renderData.push(['Mean coverage', get_mean_coverage(obj.base_coverage.Genome) !== null ? get_mean_coverage(obj.base_coverage.Genome) : '', get_mean_coverage(obj.base_coverage.Exome) !== null ? get_mean_coverage(obj.base_coverage.Exome) : '']);
            renderData.push(['% duplicates', get_pct_duplicates(obj.nr_duplicate_reads, obj.pf_aligned_reads) !== null ? get_pct_duplicates(obj.nr_duplicate_reads, obj.pf_aligned_reads) : '', get_pct_duplicates(obj.nr_duplicate_reads, obj.pf_aligned_reads) !== null ? get_pct_duplicates(obj.nr_duplicate_reads, obj.pf_aligned_reads) : '']);
            renderData.push(['GC bias <30% >60%', `${(get_mean_coverage(get_gc30(obj.base_coverage["GC 10-20"], obj.base_coverage["GC 20-30"])) / get_mean_coverage(obj.base_coverage.Genome)).toFixed(2) !== null ? (get_mean_coverage(get_gc30(obj.base_coverage["GC 10-20"], obj.base_coverage["GC 20-30"])) / get_mean_coverage(obj.base_coverage.Genome)).toFixed(2) : ''} / ${(get_mean_coverage(get_gc60(obj.base_coverage)) / get_mean_coverage(obj.base_coverage.Genome)).toFixed(2) ? (get_mean_coverage(get_gc60(obj.base_coverage)) / get_mean_coverage(obj.base_coverage.Genome)).toFixed(2) : ''}`, '']);
            renderData.push(['F80/F90/F95', `${get_f(0.8, obj.base_coverage.Genome) !== null ? get_f(0.8, obj.base_coverage.Genome) : ''} / ${get_f(0.9, obj.base_coverage.Genome) !== null ? get_f(0.9, obj.base_coverage.Genome) : ''} / ${get_f(0.95, obj.base_coverage.Genome) !== null ? get_f(0.95, obj.base_coverage.Genome) : ''}`, `${get_f(0.8, obj.base_coverage.Exome) !== null ? get_f(0.8, obj.base_coverage.Exome) : ''} / ${get_f(0.9, obj.base_coverage.Exome) !== null ? get_f(0.9, obj.base_coverage.Exome) : ''} / ${get_f(0.95, obj.base_coverage.Exome) !== null ? get_f(0.95, obj.base_coverage.Exome) : ''}`]);
            dispatch(setFMetricsTableRenderData(renderData));
        }
        catch (e) {
            dispatch(setFMetricsTableRenderData(undefined));
        }
    }

    useEffect(() => {
        if (state.ber_table_data !== undefined) {
            RenderBERTable(state.ber_table_data);
        }
        else dispatch(setBERTableRenderData(undefined));
    }, [state.ber_table_data, state.by_camera_value_global]);

    useEffect(() => {
        if (state.ber_hmer_table_data !== undefined) {
            RenderBERHMERTable(state.ber_hmer_table_data);
        }
        else dispatch(setBERHMERTableRenderData(undefined));
    }, [state.ber_hmer_table_data, state.by_camera_value_global]);

    const RenderBERTable = data => {
        try {
            if (data.camera_1 !== undefined && data.camera_2 !== undefined) {
                let renderData = [];
                if (state.by_camera_value_global === 'unified') {
                    let renderData1 = data.camera_1.filter(m => m[0] === '' || m[0] === 'BER80_RL200' || m[0] === 'BER80_200' || m[0] === 'BER80' || m[0] === 'BER100' || m[0] === 'BER80 @ 200 bp' || m[0] === 'BER80 @ 200 flows');
                    let renderData2 = data.camera_2.filter(m => m[0] === '' || m[0] === 'BER80_RL200' || m[0] === 'BER80_200' || m[0] === 'BER80' || m[0] === 'BER100' || m[0] === 'BER80 @ 200 bp' || m[0] === 'BER80 @ 200 flows');
                    renderData1.map(m => {
                        switch (m[0]) {
                            case "BER80_RL200":
                                m[0] = "BER80 @ 200 bp";
                                break;
                            case "BER80_200":
                                m[0] = "BER80 @ 200 flows";
                                break;
                            default:
                                break;
                        }
                    });
                    renderData2.map(m => {
                        switch (m[0]) {
                            case "BER80_RL200":
                                m[0] = "BER80 @ 200 bp";
                                break;
                            case "BER80_200":
                                m[0] = "BER80 @ 200 flows";
                                break;
                            default:
                                break;
                        }
                    });
                    renderData1.forEach((item, i) => {
                        if (item[0] !== '') {
                            renderData.push(item.map((m, j) => j !== 0 && !isNaN(m) ? ((parseFloat(m) + parseFloat(renderData2[i][j])) / 2).toFixed(2) : m));
                        }
                        else {
                            renderData.push(item);
                        }
                    });
                }
                else if (state.by_camera_value_global === 'camera_1' || state.by_camera_value_global === 'camera_2') {
                    let renderData1 = data[`${state.by_camera_value_global}`].filter(m => m[0] === '' || m[0] === 'BER80_RL200' || m[0] === 'BER80_200' || m[0] === 'BER80' || m[0] === 'BER100' || m[0] === 'BER80 @ 200 bp' || m[0] === 'BER80 @ 200 flows');
                    renderData1.map(m => {
                        switch (m[0]) {
                            case "BER80_RL200":
                                m[0] = "BER80 @ 200 bp";
                                break;
                            case "BER80_200":
                                m[0] = "BER80 @ 200 flows";
                                break;
                            default:
                                break;
                        }
                    });
                    renderData = renderData1;
                }

                dispatch(setBERTableRenderData(renderData));
            }
        }
        catch {
            dispatch(setBERTableRenderData(undefined));
        }

    }

    const RenderBERHMERTable = data => {
        try {
            if (data.camera_1 !== undefined && data.camera_2 !== undefined) {
                let renderData = [];
                if (state.by_camera_value_global === 'unified') {
                    data.camera_1.map((m, i) => {
                        if (m[0] === 'hmer')
                            m[0] = 'hmer Class Errors';
                        if (i !== 0) {
                            m[0] = parseInt(m[0])
                            return m;
                        }
                        else return m;
                    });
                    data.camera_2.map((m, i) => {
                        if (m[0] === 'hmer')
                            m[0] = 'hmer Class Errors';
                        if (i !== 0) {
                            m[0] = parseInt(m[0])
                            return m;
                        }
                        else return m;
                    });
                    data.camera_1.forEach((item, i) => {
                        if (item[0] !== '') {
                            renderData.push(item.map((m, j) => j !== 0 && !isNaN(m) ? ((parseFloat(m) + parseFloat(data.camera_2[i][j])) / 2).toFixed(2) : m));
                        }
                        else {
                            renderData.push(item);
                        }
                    });
                }
                else if (state.by_camera_value_global === 'camera_1' || state.by_camera_value_global === 'camera_2') {
                    renderData = data[`${state.by_camera_value_global}`].map((m, i) => {
                        if (m[0] === 'hmer')
                            m[0] = 'hmer Class Errors';
                        if (i !== 0) {
                            m[0] = parseInt(m[0])
                            return m;
                        }
                        else return m;
                    });
                }

                dispatch(setBERHMERTableRenderData(renderData));
            }
        }
        catch {
            dispatch(setBERHMERTableRenderData(undefined));
        }

    }

    const renderPlotSubTab = user => {
        try {
            if (user !== undefined && user.roles !== undefined && user.roles.length > 0) {
                let roles = user.roles.filter(f => f.name === Utils.NEXUS_ROLES.ULTIMA || f.name === Utils.NEXUS_ROLES.ADMINISTRATORS);
                if (roles !== undefined && roles.length > 0) {
                    return <Nav.Link eventKey="link-2"><AnalyticsIcon style={{ marginBottom: 5, }} /> Plots</Nav.Link>
                }
                else return null;
            }
            else return null;
        }
        catch {
            return null;
        }
    }

    useEffect(() => {
        try {
            if (state.phasing_table_data && state.phasing_table_data.length > 0) {
                RenderPhasingTable(state.phasing_table_data);
            }
        }
        catch { }
    }, [state.phasing_table_data]);

    const RenderPhasingTable = (data) => {
        try {
            if (data.length > 0) {
                dispatch(setPhasingTableRenderData(data));
            }
        }
        catch { }
    }

    useEffect(() => {
        try {
            if (state.signals_plots && state.signals_plots.length > 0) {
                Utils.purgeD3Plots('S');
                setSignalsPlotData(state.signals_plots);
            }
        }
        catch { }
    }, [state.signals_plots]);

    useEffect(() => {
        try {
            if (signalPlotsData && signalPlotsData.length > 0) {
                signalPlotsData.forEach((plot, i) => {
                    const element = document.getElementById(`S${i + 1}`);
                    if (element) element.innerHTML = '';
                    let old = JSON.stringify(plot).replace(/\bNaN\b/g, "0").replace(/\Infinity\b/g, "0");
                    const data = JSON.parse(old);
                    if (isSmallScreen) {
                        data.width = 300
                        data.height = 500
                    }
                    if (isMedium1Screen) {
                        data.width = 400
                        data.height = 500
                    }
                    if (isMedium2Screen) {
                        data.width = 500
                        data.height = 500
                    }
                    if (isBigScreen) {
                        data.width = 700
                        data.height = 600
                    }
                    mpld3.draw_figure(`S${i + 1}`, data);
                });
            }
        }
        catch (e) {
            //console.log(e);
        }
    }, [signalPlotsData, isSmallScreen, isMedium1Screen, isMedium2Screen, isBigScreen]);

    useEffect(() => {
        try {
            if (state.signals_plots_png && state.signals_plots_png.length > 0) {
                setSignalsPlotPNGData(state.signals_plots_png);
            }
        }
        catch { }
    }, [state.signals_plots_png]);

    useEffect(() => {
        try {
            if (state.uniformity_plots && state.uniformity_plots.U && state.uniformity_plots.U.length > 0) {
                Utils.purgeD3Plots('U');
                setUniformityPlotData(state.uniformity_plots.U);
            }
            if (state.uniformity_plots && state.uniformity_plots.U_X && state.uniformity_plots.U_X.length > 0) {
                Utils.purgeD3Plots('U_X');
                setUniformityPlotXData(state.uniformity_plots.U_X);
            }
        }
        catch (e) {
            //console.log(e);
        }
    }, [state.uniformity_plots]);

    useEffect(() => {
        try {
            if (uniformityPlotsXData && uniformityPlotsXData.length > 0) {
                uniformityPlotsXData.forEach((plot, i) => {
                    const element = document.getElementById(`U_X${i + 1}`);
                    if (element) element.innerHTML = '';
                    let old = JSON.stringify(plot).replace(/\bNaN\b/g, "0").replace(/\Infinity\b/g, "0");
                    const data = JSON.parse(old);
                    if (isSmallScreen) {
                        data.width = 300
                        data.height = 500
                    }
                    if (isMedium1Screen) {
                        data.width = 400
                        data.height = 500
                    }
                    if (isMedium2Screen) {
                        data.width = 500
                        data.height = 500
                    }
                    if (isBigScreen) {
                        data.width = 700
                        data.height = 600
                    }
                    mpld3.draw_figure(`U_X${i + 1}`, data);
                });
            }
        }
        catch (e) {
            //console.log(e);
        }
    }, [uniformityPlotsXData, isSmallScreen, isMedium1Screen, isMedium2Screen, isBigScreen]);

    useEffect(() => {
        try {
            if (uniformityPlotsData && uniformityPlotsData.length > 0) {
                uniformityPlotsData.forEach((plot, i) => {
                    const element = document.getElementById(`U${i + 1}`);
                    if (element) element.innerHTML = '';
                    let old = JSON.stringify(plot).replace(/\bNaN\b/g, "0").replace(/\Infinity\b/g, "0");
                    const data = JSON.parse(old);
                    if (isSmallScreen) {
                        data.width = 300
                        data.height = 500
                    }
                    if (isMedium1Screen) {
                        data.width = 400
                        data.height = 500
                    }
                    if (isMedium2Screen) {
                        data.width = 500
                        data.height = 500
                    }
                    if (isBigScreen) {
                        data.width = 700
                        data.height = 600
                    }
                    mpld3.draw_figure(`U${i + 1}`, data);
                });
            }
        }
        catch (e) {
            //console.log(e);
        }
    }, [uniformityPlotsData, isSmallScreen, isMedium1Screen, isMedium2Screen, isBigScreen]);

    useEffect(() => {
        try {
            if (state.uniformity_signal_plots && state.uniformity_signal_plots.length > 0) {
                setUniformitySignalPlotData(state.uniformity_signal_plots);
            }
        }
        catch (e) {
            //console.log(e);
        }
    }, [state.uniformity_signal_plots]);

    useEffect(() => {
        try {
            if (state.phasing_plots && state.phasing_plots.length > 0) {
                Utils.purgeD3Plots('P');
                setPhasingPlotData(state.phasing_plots);
            }
        }
        catch (e) {
            //console.log(e);
        }
    }, [state.phasing_plots]);

    useEffect(() => {
        try {
            if (phasingPlotsData && phasingPlotsData.length > 0) {
                phasingPlotsData.forEach((plot, i) => {
                    const element = document.getElementById(`P${i + 1}`);
                    if (element) element.innerHTML = '';
                    let old = JSON.stringify(plot).replace(/\bNaN\b/g, "0").replace(/\Infinity\b/g, "0");
                    const data = JSON.parse(old);
                    if (isSmallScreen) {
                        data.width = 300
                        data.height = 500
                    }
                    if (isMedium1Screen) {
                        data.width = 400
                        data.height = 500
                    }
                    if (isMedium2Screen) {
                        data.width = 500
                        data.height = 500
                    }
                    if (isBigScreen) {
                        data.width = 700
                        data.height = 600
                    }
                    mpld3.draw_figure(`P${i + 1}`, data);
                });
            }
        }
        catch (e) {
            //console.log(e);
        }
    }, [phasingPlotsData, isSmallScreen, isMedium1Screen, isMedium2Screen, isBigScreen]);

    useEffect(() => {
        try {
            if (state.signals_linearity_plots && state.signals_linearity_plots.length > 0) {
                Utils.purgeD3Plots('SL');
                setSignalLinearityPlotsData(state.signals_linearity_plots);
            }
        }
        catch (e) {
            //console.log(e);
        }
    }, [state.signals_linearity_plots]);

    useEffect(() => {
        try {
            if (signalLinearityPlotsData && signalLinearityPlotsData.length > 0) {
                signalLinearityPlotsData.forEach((plot, i) => {
                    const element = document.getElementById(`SL${i + 1}`);
                    if (element) element.innerHTML = '';
                    let old = JSON.stringify(plot).replace(/\bNaN\b/g, "0").replace(/\Infinity\b/g, "0");
                    const data = JSON.parse(old);
                    if (isSmallScreen) {
                        data.width = 300
                        data.height = 500
                    }
                    if (isMedium1Screen) {
                        data.width = 400
                        data.height = 500
                    }
                    if (isMedium2Screen) {
                        data.width = 500
                        data.height = 500
                    }
                    if (isBigScreen) {
                        data.width = 700
                        data.height = 600
                    }
                    mpld3.draw_figure(`SL${i + 1}`, data);
                });
            }
        }
        catch (e) {
            //console.log(e);
        }
    }, [signalLinearityPlotsData, isSmallScreen, isMedium1Screen, isMedium2Screen, isBigScreen]);

    // const handleQtableHeaderClick = (barcode, index) => {
    //     setSelectedBarcode(prevBarcode => {
    //         if (prevBarcode !== barcode) {
    //             setSelectedIndex(index);
    //             dispatch(getFMetricsTableData(state.runId));
    //             return barcode;
    //         }
    //         return prevBarcode;
    //     });
    //     dispatch(setBarcodeCoverageBarcode(barcode));
    //};

    const handleQtableHeaderClick = (barcode, index) => {
        setSelectedIndex(index);
        const isRunBarcoded = state.isRunBarcoded.filter(f => f.runid === state.runId).length > 0 ? state.isRunBarcoded.filter(f => f.runid === state.runId)[0].data : undefined;
        dispatch(getFMetricsTableData(state.runId, isRunBarcoded, state.jwt));
        dispatch(setBarcodeCoverageBarcode(barcode)); // Update barcode in Redux state
    };

    useEffect(() => {
        if (state.active_barcode_table_button !== undefined) setSelectedQTableDataType(state.active_barcode_table_button);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.active_barcode_table_button]);

    useEffect(() => {
        if(barcode_coverage_selected_barcode !== undefined) {
            dispatch(geBarcodeCoveragePlots(Utils.getRunIdWithoutCamera(state.runId), 'BCALL', 'BCALL_JSON_BY_BARCODE', barcode_coverage_selected_barcode, state.jwt));
        }
    }, [barcode_coverage_selected_barcode]);

    const handleQTableDataType = (e) => {
        try {
            setSelectedQTableDataType(e.target.value);
            dispatch(setBarcodeDetailsButton(e.target.value));
        } catch { }
    };

    useEffect(() => {
        try {
            if (state.q_table_data && Object.keys(state.q_table_data).length !== 0) {
                const dataArray = structuredClone(state.q_table_data);
                if (dataArray && dataArray.data !== undefined && selectedQTableDataType !== undefined) {
                    if (dataArray.type === 'from_file') {
                        RenderQTable(dataArray.data);
                    }
                    else {
                        if(dataArray.ppmSeqType){
                            let barcodeData = structuredClone(dataArray.data[selectedQTableDataType])
                            let barcodes = barcodeData.map(data => data[0]).filter(barcode => barcode !== '');
                            let combinedBarcodes = barcodes.flatMap(([_, barcode]) => barcode);
                            dispatch(getPpmSeqReportList(state.runId, combinedBarcodes, state.jwt));
                        } 

                        let exists = dataArray.data[selectedQTableDataType];
                        if (Object.keys(dataArray.data).length > 0 && exists?.length > 0) RenderQTableFromDb(exists, dataArray.ppmSeqType !== undefined ? dataArray.ppmSeqType : false);
                        else dispatch(setQTableRenderData([]));
                    }
                }
            }
        }
        catch (e) {
            //dispatch(setQTableRenderData([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.q_table_data, selectedQTableDataType]);

    
    function updateArray(data) {
        for (let i = 0; i < data[0].length; i++) {
            if (data[0][i] === '') {
                let element = data[1][i];
                if (element.includes('-')) {
                    let splitElement = element.split('-');
                    let dash = Utils.dashCounter(element);
                    if (dash > 1) {
                        data[0][i] = splitElement[0];
                        data[1][i] = splitElement.slice(1).join('-');
                    } else {
                        data[0][i] = splitElement[0];
                        data[1][i] = splitElement[1];
                    }
                } else {
                    data[0][i] = element;
                    data[1][i] = element;
                }
            } 
            if (data[1][i] === '') data[1][i] = "Barcode";
        }
        return data;
    }

    useEffect(() => {
        try {
            if (state.q_table_render_data && state.q_table_render_data.data.length > 0 && barcode_coverage_selected_barcode) {
                let copy = [...state.q_table_render_data.data];
                let lastElement = copy.pop();
                copy.unshift(lastElement);
                let update = Utils.deepClone(copy);
                let newData = updateArray(update);
                setQTableBarcodeMetricData(copy);
                addDataQTable(newData);
                let index = copy[1].indexOf(barcode_coverage_selected_barcode);
                if (index && index !== -1) {
                    let result = copy.map(m => {
                        return [m[0], m[index]]
                    });
                    setBarcodeDetailsTableData(result);
                    let header1 = result.filter(f => f[0] === 'Mean_Read_Length');
                    let header2 = result.filter(f => f[0] === 'Mean_Aligned_Read_Length');
                    let newResult = { raw: undefined, aligned: undefined };
                    let baseQualityResult = { raw: undefined, aligned: undefined };
                    if (header1 && header1.length > 0) baseQualityResult.raw = Math.floor(parseInt(header1[0][1]));
                    if (header2 && header2.length > 0) baseQualityResult.aligned = Math.floor(parseInt(header2[0][1]));
                    if (header1 && header1.length > 0) newResult.raw = Math.floor(parseInt(header1[0][1]));
                    if (header2 && header2.length > 0) newResult.aligned = Math.floor(parseInt(header2[0][1]));
                    setBarcodeDetailsReadLengthHeader(newResult);
                    setbasePlotCalc(baseQualityResult)
                }
                else setBarcodeDetailsTableData(undefined);
            } else setQTableBarcodeMetricData([])
        }
        catch (e) {
            setBarcodeDetailsReadLengthHeader(undefined);
            setBarcodeDetailsTableData(undefined);
            //dispatch(setQTableRenderData([]));
        }
    }, [state.q_table_render_data, state.calculate_coverage_data, barcode_coverage_selected_barcode, selectedQTableDataType]);

    const renderBaseQualityPlot = (data, barcode) => {
        try {
            if (data) {
                let tt = data.filter(f => f.barcode === barcode);
                if (tt) {
                    let newData = tt[0].data.data[0].data.bqx;
                    if (newData && newData.length > 0) {
                        try {
                            let q30 = newData.map(m => m.slice(30, 60).reduce((a, b) => a + b)).reduce((a, b) => a + b);
                            let q60 = newData.map(m => m.slice(0, 60).reduce((a, b) => a + b)).reduce((a, b) => a + b);
                            let res = q30 / q60;
                            setBsqBarcodeDetailValue(parseInt(res * 100));
                        }
                        catch {
                            setBsqBarcodeDetailValue(undefined);
                        }
                        let rl3 = RenderReadLengthPlots.RenderReadLengthBaseQualityPlot(newData, state.runId, state.by_camera_value_global, barcode, basePlotCalc !== undefined ? basePlotCalc.raw ? Math.floor(basePlotCalc.raw * 1.5) : basePlotCalc.aligned ? Math.floor(basePlotCalc.aligned * 1.5) : null : null);
                        setBsqBarcodeDetailPlot(rl3);
                        dispatch(setBarcodeCoveragePlotIsLoading(false));
                    }
                    else {
                        setBsqBarcodeDetailValue(undefined);
                        setBsqBarcodeDetailPlot(undefined);
                    }
                } else {
                    setBsqBarcodeDetailValue(undefined);
                    setBsqBarcodeDetailPlot(undefined);
                }
            } else {
                setBsqBarcodeDetailValue(undefined);
                setBsqBarcodeDetailPlot(undefined);
            }

        } catch {
            setBsqBarcodeDetailPlot(undefined);
            setBsqBarcodeDetailValue(undefined);
        }
    };

    useEffect(() => {
        try {
            if (state.fmetrics_table_render_data && state.fmetrics_table_render_data.length > 0) {
                let data = state.fmetrics_table_render_data.filter(f => f[0] === 'GC bias <30% >60%');
                if (data && data.length > 0 && data[0][1]) {
                    let val = data[0][1].replace('/', ':');
                    setGcBeashHeader(val);
                }
                else setGcBeashHeader(undefined);
            }
        }
        catch (e) {
            setGcBeashHeader(undefined);
        }
    }, [state.fmetrics_table_render_data]);

    const RenderQTable = (data) => {
        try {
            if (data.length > 0) {
                let result = [];

                data.forEach(r => {
                    r.forEach(sr => {
                        if (sr[0] !== undefined && sr[0].toLowerCase() === 'pf_barcode_reads') {
                            const pfBarcodeIndex = r.findIndex(item => item[0] === 'PF_Barcode_reads');
                        
                            if (pfBarcodeIndex !== -1) {
                                for (let i = 1; i < sr.length; i++) {
                                    if (!isNaN(parseFloat(sr[i]))) {
                                        sr[i] = parseFloat(sr[i]).toLocaleString(undefined, { maximumFractionDigits: 2 });
                                    }
                                }
                            }
                        }
                        if (sr[0] !== undefined && sr[0].toLowerCase() === 'barcode_reads') {
                            const barcodeIndex = r.findIndex(item => item[0] === 'Barcode_reads');

                            if (barcodeIndex !== -1) {
                                for (let i = 1; i < sr.length; i++) {
                                    sr[i] = Number(sr[i]).toLocaleString();
                                }
                            }
                        }
                    });
                });
                data.forEach((row, i) => {
                    if (i === 0) result.push(row);
                    else result[0].map((m, j) => m.push(row[j][1]));

                });
                dispatch(setQTableRenderData(result[0]));
            }
        }
        catch {
            // dispatch(setQTableRenderData([]));
        }
    }

    const RenderQTableFromDb = (data, type) => {
        try {
            let copy = [...data];
            if (copy.length > 0) {
                let result = [];
                data.forEach(r => {
                    r.forEach(sr => {
                        if (sr[0] !== undefined && sr[0].toLowerCase() === 'pct_pf_aligned') {
                            sr[0] = 'PCT_PF_Bases_aligned';
                        }
                    });
                });
                data.forEach(r => {
                    r.forEach(sr => {
                        if (sr[0] !== undefined && sr[0].toLowerCase() === 'pf_barcode_reads') {
                            const pfBarcodeIndex = r.findIndex(item => item[0] === 'PF_Barcode_reads');
                        
                            if (pfBarcodeIndex !== -1) {
                                for (let i = 1; i < sr.length; i++) {
                                    if (!isNaN(parseFloat(sr[i]))) {
                                        sr[i] = parseFloat(sr[i]).toLocaleString(undefined, { maximumFractionDigits: 2 });
                                    }
                                }
                            }
                        }
                        if (sr[0] !== undefined && sr[0].toLowerCase() === 'barcode_reads') {
                            const barcodeIndex = r.findIndex(item => item[0] === 'Barcode_reads');

                            if (barcodeIndex !== -1) {
                                for (let i = 1; i < sr.length; i++) {
                                    sr[i] = Number(sr[i]).toLocaleString();
                                }
                            }
                        }
                    });
                });
                data.forEach((row, i) => {
                    if (i === 0) result.push(row);
                    else {
                        result[0].map((m, j) => {
                            if (row[j] !== undefined) m.push(row[j][1]);
                            else m.push('');
                        });
                    }
                })
                const updatedData = [...result[0]];
                dispatch(setQTableRenderData(updatedData, type));
            }
        } catch {
            //dispatch(setQTableRenderData([]));
        }
    }

    useEffect(() => {
        try {
            if (state.fmetrics_table_render_data && state.fmetrics_table_render_data.length > 0 && barcode_coverage_selected_barcode) {
                let data = state.fmetrics_table_render_data.filter(f => f[0] === 'Mean coverage');
                if (data && data.length > 0) setMeanCoverageValue(data[0][1]);
                else setMeanCoverageValue(undefined);
            }
        }
        catch (e) {
            setMeanCoverageValue(undefined);
        }
    }, [state.fmetrics_table_render_data]);

    const handleOnHover = (index, isLeave = false) => {
        setHoverIndex(isLeave ? -1 : index);
    };

    // Function to synchronize column widths between regular table and sticky header
    function synchronizeColumnWidths() {
        try {
            const regularTableContainer = document.querySelector('.regular-table').closest('div');
            const stickyTableContainer = document.querySelector('#stickyBarcodeHeader').closest('div');
            const regularTable = document.querySelector('.regular-table');
            const stickyTable = document.querySelector('#stickyBarcodeHeader');
            
            if (!regularTable || !stickyTable || !regularTableContainer || !stickyTableContainer) {
                throw new Error('Required elements not found');
            }
    
            const regularTableHeaders = regularTable.querySelectorAll('th');
            const stickyTableHeaders = stickyTable.querySelectorAll('th');
    
            if (regularTableHeaders.length !== stickyTableHeaders.length) {
                throw new Error('Number of headers in regular table and sticky header do not match');
            }
    
            // Set the sticky table's width to match the regular table
            stickyTable.style.width = `${regularTable.offsetWidth}px`;
    
            regularTableHeaders.forEach((header, index) => {
                const width = header.offsetWidth;
                const stickyHeader = stickyTableHeaders[index];
    
                if (stickyHeader) {
                    stickyHeader.style.width = `${width}px`;
                    stickyHeader.style.minWidth = `${width}px`;
                    stickyHeader.style.maxWidth = `${width}px`;
                    if (index === 0) {
                        // Set left position for the first column in the sticky header
                        stickyHeader.style.left = '0';
                    }
                } else {
                    throw new Error(`Sticky header at index ${index} is undefined`);
                }
            });

            // Set left position for the first column in the regular table
            const firstColumnCells = regularTable.querySelectorAll('th:first-child, td:first-child');
            firstColumnCells.forEach(cell => {
                cell.style.left = '0';
            });
    
            // Adjust the sticky table's container width and style
            stickyTableContainer.style.width = `${regularTableContainer.offsetWidth}px`;
            stickyTableContainer.style.overflowX = 'hidden';
    
            // Synchronize scroll positions
            regularTableContainer.addEventListener('scroll', () => {
                stickyTableContainer.scrollLeft = regularTableContainer.scrollLeft;
            });
        } catch (error) {
            //console.error('Error synchronizing column widths:', error);
        }
    }

    // Call the function initially
    synchronizeColumnWidths();

    // Call the function whenever the window is resized
    window.addEventListener('resize', synchronizeColumnWidths);

    useEffect(() => {
        if(state.ppmSeqReportList?.length > 0 && qTableBarcodeMetricData) {
            let barcodeOrder = qTableBarcodeMetricData[1]?.slice(1);
            let ppmSeqDataList = structuredClone(state.ppmSeqReportList);
            let barcodeToHtmlMap = {};
            ppmSeqDataList?.forEach(item => {
                if(!item.error && item.html) barcodeToHtmlMap[item.barcode] = item.html;
            });

            let orderedPpmSeqDataList = barcodeOrder?.map(barcode => {
                return {
                    barcode: barcode,
                    html: barcodeToHtmlMap[barcode] || null
                };
            }).filter(item => item.html);
            setPpmSeqHTMLList(orderedPpmSeqDataList);
        } else {
            setPpmSeqHTMLList(undefined);
        }
    }, [state.ppmSeqReportList, qTableBarcodeMetricData])

    useEffect(() => {
        if (qTableBarcodeMetricData && qTableBarcodeMetricData.length > 1) {
            const ttIndex = qTableBarcodeMetricData[1].findIndex(barcode => barcode === barcode_coverage_selected_barcode);
            if (ttIndex !== -1 && ttIndex !== selectedIndex) {
                setSelectedIndex(ttIndex);
            } else if (ttIndex === -1 && selectedIndex !== -1) {
                setSelectedIndex(-1);
                setSelectedBarcode('TT');
                dispatch(setBarcodeCoverageBarcode('TT'));
            }
        }
    }, [qTableBarcodeMetricData, barcode_coverage_selected_barcode, selectedIndex]);

    const getHeaderStyle = (j, m) => {
        const isSelected = m === barcode_coverage_selected_barcode || j === selectedIndex;
        const isHovered = j === hoverIndex;
        const baseStyle = {
            backgroundColor: isSelected || isHovered ? '#0075a9' : 'white',
            textAlign: 'center',
            cursor: 'pointer',
        };
        
        if (j === 0) {
            return {
                ...baseStyle,
                left: 0,
                backgroundColor: m.toUpperCase() === 'PPMSEQ' ? '#A9A9A9' : 'white',
                zIndex: j === 0 ? 3 : 2, // Higher z-index for header
                boxShadow: '2px 0 5px -2px rgba(0,0,0,0.1)',
            };
        }
        
        return baseStyle;
    };

    const getFrozenHeaderHeight = () => {
        const frozenHeader = document?.querySelector('#stickeHeaderContainer');
        return frozenHeader ? frozenHeader?.offsetHeight : 0;
    };

    function updateFirstColumnBackgrounds() {
        const firstColumnCells = document.querySelectorAll('.regular-table td:first-child');
        firstColumnCells.forEach((cell, index) => {
            if (cell.textContent.toUpperCase() !== 'PPMSEQ') {
                if (index % 2 === 1) {
                    cell.style.backgroundColor = 'white';
                } else {
                    cell.style.backgroundColor = '#f2f2f2';
                }
            }
        });
    }
    
    // Call this function after the table is rendered or updated
    updateFirstColumnBackgrounds();

    const renderRunSummarySubTab1 = () => {
        return <>
                    <Card sx={{ minWidth: 275, zIndex: 1 }}>
                        <CardHeader
                            action={
                                <IconButton
                                    onClick={() => handleExpandClick(8)}
                                    aria-expanded={expandedIds.includes(8)}
                                    aria-label="Expand/Collapse"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            }
                            //ex. Read Length
                            title="Quality of Control Sample"
                            subheader={state.is_unified_row_selected ? state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId : state.runId}
                        />
                        <Collapse in={expandedIds.includes(8)} timeout="auto">
                            <CardContent>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col lg={4} style={{ justifyContent: 'center', display: 'flex' }}>
                                                {averageReadLength !== undefined && <SummaryHeader label='Average Read Length' value={`${averageReadLength} bp`} width_perc={100} />}
                                            </Col>
                                            <Col lg={4} style={{ justifyContent: 'center', display: 'flex' }}>
                                                {homoIndelError !== undefined && rl2PlotData !== undefined && <SummaryHeader label='Homopolymer Indel Error' value={`${homoIndelError}%`} width_perc={100} />}
                                            </Col>
                                            <Col lg={4} style={{ justifyContent: 'center', display: 'flex' }}>
                                                {totalBasesValue !== undefined && <SummaryHeader label='Total Bases > Q30' value={`${totalBasesValue}%`} width_perc={100} />}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4}>
                                                {rl1PlotData !== undefined && <RenderPlot id="readLengthPlot" data={rl1PlotData.data && rl1PlotData.data} layout={rl1PlotData.layout && rl1PlotData.layout} config={rl1PlotData.config && rl1PlotData.config} isLoading={state.is_read_length_plots_loading}
                                                    type={'rounded'} width={'100%'} height={400} />}
                                            </Col>

                                            <Col lg={4}>
                                                {rl2PlotData !== undefined && <RenderPlot id="readLengthPlot2" data={rl2PlotData.data && rl2PlotData.data} layout={rl2PlotData.layout && rl2PlotData.layout} config={rl2PlotData.config && rl2PlotData.config} isLoading={state.is_read_length_plots_loading}
                                                    type={'rounded'} width={'100%'} height={400} />}
                                            </Col>

                                            <Col lg={4}>
                                                {rl3PlotData !== undefined && <RenderPlot id="readLengthPlot3" data={rl3PlotData.data && rl3PlotData.data} layout={rl3PlotData.layout && rl3PlotData.layout} config={rl3PlotData.config && rl3PlotData.config} isLoading={state.is_barcode_coverage_plot_loading}
                                                    type={'rounded'} width={'100%'} height={400} />}
                                            </Col>

                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </CardContent>
                        </Collapse>
                    </Card>
                    <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                        <CardHeader
                            action={
                                <IconButton
                                    onClick={() => handleExpandClick(2)}
                                    aria-expanded={expandedIds.includes(2)}
                                    aria-label="Expand/Collapse"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            }
                            title="Read Filtering and Barcode Assignment"
                            subheader={state.is_unified_row_selected ? state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId : state.runId}
                        />
                        <Collapse in={expandedIds.includes(2)} timeout="auto">
                            <CardContent>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <ReadFiltering />
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </CardContent>
                        </Collapse>
                    </Card>
                {!polyH5Plot1 && state.is_unified_row_selected ?
                    <div></div>
                    :
                        <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                            <CardHeader
                                action={
                                    <IconButton
                                        onClick={() => handleExpandClick(14)}
                                        aria-expanded={expandedIds.includes(14)}
                                        aria-label="Expand/Collapse"
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                }
                                title="Polyclonality"
                                subheader={state.is_unified_row_selected ? state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId : state.runId}
                            />
                            <Collapse in={expandedIds.includes(14)} timeout="auto">
                                <CardContent>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>
                                            <Row>
                                                {polyH5Plot1 !== undefined &&
                                                    <Col lg={4} style={{ marginTop: 5, }}>
                                                        <RenderPlot id="polyPlot1" data={(polyH5Plot1 !== undefined && polyH5Plot1.data) && polyH5Plot1.data} layout={(polyH5Plot1 !== undefined && polyH5Plot1.layout) && polyH5Plot1.layout} config={(polyH5Plot1 !== undefined && polyH5Plot1.config) && polyH5Plot1.config} isLoading={state.is_poly_plot_loading}
                                                            type={'rounded'} width={'100%'} height={400} />
                                                    </Col>
                                                }
                                                {polyH5Plot2 !== undefined &&
                                                    <Col lg={4} style={{ marginTop: 5, }}>
                                                        <RenderPlot id="polyPlot2" data={(polyH5Plot2 !== undefined && polyH5Plot2.data) && polyH5Plot2.data} layout={(polyH5Plot2 !== undefined && polyH5Plot2.layout) && polyH5Plot2.layout} config={(polyH5Plot2 !== undefined && polyH5Plot2.config) && polyH5Plot2.config} isLoading={state.is_poly_plot_loading}
                                                            type={'rounded'} width={'100%'} height={400} />
                                                    </Col>
                                                }
                                                {polyH5Plot3 !== undefined &&
                                                    <Col lg={4} style={{ marginTop: 5, }}>
                                                        <RenderPlot id="polyPlot3" data={(polyH5Plot3 !== undefined && polyH5Plot3.data) && polyH5Plot3.data} layout={(polyH5Plot3 !== undefined && polyH5Plot3.layout) && polyH5Plot3.layout} config={(polyH5Plot3 !== undefined && polyH5Plot3.config) && polyH5Plot3.config} isLoading={state.is_poly_plot_loading}
                                                            type={'rounded'} width={'100%'} height={400} />
                                                    </Col>
                                                }
                                            </Row>
                                            <Row>
                                                {(polyPlot1 !== undefined && polyPlot1.length !== 0) &&
                                                    <Col lg={6}>
                                                        {polyPlot1 && <Image src={polyPlot1} width={isSmallScreen ? 300 : isMedium1Screen ? 400 : isMedium2Screen ? 500 : null} alt='' />}
                                                    </Col>
                                                }
                                                {(polyPlot2 !== undefined && polyPlot2.length !== 0) &&
                                                    <Col lg={6}>
                                                        {polyPlot2 && <Image src={polyPlot2} width={isSmallScreen ? 300 : isMedium1Screen ? 400 : isMedium2Screen ? 500 : null} alt='' />}
                                                    </Col>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </CardContent>
                            </Collapse>
                        </Card>
                }
                <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                    <CardHeader
                        action={
                            <IconButton
                                onClick={() => handleExpandClick(6)}
                                aria-expanded={expandedIds.includes(6)}
                                aria-label="Expand/Collapse"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        }
                        title="Barcode Metrics"
                        subheader={Utils.getRunIdWithoutCamera(state.runId)}
                    />
                    <Collapse in={expandedIds.includes(6)} timeout="auto">
                        <CardContent>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Row>
                                        <Col lg={12}>
                                        <span style={{ float: 'left' }}>* Click on the barcode name for more details</span>
                                                    <div style={{ float: 'left', marginLeft: 10 }}>
                                                        {qTableBarcodeMetricData !== undefined && (
                                                            <CSVDownloadLink
                                                                data={
                                                                    barcodeTableDownloadData
                                                                    ? barcodeTableDownloadData
                                                                    : [[]]
                                                                }
                                                                filename={`${Utils.getRunIdWithoutCamera(
                                                                    state.runId
                                                                )}_barcode-coverage-table-data.csv`}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="mt-3 d-flex justify-content-end align-items-center">
                                                        <span className='barcode-table-total-reads-message'>
                                                            Minimal frequency of displayed barcodes
                                                        </span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-1">Click to display barcodes containing more than 0.1% of total reads</Tooltip>}
                                                        >
                                                            <Button
                                                                className='mx-2'
                                                                variant="contained"
                                                                color="primary"
                                                                id={`${state.active_barcode_table_button === '1k' ? 'barcode-table-total-reads-button:active' : 'barcode-table-total-reads-button'}`}
                                                                value='1k'
                                                                onClick={handleQTableDataType}
                                                            >
                                                                1:1 K
                                                            </Button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-2">Click to display barcodes containing more than 0.01% of total reads</Tooltip>}
                                                        >
                                                            <Button
                                                                className='mx-2'
                                                                variant="contained"
                                                                color="primary"
                                                                id={`${state.active_barcode_table_button === '10k' ? 'barcode-table-total-reads-button:active' : 'barcode-table-total-reads-button'}`}
                                                                value='10k'
                                                                onClick={handleQTableDataType}
                                                            >
                                                                1:10 K
                                                            </Button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-3">Click to display barcodes containing more than 0.001% of total reads</Tooltip>}
                                                        >
                                                            <Button
                                                                className='mx-2'
                                                                variant="contained"
                                                                color="primary"
                                                                id={`${state.active_barcode_table_button === '100k' ? 'barcode-table-total-reads-button:active' : 'barcode-table-total-reads-button'}`}
                                                                value='100k'
                                                                onClick={handleQTableDataType}
                                                            >
                                                                1:100 K
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </div>
                                                    {isSticky && qTableBarcodeMetricData && qTableBarcodeMetricData.length > 0 &&
                                                        <>
                                                            <div style={{ overflow: 'hidden', position: 'fixed', top: state.show_frozen_header ? `${getFrozenHeaderHeight()}px` : 0, zIndex: 1000}}>
                                                            <Table bordered hover id="stickyBarcodeHeader">
                                                                    <thead>
                                                                        <tr key={'BHM12'}>
                                                                            {qTableBarcodeMetricData[0].map((m, j) => <th 
                                                                                style={getHeaderStyle(j, m)}
                                                                                key={`BMH1${( 2 + j) * 2}`}>
                                                                                    <>
                                                                                        {(() => {
                                                                                            if (j === 0){
                                                                                                return 'Sample'
                                                                                            } else {
                                                                                                return m === '' ? qTableBarcodeMetricData[1][j].split('-')[0] : m
                                                                                            }
                                                                                        }) ()}
                                                                                    </> 
                                                                            </th>)}
                                                                        </tr>
                                                                        <tr key={'BHM13'}>
                                                                            {qTableBarcodeMetricData[1].map((m, j) => <th className="qtable_header" 
                                                                            onMouseOver={() => handleOnHover(m)} 
                                                                            onMouseOut={() => handleOnHover(undefined)} 
                                                                            style={getHeaderStyle(j, m)}
                                                                                onClick={j === 0 ? undefined : (e) => handleQtableHeaderClick(m, j)}
                                                                                key={`BMH1${( 2 + j) * 3}`}>
                                                                                    <>
                                                                                        {(() => {
                                                                                            if (j === 0){
                                                                                                return 'Barcode'
                                                                                            } else {
                                                                                                let dash = Utils.dashCounter(m);
                                                                                                if(dash > 1){
                                                                                                    if(qTableBarcodeMetricData[0][j] === '') return m.split('-').slice(1).join('-')
                                                                                                    else return m;
                                                                                                } else {
                                                                                                    if(qTableBarcodeMetricData[0][j] === '') return m.includes('-') ? m.split('-')[1] : m;
                                                                                                    else return m;
                                                                                                }
                                                                                            }
                                                                                        }) ()}
                                                                                    </> 
                                                                            </th>)}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                {qTableBarcodeMetricData.length && qTableBarcodeMetricData.map((item, i) => {
                                                                    if (i >= 2)
                                                                        return <tr key={`BM${(i + 1) * 2}`} className='d-none'>
                                                                            {item.map((m, j) => {
                                                                                if (j !== 0)
                                                                                    return <td key={`BM${(i + 2 + j) * 2}`}>{Utils.isFloat(m)}</td>
                                                                                else return <td style={{ textAlign: 'left' }} key={`BM${(i + 2 + j) * 2}`}><strong>{m}</strong></td>
                                                                            })}
                                                                        </tr>;
                                                                })}
                                                            </tbody>
                                                                </Table>
                                                            </div>
                                                        </>}
                                            {qTableBarcodeMetricData && qTableBarcodeMetricData.length > 0 ?
                                                <>
                                                    <div style={{position: 'relative', overflowX: 'auto' }}>
                                                        <Table className='regular-table' ref={tableRef} bordered hover style={{ marginTop: 20}} striped >
                                                            <thead>
                                                                <tr key={'BHM12'}>
                                                                    {qTableBarcodeMetricData[0].map((m, j) => <th             
                                                                        className="qtable_header"
                                                                        style={getHeaderStyle(j, m)}
                                                                        key={`BMH1${( 2 + j) * 2}`}>
                                                                            <>
                                                                                {(() => {
                                                                                    if (j === 0){
                                                                                        return 'Sample'
                                                                                    } else {
                                                                                        return m === '' ? qTableBarcodeMetricData[1][j].split('-')[0] : m
                                                                                    }
                                                                                }) ()}
                                                                            </> 
                                                                    </th>)}
                                                                </tr>
                                                                <tr key={'BHM13'}>
                                                                    {qTableBarcodeMetricData[1].map((m, j) => <th 
                                                                    className="qtable_header"
                                                                    onMouseEnter={() => handleOnHover(j)}
                                                                    onMouseLeave={() => handleOnHover(j, true)}
                                                                    style={getHeaderStyle(j, m)}
                                                                    onClick={j === 0 ? undefined : () => handleQtableHeaderClick(m, j)}
                                                                        key={`BMH1${( 2 + j) * 3}`}>
                                                                            <>
                                                                                {(() => {
                                                                                    if (j === 0){
                                                                                        return 'Barcode'
                                                                                    } else {
                                                                                        let dash = Utils.dashCounter(m);
                                                                                        if(dash > 1){
                                                                                            if(qTableBarcodeMetricData[0][j] === '') return m.split('-').slice(1).join('-')
                                                                                            else return m;
                                                                                        } else {
                                                                                            if(qTableBarcodeMetricData[0][j] === '') return m.includes('-') ? m.split('-')[1] : m;
                                                                                            else return m;
                                                                                        }
                                                                                    }
                                                                                }) ()}
                                                                            </> 
                                                                    </th>)}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {qTableBarcodeMetricData.length && qTableBarcodeMetricData.map((item, i) => {
                                                                const ppmSeqIndex = qTableBarcodeMetricData.findIndex(row => row[0] === 'ppmSeq');

                                                                if (i >= 2) {
                                                                    return (
                                                                        <tr key={`BM${(i + 1) * 2}`}>
                                                                            {item.map((m, j) => {
                                                                                if (i === ppmSeqIndex && j === 0) {
                                                                                    return <td key={`BM${(i + 2 + j) * 2}`} className={i === ppmSeqIndex ? 'ppmseq-row' : ''} style={{ textAlign: 'left', backgroundColor: '#A9A9A9' }}>{<strong>{m.toUpperCase()}</strong>}</td>;
                                                                                } else if (i === ppmSeqIndex && j !== 0) {
                                                                                    const barcode = qTableBarcodeMetricData[1][j];
                                                                                    const htmlContentItem = ppmSeqHTMLList?.find(item => item.barcode === barcode);
                                                                                    return (
                                                                                        <td key={`BM${(i + 2 + j) * 2}`} style={{ backgroundColor: '#A9A9A9' }}>
                                                                                            {htmlContentItem ? <HtmlPopup barcode={htmlContentItem.barcode} htmlContent={htmlContentItem.html} /> : null}
                                                                                        </td>
                                                                                    );
                                                                                } else if (j !== 0) {
                                                                                    return <td key={`BM${(i + 2 + j) * 2}`}>{Utils.isFloat(m)}</td>;
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ textAlign: 'left' }} key={`BM${(i + 2 + j) * 2}`}>
                                                                                            <strong>{m}</strong>
                                                                                        </td>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </tr>
                                                                    );
                                                                }
                                                                return null;
                                                            })}

                                                                {/* {qTableBarcodeMetricData.length && qTableBarcodeMetricData.map((item, i) => {
                                                                    // Original data without ppmSeq
                                                                    // if (i >= 2)
                                                                    //     return <tr key={`BM${(i + 1) * 2}`}>
                                                                    //         {item.map((m, j) => {
                                                                    //             if (j !== 0)
                                                                    //                 return <td key={`BM${(i + 2 + j) * 2}`}>{Utils.isFloat(m)}</td>
                                                                    //             else return <td style={{ textAlign: 'left' }} key={`BM${(i + 2 + j) * 2}`}><strong>{m}</strong></td>
                                                                    //         })}
                                                                    //     </tr>;
                                                                    //Hard code ppmSeq data
                                                                    if (i >= 2 && i < 23) {
                                                                        return (
                                                                            <tr key={`BM${(i + 1) * 2}`}>
                                                                            {item.map((m, j) => {
                                                                                if (j !== 0)
                                                                                return <td key={`BM${(i + 2 + j) * 2}`}>{Utils.isFloat(m)}</td>;
                                                                                else
                                                                                return (
                                                                                    <td style={{ textAlign: 'left' }} key={`BM${(i + 2 + j) * 2}`}>
                                                                                    <strong>{m}</strong>
                                                                                    </td>
                                                                                );
                                                                            })}
                                                                            </tr>
                                                                        );
                                                                        } else if (i === 23 && state.q_table_render_data.type === true) {
                                                                        // Insert the ppmSeq row after the 22nd row
                                                                        return (
                                                                            <>
                                                                            <tr key={`BM${(23 + 1) * 2}`}>
                                                                                {item.map((m, j) => {
                                                                                if (j !== 0)
                                                                                    return <td key={`BM${(23 + 2 + j) * 2}`}>{Utils.isFloat(m)}</td>;
                                                                                else
                                                                                    return (
                                                                                    <td style={{ textAlign: 'left' }} key={`BM${(23 + 2 + j) * 2}`}>
                                                                                        <strong>{m}</strong>
                                                                                    </td>
                                                                                    );
                                                                                })}
                                                                            </tr>
                                                                            <tr key="ppmSeq">
                                                                                {qTableBarcodeMetricData[0].map((header, j) => {
                                                                                if (j === 0) {
                                                                                    return (
                                                                                    <td key={`BM${(23 + 2 + j) * 2}`} style={{ textAlign: 'left', fontWeight: 'bold', backgroundColor: '#A9A9A9' }}>
                                                                                        PpmSeq
                                                                                    </td>
                                                                                    );
                                                                                } else {
                                                                                    const barcode = qTableBarcodeMetricData[1][j];
                                                                                    const htmlContentItem = ppmSeqHTMLList?.find(item => item.barcode === barcode);
                                                                                    return (
                                                                                    <td key={`BM${(23 + 2 + j) * 2}`}>
                                                                                        {htmlContentItem ? <HtmlPopup barcode={htmlContentItem.barcode} htmlContent={htmlContentItem.html} /> : null}
                                                                                    </td>
                                                                                    );
                                                                                }
                                                                                })}
                                                                            </tr>
                                                                            </>
                                                                        );
                                                                        } else if (i > 23 && state.q_table_render_data.type === true) {
                                                                        return (
                                                                            <tr key={`BM${(i + 1) * 2}`}>
                                                                            {item.map((m, j) => {
                                                                                if (j !== 0)
                                                                                return <td key={`BM${(i + 2 + j) * 2}`}>{Utils.isFloat(m)}</td>;
                                                                                else
                                                                                return (
                                                                                    <td style={{ textAlign: 'left' }} key={`BM${(i + 2 + j) * 2}`}>
                                                                                    <strong>{m}</strong>
                                                                                    </td>
                                                                                );
                                                                            })}
                                                                            </tr>
                                                                        );
                                                                        }
                                                                })} */}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </>
                                            : null}
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        </CardContent>
                    </Collapse>
                </Card>
                <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(7)}
                            aria-expanded={expandedIds.includes(7)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Barcode Details"
                    subheader={selectedBarcodeBySample ? `Barcode ${selectedBarcodeBySample.barcode} Sample ${selectedBarcodeBySample.sample}` : Utils.getRunIdWithoutCamera(state.runId)}
                />
                <Collapse in={expandedIds.includes(7)} timeout="auto">
                    <CardContent>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <Row>
                                    <Col lg={12}>
                                        <Row>
                                            {barcode_coverage_selected_barcode && barcodeDetailsReadLengthPlot !== undefined &&
                                                <Col lg={6}>
                                                    <Row>
                                                        <Col lg={12}>
                                                            {barcodeDetailsReadLengthHeader && <SummaryHeader label='Raw Read Length' value={barcodeDetailsReadLengthHeader.raw ? `${barcodeDetailsReadLengthHeader.raw} bp` : barcodeDetailsReadLengthHeader.aligned ? `${barcodeDetailsReadLengthHeader.aligned} bp` : ''} width_perc={12} />}
                                                        </Col>
                                                        <Col lg={12} style={{ marginTop: 5, }}>
                                                            <RenderPlot id="readLengthPlot4" data={barcodeDetailsReadLengthPlot.data && barcodeDetailsReadLengthPlot.data} layout={barcodeDetailsReadLengthPlot.layout && barcodeDetailsReadLengthPlot.layout} config={barcodeDetailsReadLengthPlot.config && barcodeDetailsReadLengthPlot.config} isLoading={state.is_barcode_coverage_plot_loading}
                                                                type={'rounded'} width={'100%'} height={400} />
                                                        </Col>

                                                    </Row>
                                                </Col>
                                            }

                                            {barcode_coverage_selected_barcode &&
                                                <Col lg={6}>
                                                    <Row>
                                                        <Col lg={12}>
                                                            {bsqBarcodeDetailValue !== undefined && bsqBarcodeDetailPlot && <SummaryHeader label='Total Bases > Q30' value={`${bsqBarcodeDetailValue}%`} width_perc={12} />}
                                                        </Col>
                                                        {bsqBarcodeDetailPlot !== undefined &&
                                                            <Col lg={12} style={{ marginTop: 5, }}>
                                                                <RenderPlot id="bsqBarcodeDetailPlot" data={bsqBarcodeDetailPlot.data && bsqBarcodeDetailPlot.data} layout={bsqBarcodeDetailPlot.layout && bsqBarcodeDetailPlot.layout} config={bsqBarcodeDetailPlot.config && bsqBarcodeDetailPlot.config} isLoading={state.is_barcode_coverage_plot_loading}
                                                                    type={'rounded'} width={'100%'} height={400} />
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                        <Row style={{ marginTop: 5 }}>
                                            {barcode_coverage_selected_barcode &&
                                                <Col lg={6} style={{ marginTop: 15 }}>
                                                    <Row>
                                                        {(state.coverage_plots.plot1 !== undefined || covPlot1 !== undefined) && state.fmetrics_table_render_data && state.fmetrics_table_render_data.length && meanCoverageValue && <SummaryHeader label='Mean Coverage' value={`${meanCoverageValue}`} width_perc={12} />}
                                                        {state.coverage_plots.plot1 !== undefined &&
                                                            <Col lg={12}>
                                                                {state.coverage_plots.plot1 && <Image src={state.coverage_plots.plot1} width={isSmallScreen ? 300 : isMedium1Screen ? 400 : isMedium2Screen ? 500 : null} />}
                                                            </Col>
                                                        }
                                                        {covPlot1 !== undefined &&
                                                            <Col lg={12} style={{ marginTop: 5, }}>
                                                                <RenderPlot id="CoveragePlot1" data={(covPlot1 !== undefined && covPlot1.data) && covPlot1.data} layout={(covPlot1 !== undefined && covPlot1.layout) && covPlot1.layout} config={(covPlot1 !== undefined && covPlot1.config) && covPlot1.config} isLoading={state.is_fmetrics_table_data_loading}
                                                                    type={'rounded'} width={'100%'} height={300} />
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                            }

                                            {(barcodeCoveragePlotDataPNG !== undefined && barcodeCoveragePlotDataPNG.length > 0) && barcodeCoveragePlotDataPNG.map((item, i) => {
                                                return (
                                                    <Col lg={6}>
                                                        <Row>
                                                            <Col lg={12} key={`BCPNG${(i + 1) * 2}`}>
                                                                <Image src={item} width={isSmallScreen ? 300 : isMedium1Screen ? 400 : isMedium2Screen ? 500 : null} />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                );
                                            })}

                                            {(barcode_coverage_selected_barcode && barcodeCoveragePlotData !== undefined && barcodeCoveragePlotData.length > 0) && barcodeCoveragePlotData.map((item, i) => {
                                                if (item !== 'object' && item !== undefined) {
                                                    return (
                                                        <Col lg={6} key={`BCJSON${(i + 1) * 4}`} style={{ marginTop: 15 }}>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    {gcBeashHeader !== undefined && <SummaryHeader label='GC bias <30% : >60%' value={`${gcBeashHeader}`} width_perc={15} />}
                                                                </Col>
                                                                <div id='barcodePrintDiv'>
                                                                <Col lg={12} key={`BCJSON${(i + 1) * 2}`}>
                                                                    <RenderPlot id={`BCJSON${i}`} key={`BCJSON${(i + 1) * 3}`} data={item.data !== undefined && item.data} layout={item.layout !== undefined && item.layout} config={item.config !== undefined && item.config} isLoading={state.is_barcode_coverage_plot_loading}
                                                                        type={'rounded'} width={'100%'} height={400} />
                                                                </Col>
                                                                </div>
                                                            </Row>
                                                        </Col>
                                                    );
                                                }
                                            })}

                                            {(barcode_coverage_selected_barcode && state.fmetrics_table_render_data !== undefined && state.fmetrics_table_render_data.length > 0) &&
                                                <Col lg={6}>
                                                    <Table striped bordered hover style={{ marginTop: 5 }}>
                                                        <thead>
                                                        </thead>
                                                        <tbody>
                                                            {(state.fmetrics_table_render_data !== undefined && state.fmetrics_table_render_data.length > 0) && state.fmetrics_table_render_data.map((item, i) => {
                                                                if (i === 0)
                                                                    return <tr key={Utils.generateKey(i)}>
                                                                        {item.map((m, j) => {
                                                                            return <th key={Utils.generateKey(j)}>{m}</th>
                                                                        })}
                                                                    </tr>;
                                                            })}
                                                            {(state.fmetrics_table_render_data !== undefined && state.fmetrics_table_render_data.length > 0) && state.fmetrics_table_render_data.map((item, i) => {
                                                                if (i !== 0)
                                                                    return <tr key={Utils.generateKey(i)}>
                                                                        {item.map((m, j) => {
                                                                            return j === 0 ? <td key={Utils.generateKey(j)}><strong>{m}</strong></td> : <td key={Utils.generateKey(j)}>{m}</td>
                                                                        })}
                                                                    </tr>;
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            }
                                            {(barcode_coverage_selected_barcode && barcodeCoveragePlotData !== undefined && gcBeasTableData !== undefined && gcBeasTableData.length > 0) &&
                                                <Col lg={6}>
                                                    <Table striped bordered hover style={{ marginTop: 5 }}>
                                                        <thead>
                                                        </thead>
                                                        <tbody>
                                                            <tr key={Utils.generateKey(1)}>
                                                                <th key={Utils.generateKey(2)}>Genome</th>
                                                                <th key={Utils.generateKey(3)}>median</th>
                                                                <th key={Utils.generateKey(4)}>mean</th>
                                                                <th key={Utils.generateKey(4)}>25pct</th>
                                                                <th key={Utils.generateKey(5)}>75pct</th>
                                                            </tr>
                                                            {(gcBeasTableData !== undefined && gcBeasTableData.length > 0) && gcBeasTableData.map((item, i) => {
                                                                return <tr key={Utils.generateKey(i)}>
                                                                    <td key={Utils.generateKey(i)}>{item.genome}</td>
                                                                    <td key={Utils.generateKey(i)}>{item.median}</td>
                                                                    <td key={Utils.generateKey(i)}>{item.mean}</td>
                                                                    <td key={Utils.generateKey(i)}>{item.p25}</td>
                                                                    <td key={Utils.generateKey(i)}>{item.p75}</td>
                                                                </tr>;
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                    </CardContent>
                </Collapse >
            </Card>
        </>
    }

    const renderRunSummarySubTab2 = () => {
        return <>
                <Card sx={{ minWidth: 275, zIndex: 1, }}>
                    <CardHeader
                        action={
                            <IconButton
                                onClick={() => handleExpandClick(3)}
                                aria-expanded={expandedIds.includes(3)}
                                aria-label="Expand/Collapse"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        }
                        title="Error"
                        subheader={state.is_unified_row_selected ? state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId : state.runId}
                    />
                    <Collapse in={expandedIds.includes(3)} timeout="auto">
                        <CardContent>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Row>
                                        <Col lg={3}>
                                            {state.ber_table_render_data !== undefined && state.ber_table_render_data.length > 0 &&
                                                <Table striped bordered hover style={{ marginTop: 20 }}>
                                                    <thead>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {state.ber_table_render_data !== undefined && state.ber_table_render_data.length > 0 && state.ber_table_render_data[0].map((item, i) => {
                                                                return <th key={Utils.generateKey(i)}>{item}</th>
                                                            })}
                                                        </tr>
                                                        {state.ber_table_render_data !== undefined && state.ber_table_render_data.length > 0 && state.ber_table_render_data.map((item, i) => {
                                                            if (i !== 0) {
                                                                return <tr key={Utils.generateKey(i)}>
                                                                    {item.map((m, j) => {
                                                                        return j === 0 ? <td key={Utils.generateKey(j)}><strong>{m}</strong></td> : <td key={Utils.generateKey(j)}>{m}</td>
                                                                    })}
                                                                </tr>;
                                                            }
                                                        })}
                                                    </tbody>
                                                </Table>
                                            }
                                            {state.ber_hmer_table_render_data && state.ber_hmer_table_render_data.length > 0 &&
                                                <Table striped bordered hover style={{ marginTop: 20 }}>
                                                    <thead>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {state.ber_hmer_table_render_data && state.ber_hmer_table_render_data.length > 0 && state.ber_hmer_table_render_data[0].map((item, i) => {
                                                                return <th key={Utils.generateKey(i)}>{item}</th>
                                                            })}
                                                        </tr>
                                                        {state.ber_hmer_table_render_data && state.ber_hmer_table_render_data.length > 0 && state.ber_hmer_table_render_data.map((item, i) => {
                                                            if (i !== 0) {
                                                                return <tr key={Utils.generateKey(i)}>
                                                                    {item.map((m, j) => {
                                                                        return j === 0 ? <td key={i + j}><strong>{m}</strong></td> : <td key={i + j}>{m}</td>
                                                                    })}
                                                                </tr>;
                                                            }
                                                        })}
                                                    </tbody>
                                                </Table>
                                            }
                                        </Col>
                                        <Col lg={9}>
                                            <Row style={{ marginTop: 60 }}>
                                                {(error1PlotDataPNG !== undefined) &&
                                                    <Col lg={6}>
                                                        {error1PlotDataPNG && <Image src={error1PlotDataPNG} width={isSmallScreen ? 300 : isMedium1Screen ? 400 : isMedium2Screen ? 500 : null} />}
                                                    </Col>
                                                }
                                                {(error2PlotDataPNG !== undefined) &&
                                                    <Col lg={6}>
                                                        {error2PlotDataPNG && <Image src={error2PlotDataPNG} width={isSmallScreen ? 300 : isMedium1Screen ? 400 : isMedium2Screen ? 500 : null} />}
                                                    </Col>
                                                }
                                                {(state.runId !== undefined && state.runId !== null && state.runId.includes('_')) &&
                                                    error1PlotData !== undefined &&
                                                    <Col lg={6}>
                                                        {<RenderPlot id="errorPlot1" data={error1PlotData.data && error1PlotData.data} layout={error1PlotData.layout && error1PlotData.layout} config={error1PlotData.config && error1PlotData.config} isLoading={state.is_error_plot_loading}
                                                            type={'rounded'} width={'100%'} height={420} />}
                                                    </Col>
                                                }
                                                {(state.runId !== undefined && state.runId !== null && state.runId.includes('_')) &&
                                                    error2PlotData !== undefined &&
                                                    <Col lg={6}>
                                                        {<RenderPlot id="errorPlot2" data={error2PlotData.data && error2PlotData.data} layout={error2PlotData.layout && error2PlotData.layout} config={error2PlotData.config && error2PlotData.config} isLoading={state.is_error_plot_loading}
                                                            type={'rounded'} width={'100%'} height={400} />}
                                                    </Col>
                                                }
                                            </Row>
                                            {state.rlq_table_render_data && state.rlq_table_render_data.length > 0 &&
                                                <Table striped bordered hover style={{ marginTop: 20 }}>
                                                    <thead>
                                                    </thead>
                                                    <tbody>
                                                        {state.rlq_table_render_data.length > 0 && state.rlq_table_render_data.map((item, i) => {
                                                            return <tr key={Utils.generateKey(i)}>
                                                                {item.map((m, j) => {
                                                                    return j === 0 ? <td key={Utils.generateKey(j)}><strong>{m}</strong></td> : <td key={Utils.generateKey(j)}>{m}</td>
                                                                })}
                                                            </tr>;
                                                        })}
                                                    </tbody>
                                                </Table>
                                            }
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        </CardContent>
                    </Collapse>
                </Card>
                <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                    <CardHeader
                        action={
                            <IconButton
                                onClick={() => handleExpandClick(5)}
                                aria-expanded={expandedIds.includes(5)}
                                aria-label="Expand/Collapse"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        }
                        title="Per-base Signals and Linearity"
                        subheader={state.runId}
                    />
                    <Collapse in={expandedIds.includes(5)} timeout="auto">
                        <CardContent>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Row>
                                        {state.signals_linearity_table_render_data && state.signals_linearity_table_render_data.length > 0 &&
                                            <Col lg={6}>
                                                <Table
                                                    bordered hover style={{ marginTop: 20 }} striped>
                                                    <thead>
                                                    </thead>
                                                    <tbody>
                                                        {state.signals_linearity_table_render_data.length > 0 && state.signals_linearity_table_render_data[0].map((item, i) => {
                                                            if (i === 0)
                                                                return <tr key={Utils.generateKey(i)}>
                                                                    {item.map((m, j) => {
                                                                        return <th key={Utils.generateKey(i)}>{m}</th>
                                                                    })}
                                                                </tr>;
                                                        })}
                                                        {state.signals_linearity_table_render_data.length > 0 && state.signals_linearity_table_render_data[0].map((item, i) => {
                                                            if (i !== 0)
                                                                return <tr key={Utils.generateKey(i)}>
                                                                    {item.map((m, j) => {
                                                                        return j === 0 ? <td key={Utils.generateKey(j)}><strong>{m}</strong></td> : <td key={Utils.generateKey(j)}>{m}</td>
                                                                    })}
                                                                </tr>;
                                                        })}
                                                    </tbody>
                                                </Table>
                                                <Table bordered hover style={{ marginTop: 20 }} striped>
                                                    <thead>
                                                    </thead>
                                                    <tbody>
                                                        {state.signals_linearity_table_render_data.length > 0 && state.signals_linearity_table_render_data[1].map((item, i) => {
                                                            if (i === 0)
                                                                return <tr key={Utils.generateKey(i)}>
                                                                    {item.map((m, j) => {
                                                                        return <th key={Utils.generateKey(i)}>{m}</th>
                                                                    })}
                                                                </tr>;
                                                        })}
                                                        {state.signals_linearity_table_render_data.length > 0 && state.signals_linearity_table_render_data[1].map((item, i) => {
                                                            if (i !== 0)
                                                                return <tr key={Utils.generateKey(i)}>
                                                                    {item.map((m, j) => {
                                                                        return j === 0 ? <td key={Utils.generateKey(j)}><strong>{m}</strong></td> : <td key={Utils.generateKey(j)}>{m}</td>
                                                                    })}
                                                                </tr>;
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        }
                                        {signalLinearityPlotsData && signalLinearityPlotsData.length > 0 ? signalLinearityPlotsData.map((item, i) => {
                                            return (
                                                <Col lg={6} key={`SL${i * 2}`}>
                                                    <div id={`SL${i + 1}`} className="SL"></div>
                                                </Col>
                                            );
                                        }) : null}
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        </CardContent>
                    </Collapse>
                </Card>
                <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                    <CardHeader
                        action={
                            <IconButton
                                onClick={() => handleExpandClick(9)}
                                aria-expanded={expandedIds.includes(9)}
                                aria-label="Expand/Collapse"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        }
                        title="Phasing"
                        subheader={state.is_unified_row_selected ? state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId : state.runId}
                    />
                    <Collapse in={expandedIds.includes(9)} timeout="auto">
                        <CardContent>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Row>
                                        <Col lg={2}>
                                            {state.phasing_table_render_data && state.phasing_table_render_data.length > 0 ?
                                                <Table bordered hover style={{ marginTop: 20 }} striped>
                                                    <thead>
                                                    </thead>
                                                    <tbody>
                                                        {state.phasing_table_render_data.length > 0 ? state.phasing_table_render_data.map((item, i) => {
                                                            return <tr key={Utils.generateKey(i)}>
                                                                {item.map((m, j) => {
                                                                    return <td key={Utils.generateKey(i)}>{j === 0 ? <strong>{m}</strong> : m}</td>
                                                                })}
                                                            </tr>;
                                                        }) : null}
                                                    </tbody>
                                                </Table> : null
                                            }
                                        </Col>
                                        {phasingPlotsData && phasingPlotsData.length > 0 ? phasingPlotsData.map((item, i) => {
                                            return (
                                                <Col lg={i >= 2 ? { span: 5, offset: 2 } : { span: 5 }} key={`P${(i + 1) * 2}`}>
                                                    <div id={`P${i + 1}`} className="P"></div>
                                                </Col>
                                            );
                                        }) : null}
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        </CardContent>
                    </Collapse>
                </Card>
                <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                    <CardHeader
                        action={
                            <IconButton
                                onClick={() => handleExpandClick(11)}
                                aria-expanded={expandedIds.includes(11)}
                                aria-label="Expand/Collapse"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        }
                        title="Signals"
                        subheader={state.is_unified_row_selected ? state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId : state.runId}
                    />
                    <Collapse in={expandedIds.includes(11)} timeout="auto">
                        <CardContent>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Row>
                                        {signalPlotsPNGData && signalPlotsPNGData.length > 0 ? signalPlotsPNGData.map((item, i) => {
                                            return (
                                                <Col lg={6} key={`S_PNG${(i + 1) * 2}`}>
                                                    <Image src={item} width={isSmallScreen ? 300 : isMedium1Screen ? 400 : isMedium2Screen ? 500 : null} />
                                                </Col>
                                            );
                                        }) : null}
                                        {signalPlotsData && signalPlotsData.length > 0 ? signalPlotsData.map((item, i) => {
                                            return (
                                                <Col lg={6} key={`S${(i + 1) * 2}`}>
                                                    <div id={`S${i + 1}`} className="S"></div>
                                                </Col>
                                            );
                                        }) : null}
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        </CardContent>
                    </Collapse>
                </Card>
                <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                    <CardHeader
                        action={
                            <IconButton
                                onClick={() => handleExpandClick(12)}
                                aria-expanded={expandedIds.includes(12)}
                                aria-label="Expand/Collapse"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        }
                        title="Uniformity"
                        subheader={state.is_unified_row_selected ? state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId : state.runId}
                    />
                    <Collapse in={expandedIds.includes(12)} timeout="auto">
                        <CardContent>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Row>
                                        {uniformityPlotsXData && uniformityPlotsXData.length > 0 ? uniformityPlotsXData.map((item, i) => {
                                            return (
                                                <Col lg={6} key={`U${(i + 1) * 2}`}>
                                                    <div className="U_X" id={`U_X${i + 1}`}></div>
                                                </Col>
                                            );
                                        }) : null}
                                        {uniformityPlotsData && uniformityPlotsData.length > 0 ? uniformityPlotsData.map((item, i) => {
                                            return (
                                                <Col lg={6} key={`U${(i + 1) * 2}`}>
                                                    <div className="U" id={`U${i + 1}`}></div>
                                                </Col>
                                            );
                                        }) : null}
                                        {uniformitySignalPlotsData && uniformitySignalPlotsData.length > 0 ? uniformitySignalPlotsData.map((item, i) => {
                                            return (
                                                <Col lg={6} key={`UNIFSIGN${(i + 1) * 2}`}>
                                                    <Image src={item} width={isSmallScreen ? 300 : isMedium1Screen ? 400 : isMedium2Screen ? 500 : null} />
                                                </Col>
                                            );
                                        }) : null}
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        </CardContent>
                    </Collapse>
                </Card>
        </>
    }

    const renderRunSummarySubTab3 = () => {
        return <>
                <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                    <CardHeader
                        action={
                            <IconButton
                                onClick={() => handleExpandClick(13)}
                                aria-expanded={expandedIds.includes(13)}
                                aria-label="Expand/Collapse"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        }
                        title="VC Report"
                        subheader={Utils.getRunIdWithoutCamera(state.runId)}
                    />
                    <Collapse in={expandedIds.includes(13)} timeout="auto">
                        <CardContent>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Row>
                                        {state.vcreport_plots && state.vcreport_plots.length > 0 ? state.vcreport_plots.map((item, i) => {
                                            return (
                                                <Col lg={6} key={`VCREPORT${(i + 1) * 2}`}>
                                                    <Image src={item} width={isSmallScreen ? 300 : isMedium1Screen ? 400 : isMedium2Screen ? 500 : null} />
                                                </Col>
                                            );
                                        }) : null}
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        </CardContent>
                    </Collapse>
                </Card>
        </>
    }

    const determineDeviceSize = (device) => {
        if (device.isMedium1Screen) {
          return "isMedium1Screen";
        } else if (device.isMedium2Screen) {
          return "isMedium2Screen";
        } else if (device.isMedium3Screen) {
          return "isMedium3Screen";
        } else if (device.isSmallScreen) {
          return "isSmallScreen";
        } else if (device.isBigScreen) {
          return "isBigScreen";
        } else if (device.isBigScreen1) {
            return "isBigScreen1";
          } else if (device.isBigScreen2) {
            return "isBigScreen2";
          } else if (device.isBigScreen3) {
            return "isBigScreen3";
          } else if (device.isBigScreen4) {
            return "isBigScreen4";
          } else if (device.isBigScreen5) {
            return "isBigScreen5";
          } 
      
        // Default fallback
        return "Medium1Screen";
      };
      
      const deviceSize = determineDeviceSize({
        isMedium1Screen,
        isMedium2Screen,
        isMedium3Screen,
        isSmallScreen,
        isBigScreen,
        isBigScreen1,
        isBigScreen2,
        isBigScreen3,
        isBigScreen4,
        isBigScreen5
      });
      

    const exportTest = async () => {
        setPDFRendering(true);

        function getCurrrentStateList(objects) {
         return objects.filter(object => object.runId === state.runId)
        }

        try {
           
            let pdfData = structuredClone(state.run_details_table_pdf[0]?.data);
            let beadFilterTable = structuredClone(state.beads_filter_table_pdf);
            let cloneBarcodeMetric = structuredClone(state.q_table_render_data);
            let barcodeMetricsData = Utils.formatBarcodeMetricsTable(cloneBarcodeMetric);
            let cloneBarcodeMetricButton = structuredClone(state.active_barcode_table_button);
            //let plotUriClone = structuredClone(state.plotly_uri_list);
            let plotUriClone = getCurrrentStateList(state.plotly_uri_list);
            let fMetricsClone = structuredClone(state.fmetrics_table_render_data);
            //const result =  getCurrrentStateList(state.plotly_uri_list);
            
            for (const plot of plotUriClone) {
                const { id, plotData, plotLayout } = plot;
                const clonedData = structuredClone(plotData);
                const clonedLayout = structuredClone(plotLayout);
                let result;

                if(id === "BCJSON0" || id === "rsq1" || id === "runSummaryDensityPlot1" || id === "polyPlot2") {

                    const element = document.getElementById(id);
                    if (element) {
                        const positionInfo = element.getBoundingClientRect();
                        const divHeight = positionInfo.height;
                        const divWidth = positionInfo.width;
    
                        const canvas = await html2canvas(element, {
                            height: divHeight,
                            width: divWidth + 200,
                            useCORS: true,
                        });
    
                        const image = canvas.toDataURL('image/png');
                        const updatedURI = Utils.removeBase64Prefix(image);
                        result =  {pid: id, base64Data:updatedURI}
                    }
                } else {
                    result = await Utils.exportToImage(id, clonedData, clonedLayout)
                }

        
                if (result) {
                    pdfData[result.pid] = (result.base64Data && result.base64Data !== undefined) ? result.base64Data : '';
                    //plotUriList.push({ id: result.pid, uri: result.base64Data });
                }
            }

            setPdfLoading(true);
    
            setTimeout(() => {
                // Additional actions or set state after the timeout
                if(pdfData){
                    pdfData['averageReadLength'] = (averageReadLength && averageReadLength !== undefined) ? averageReadLength : '';
                    pdfData['homoIndelError'] = (homoIndelError && homoIndelError !== undefined) ? homoIndelError :  '';
                    pdfData['totalBasesValue'] = (totalBasesValue && totalBasesValue !== undefined) ? totalBasesValue : '';
                    pdfData['barcodeDetailsReadLengthHeader'] = (barcodeDetailsReadLengthHeader && barcodeDetailsReadLengthHeader !== undefined) ? barcodeDetailsReadLengthHeader.raw ? `${barcodeDetailsReadLengthHeader.raw} bp` : barcodeDetailsReadLengthHeader.aligned ? `${barcodeDetailsReadLengthHeader.aligned} bp` : '' : '';
                    pdfData['bsqBarcodeDetailValue'] = (bsqBarcodeDetailValue && bsqBarcodeDetailValue !== undefined) ? bsqBarcodeDetailValue : '';
                    pdfData['meanCoverageValue'] =(meanCoverageValue && meanCoverageValue !== undefined) ?  meanCoverageValue : '';
                    pdfData['gcBiashHeader'] = (gcBeashHeader && gcBeashHeader !== undefined) ? gcBeashHeader : '';
                    pdfData['gcBiasSummary'] = (gcBeasTableData && gcBeasTableData !== undefined) ? gcBeasTableData : '';
                    pdfData['gcBiasTable'] = (fMetricsClone && fMetricsClone !== undefined) ? fMetricsClone : '';
                    pdfData['UAstatsTable'] = (beadFilterTable && beadFilterTable !== undefined) ? beadFilterTable : [];
                    pdfData['barcodeMetricsTable'] = (barcodeMetricsData && barcodeMetricsData !== undefined) ? barcodeMetricsData : '';
                    pdfData['BarcodeMetricsButton'] = (cloneBarcodeMetricButton && cloneBarcodeMetricButton !== undefined) ? cloneBarcodeMetricButton : '';
                    //pdfData['plotUriList'] = (plotUriList && plotUriList !== undefined && plotUriList.length > 0) ? plotUriList : [];
                }
                jsPDFGenerator(pdfData, state.is_unified_row_selected ? state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId : state.runId, deviceSize);
                setPdfLoading(false);
            }, 5000);

            
    
            setPDFRendering(false);
        } catch (error) {
            console.log(error);
            setPDFRendering(false);
        }
    }


    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                        <Button 
                        id="header-advanced-filter-4" 
                        className="advanced-filter-btn" 
                        color="primary" 
                        startIcon={<PictureAsPdfIcon className="filter-Child" />} 
                        style={{ float: 'right', width: 210, color: 'white', marginRight: 10, marginTop: 10, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }} 
                        onClick={() => exportTest()}>
                            {isPDFRendering ? 'Generating PDF......' : 'Export PDF'}
                        </Button>
                </Col>
            </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col lg={12}>
                        <Card sx={{ minWidth: 275, zIndex: 1, }}>
                            <CardHeader
                                action={
                                    <IconButton
                                        onClick={() => handleExpandClick(1)}
                                        aria-expanded={expandedIds.includes(1)}
                                        aria-label="Expand/Collapse"
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                }
                                title="Run Description & Quality"
                                subheader={state.is_unified_row_selected ? state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId : state.runId}
                            />
                            <Collapse in={expandedIds.includes(1)} timeout="auto">
                                <CardContent>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>
                                            <Row>
                                                <RunConfiguration />
                                            </Row>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
            <Row style={{ marginTop: 20 }}>
                {!state.is_unified_row_selected ?
                    <Tab.Container id="left-tabs-example1" defaultActiveKey="link-1" activeKey={activeKey} onSelect={handleTabSelect}>
                        <Col lg={2}>
                            <Nav defaultActiveKey="link-1" className="flex-column verticalNav" onSelect={(selectedKey) => handleSubTabsRunSummarySelect(selectedKey)}>
                                <Nav.Link eventKey="link-1" ><InsightsIcon style={{ marginBottom: 5, marginLeft: 35, }} /> Summary</Nav.Link>
                                {renderPlotSubTab(state.user)}
                                {state.vcreport_exists && <Nav.Link eventKey="link-3">VC Report</Nav.Link>}
                            </Nav>
                        </Col>
                        <Col lg={10}>
                            <Tab.Content>
                                <Tab.Pane eventKey="link-1">
                                    {renderRunSummarySubTab1()}
                                </Tab.Pane>
                                <Tab.Pane eventKey="link-2">
                                    {renderRunSummarySubTab2()}
                                </Tab.Pane>
                                <Tab.Pane eventKey="link-3">
                                    {renderRunSummarySubTab3()}
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Tab.Container>
                    : renderRunSummarySubTab1()
                }
            </Row>
        </React.Fragment>
    )
};

export default RunSummary;