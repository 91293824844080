import React, { useState, useEffect } from 'react';
import { useAsyncDebounce } from 'react-table';
import matchSorter from 'match-sorter';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { IconButton } from '@material-ui/core';
import { SearchOutlined, Clear } from '@material-ui/icons';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { setRunListDataSearched, setSearchTerm, setSearchTermAMP, setRunListDataSearchedAMP, setEditMode, setEditAllowed, setColumnType } from "../actions/index";
import * as Utils from '../../utils/utils';

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "15px",
            "@media (min-width:992px)": {
                fontSize: "10px",
            },
            "@media (min-width:1280px)": {
                fontSize: "11px",
            },
            "@media (min-width:1420px)": {
                fontSize: "13px",
            },
            "@media screen and (min-width: 1500px) and (max-width: 1599px)": {
                fontSize: "13px",
            },
            "@media screen and (min-width: 2200px) and (max-width: 2700px)": {
                fontSize: "17px",
            },
            "@media (min-width:2800px)": {
                fontSize: "20px",
            },
        }
    }
})

export const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 300)

    return (
        <span>
            Search:{' '}
            <input
                // id="globalFilter"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
                style={{
                    fontSize: '1.1rem',
                    border: '0'
                }}
            />
        </span>
    )
}

export const GlobalFilterMain = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    gotoPage
}) => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const [diskSpace, setDiskSpace] = useState(undefined);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 2000);

    React.useEffect(() => {
        if (value !== '') dispatch(setSearchTerm(Utils.escapeRegExp(value), 'all'));
        else dispatch(setSearchTerm(undefined, 'all'));
        //dispatch(setColumnType(null))
        gotoPage(0);
    }, [value]);

    React.useEffect(() => {
        if (state.runlist !== undefined && state.runlist.length > 0 && (state.is_details_data_saved || state.is_analysis_comments_data_saved)) {
            dispatch(setRunListDataSearched([]));
            dispatch(setSearchTerm(undefined, 'all'));
            setValue('');
            gotoPage(0);
        }
        dispatch(setEditMode(false));
    }, [state.is_details_data_saved, state.is_analysis_comments_data_saved]);

    useEffect(() => {
        if (state.diskspace !== undefined) setDiskSpace(state.diskspace);
    }, [state.diskspace]);

    const handleClearClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (value !== undefined && value !== '') {
            setValue('');
            dispatch(setRunListDataSearched([]));
        }
        if (state.is_in_edit_mode === true) dispatch(setEditMode(false));
        dispatch(setColumnType(null))
        gotoPage(0);
    }

    let diskSpaceText = (diskSpace !== undefined) ? `Available storage: ${diskSpace} runs` : '';
    let diskSpaceEvalue = (diskSpaceText.includes('undefined') || diskSpaceText === undefined) ? '' : diskSpaceText;
    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <TextField
                fullWidth
                id="standard-bare"
                variant="outlined"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records... ${diskSpaceEvalue}`}
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={handleClearClick}>
                            {value !== undefined && value !== '' ? <Clear /> : ''}
                        </IconButton>
                    ),
                }}
                style={{ width: '100%', backgroundColor: 'white', marginTop: 5, marginBottom: 5, display: "flex", }}
            />
        </Box>
    )
}

export const GlobalFilterMainAmp = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    gotoPage,
    pageIndex
}) => {
    const dispatch = useDispatch();
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 2000);

    React.useEffect(() => {
        if (value !== '') dispatch(setSearchTermAMP([Utils.escapeRegExp(value)], 'all'));
        else dispatch(setSearchTermAMP(undefined, 'all'));
        gotoPage(0);
    }, [value]);


    const handleClearClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (value !== undefined && value !== '') {
            setValue('');
            setGlobalFilter('');
            dispatch(setRunListDataSearchedAMP([]));
        }
        gotoPage(0);
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <TextField
                fullWidth
                id="standard-bare"
                variant="outlined"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={handleClearClick}>
                            {value !== undefined && value !== '' ? <Clear /> : <SearchOutlined />}
                        </IconButton>
                    ),
                }}
                style={{ width: '100%', backgroundColor: 'white', marginTop: 5, marginBottom: 5, display: "flex", }}
            />
        </Box>
    )
}

export const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter, id },
}) => {
    const count = preFilteredRows.length;
    const state = useSelector(state => state)
    const dispatch = useDispatch();

    const onChange = value => setFilter(value || undefined);

    React.useEffect(() => {
        if (filterValue !== undefined) {
            dispatch(setSearchTerm(Utils.escapeRegExp(filterValue), id));
        }
    }, [filterValue]);

    const handleClearClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        //dispatch(setRunListDataSearched([]));
        dispatch(setSearchTerm(null, id));
        setFilter(undefined);
        if (state.is_in_edit_mode === true) dispatch(setEditMode(false));
        //gotoPage(0);      
    }

    const classes = useStyles();

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            {/* <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
            <TextField
                classes={{ root: classes.customTextField }}
                variant="standard"
                size="small"
                placeholder={`Search...`}
                value={filterValue || ''}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onChange={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange(e.target.value);
                    //setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={handleClearClick} size="small">
                            {filterValue !== undefined && filterValue !== '' ? <Clear className="buttonsIconChild" /> : ''}
                        </IconButton>
                    ),
                }}
                style={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                }}
                disabled={false}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Box>
    )
}

export const DefaultColumnFilterAMP = ({
    column: { filterValue, preFilteredRows, setFilter, id },
}) => {
    const count = preFilteredRows.length;
    const dispatch = useDispatch();

    const onChange = value => setFilter(value || undefined);

    React.useEffect(() => {
        if (filterValue !== undefined) {
            dispatch(setSearchTermAMP(Utils.escapeRegExp(filterValue), id));
        }
        //else dispatch(setSearchTermAMP([], 'all'));
    }, [filterValue]);

    const handleClearClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setSearchTermAMP(null, id));
        setFilter(undefined);
        //gotoPage(0);      
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            {/* <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
            <TextField
                variant="standard"
                size="small"
                placeholder={`Search...`}
                value={filterValue || ''}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onChange={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange(e.target.value);
                    //setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={handleClearClick} size="small">
                            {filterValue !== undefined && filterValue !== '' ? <Clear /> : <SearchOutlined />}
                        </IconButton>
                    ),
                }}
                style={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                }}
                disabled={false}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Box>
    )
}

export const SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
}) => {
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach(row => {
            options.add(row.values[id]);
        })
        return [...options.values()];
    }, [id, preFilteredRows]);

    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

export const SliderColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
}) => {

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={e => {
                    setFilter(parseInt(e.target.value, 10));
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    )
}

export const NumberRangeColumnFilter = ({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) => {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] !== undefined ? parseInt(preFilteredRows[0].values[id].split(' ')[0]) : preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] !== undefined ? parseInt(preFilteredRows[0].values[id].split(' ')[0]) : preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id] !== undefined && row.values[id] !== null && row.values[id] !== '' ? parseInt(row.values[id].split(' ')[0]) : row.values[id], min);
            max = Math.max(row.values[id] !== undefined && row.values[id] !== null && row.values[id] !== '' ? parseInt(row.values[id].split(' ')[0]) : row.values[id], max);
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value;
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: '70px',
                    marginRight: '0.5rem',
                }}
            />
            to
            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value;
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: '70px',
                    marginLeft: '0.5rem',
                }}
            />
        </div>
    )
}

export const fuzzyTextFilterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = val => !val