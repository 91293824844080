import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useSelector, useDispatch } from "react-redux";
import AuthAPI from '../auth/api';
import { setUser, setIsAuthenticated, setJWT, setJWTExp } from '../actions/index';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const user = useSelector(state => state.user);

    let history = useHistory();
    const dispatch = useDispatch();

    const handleSignIn = async () => {
        try {
            const response = await AuthAPI.isAuthenticated();

            if (response.status === 200) {
                const body = await response.json();
                
                const cookies = new Cookies(null, { path: '/' });
                const token = cookies.get('token');
                if (token) {
                    dispatch(setJWT(token));
                    dispatch(setJWTExp(Date.now() + parseInt(process.env.REACT_APP_JWT_EXP_TIME_MS)));
                    dispatch(setUser(body));
                    dispatch(setIsAuthenticated(true));
                    history.push("/");
                }
                else history.push('/login');
            }
            else history.push('/login');
        }
        catch {
            dispatch(setJWT(undefined));
            history.push('/login');
        }
    };

    React.useEffect(() => {
        handleSignIn();
    }, []);

    return (
        <Route {...rest} render={
            props => {
                if (user && user.active === true) {
                    return <Component {...rest} {...props} />
                }
            }
        } />
    )
}

export default ProtectedRoute;